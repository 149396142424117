<template>
	<div>
		<!-- <stay-phone-call :user_phone="user_phone"></stay-phone-call> -->
		<b-table :items="items" :fields="fields" responsive hover class="prospect-table" selectable selected-variant="secondary" @row-selected="handleRowSelect">
			<template v-slot:cell(created)="data">
				<dot v-if="!data.item.viewed" variant="info"></dot>
        		<small>{{data.item.created_at | ago_or_date_after}}</small>
      		</template>
			<template v-slot:cell(fname)="data">
	        	<prospect-info :prospect="data.item"></prospect-info>
	      	</template>
<!-- 	      	<template v-slot:cell(user_phones)="data">
	      		<icon variant="danger" value="alert-circle" v-b-tooltip title="We were not able to verify this phone number" v-if="data.item.user_phones.length > 0 && data.item.user_phones[0].verified == false" />
	      		{{data.item.user_phones.length > 0 ? data.item.user_phones[0].friendly_name : ''}}
	      		<icon v-if="data.item.user_phones.length > 0" value="phone-outgoing" @click="call(data.item.user_phones[0])" />
	      	</template> -->
	      	<template v-slot:cell(email)="data">
	      		<a :href="'mailto:' + data.item.email">{{data.item.email}}</a>
	      	</template>
	      	<template v-slot:cell(animals)="data">
	      		<prospect-pets :value="data.item.pets"></prospect-pets>
	      	</template>
	      	<template v-slot:cell(status)="data">
	      		<user-status editable v-model="data.item"></user-status>
	      	</template>
		</b-table>
	</div>
</template>

<script>
	//import StayPhoneCall from '../organizations/stay_phone_call'
	import ProspectInfo from './table/prospect_info'
	import ProspectPets from './table/prospect_pets'
	import Dot from 'common/dot'
	import Icon from 'common/icon'
	import { BTable } from 'bootstrap-vue'
	import { ago_or_date_after } from 'functions/date'

	export default {
		components: {
			//'stay-phone-call': StayPhoneCall,
			'prospect-info': ProspectInfo,
			'prospect-pets': ProspectPets,
			BTable, Icon, Dot
		},
		filters: {ago_or_date_after: ago_or_date_after},
		data() {
			return {
				user_phone: {},
				fields: [
					'created',
					{
						key: 'fname',
						label: 'Owner'
					},
					{
						key: 'animals',
						label: 'Pet'
					},
					{
						key: 'address.city',
						label: 'City'
					},
					{
						key: 'status',
						label: 'Status'
					}
				]

			}
		},
		props: {
			items: Object | Array
		},
		methods: {
			// call(user_phone) {
			// 	console.log(user_phone)
			// 	this.user_phone = user_phone
			// 	this.$bvModal.show('stay-phone-call')
			// },
			handleRowSelect(evt) {
				this.$stayloader.enable()
				location.href = `${this.base_url}/${evt[0].id}`
			}
		},
		computed: {
			base_url(){
				return this.$root.pageData.base_url
			}
		}
	}
</script>
