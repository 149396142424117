<template>
	<b-modal title="Make a phone call" id="stay-phone-call">
		<b-container fluid>
			<b-row>
				<b-col class="text-center">
					<b-badge>ME</b-badge>
					{{$root.pageData.current_user.phone}}
				</b-col>
			</b-row>
			<b-row class="mt-3 mb-3">
				<b-col class="text-center">
					<icon value='arrow-down' />
				</b-col>
			</b-row>
			<b-row>
				<b-col class="text-center">
					<b-badge>{{$root.pageData.organization.name}}</b-badge>
					{{$root.pageData.phone_numbers[0].friendly_name}}
				</b-col>
			</b-row>
			<b-row class="mt-3 mb-3">
				<b-col class="text-center">
					<icon value='arrow-down' />
				</b-col>
			</b-row>
			<b-row>
				<b-col class="text-center">
					<b-badge>Client</b-badge>
					{{user_phone.friendly_name}}
				</b-col>
			</b-row>
			<b-row class="mt-5">
				<b-col class="text-center">
					<button-group>
						<b-button variant="outline-success">Dial</b-button>
						<b-button variant="outline-secondary">Send to Phone</b-button>
						<b-button variant="outline-secondary">Log a call</b-button>
					</button-group>
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
	import Icon from 'common/icon'
	export default {
		data() {
			return {

			}

		},
		props: {
			user_phone: {
				type: Object,
				required: true
			}
		},
		components: {Icon}
	}
</script>