<template>
	<div class="stay-right-nav">
		<div id="right-nav-bg" v-bind:style="{ display: display, opacity: opacity }" v-on:click="visible = false"></div>
		<div id="right-nav" v-bind:style="{ right: pixels, width: menuWidth }">
			<b-container>
				<b-row>
					<b-col>
						<close @click="visible = false" />
					</b-col>
				</b-row>
				<b-row class="mt-5">
					<b-col>
						<h4>{{organization.name}}</h4>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						{{organization.address | address}}
					</b-col>
				</b-row>
				<b-row class="mt-3" v-if="organization.phone">
					<b-col>
						<a :href="`tel:${organization.phone}`">{{organization.phone}}</a>
					</b-col>
				</b-row>
				<b-row class="mt-3" v-if="organization.email">
					<b-col>
						<a :href="`mailto:${organization.email}`">{{organization.email}}</a>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>

<script>
	import Http from 'mixins/http'
	import Close from 'common/close'
	export default {
		components: { Close },
		data() {
			return {
				visible: false,
				right: -300,
				menuWidth: '300px',
				organization: {
					name: this.$root.pageData.organization.name,
					address: null,
					phone: null,
					email_address: null
				}
			}
		},

		methods: {
			fetchOrganizationInfo(){
				if(this.organization.email_address) return true
				this.post('/organization', {}, {alert: false}).then(res => {
					this.organization = res.data.organization
				})
			}
		},

		watch: {
			visible: function(val) {
				//console.log(val)
				if(this.$root.window.width > 300){
					this.menuWidth = '300px'
				} else {
					this.menuWidth = this.$root.window.width + 'px'
				}
				if(val) {
					this.right = 0
					this.fetchOrganizationInfo()
				} else {
					this.right = -300
				}
			}
		},

		computed: {
			pixels() {
				return this.right + "px"
			},
			display() {
				return (this.visible ? 'block' : 'none')
			},
			opacity() {
				return (this.visible ? 0.3 : 0)
			}
		},

		mixins: [Http]
	}
</script>

<style lang="scss">
	@import "stylesheets/variables.scss";

	.stay-right-nav {
		@include fill;
		pointer-events: none;
		z-index: $nav-index;

	}
	#right-nav-bg {
		position: absolute;
		height: 100%;
		width: 100%;
		background-color: black;
		z-index: $nav-index+1;
		transition: opacity 0.5s;
		pointer-events: auto;
	}
	#right-nav {
		position: absolute;
		height: 100%;
		background-color: #FFFFFF;
		top: 0;
		z-index: $nav-index+2;
		transition: 0.5s;
		pointer-events: auto;
	}
	#right-nav button {
		outline: none;
		border: none;
		height: 50px;
		width: 50px;
		background-color: Transparent;
	}
	#right-nav svg {
		stroke-width: 0.75;
		width: 50px;
		height: 50px;

	}
	#right-nav .list-group-item {
		font-weight: 200;
		font-size: 2em;
		border: none;
	}
	

</style>