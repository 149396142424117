// Users.js
//
// This file includes functions that are shared between prospects and users (customers and employees in some cases)
import PhoneTable from './phone_table'
import Note from './note_modal'
import Signature from './signature'
import VueSignaturePad from 'vue-signature-pad'
import SignatureTable from './signature_table'
import SignatureConsent from './signature_consent'
import NewUser from './new_user'
import UserCard from './user_card'
import UserTable from './user_table'
import Tags from './tags.vue'
import SearchModal from './search_modal'
import SearchTable from './search_table'
import SearchFilters from './search_filters'
import EntityDetails from 'common/entity_details'
import {BModal, BFormTextarea} from 'bootstrap-vue'
import FloatingButton from 'common/floating_button'

// Ported from prospects.js
import ProspectTable from './prospect_table'
import NewUserStatus from './new_user_status'
import UserStatus from './user_status'
import UserStatusColors from './user_status_colors'
import ColorPicker from '../common/color_picker'


import {StayData, StayCommon} from '../../plugins/stay-common'
import merge from 'lodash/merge'
import has from 'lodash/has'

export default {
	install(Vue, options){
		Vue.use(VueSignaturePad)
		Vue.use(StayCommon)
		Vue.component('phone-table', PhoneTable)
		Vue.component('signature-table', SignatureTable)
		Vue.component('signature-consent', SignatureConsent)
		Vue.component('signature', Signature)
		Vue.component('prospect-table', ProspectTable)
		Vue.component('user-status', UserStatus)
		Vue.component('user-status-colors', UserStatusColors)
		Vue.component('new-user-status', NewUserStatus)
		Vue.component('color-picker', ColorPicker)
		Vue.component('user-card', UserCard)
		Vue.component('user-table', UserTable)
		Vue.component('tags', Tags)
		Vue.component('search-table', SearchTable)
		Vue.component('search-filters', SearchFilters)
		Vue.component('floating-button', FloatingButton)
		Vue.component('b-modal', BModal)
		Vue.component('b-form-textarea', BFormTextarea)
		console.log("User tools installed for use")

		document.addEventListener('DOMContentLoaded', () => {
			Vue.stayuser = pageData.user || pageData.prospect
			Vue.prototype.$user = pageData.user || pageData.prospect
			document.addEventListener('keydown', event => {
				if((document.activeElement.tagName == "body" || document.activeElement.tagName == "BODY") && Vue.stayuser){
					switch(event.key){
					case "n":
						StayUsers.notes.new()
						break
					case "m":
						StayUsers.messages.new()
						break
					}
				}
			})
		})

		const SignatureModal = Vue.extend(Signature)
		const NoteModal = Vue.extend(Note)
		const NewUserModal = Vue.extend(NewUser)
		const UserSearchModal = Vue.extend(SearchModal)
		const EntityDetailsModal = Vue.extend(EntityDetails)

		// TODO remove this....
		// Prospect modals
		const StayProspectModals = Vue.prototype.$stayprospectmodals = {}
		const StayNewUserStatusModal = Vue.extend(NewUserStatus)

		// Prospect functions
		const StayProspects = Vue.prototype.$stayprospects = {}
		StayProspects.user_statuses = {
			new: function() {
				console.log("New user status")
				var StayProspectModal = new StayNewUserStatusModal({
					propsData: {
						visible: true,
						domain: options.domain || null,
						admin: options.admin || false
					}
				})
				const mountedModal = StayProspectModal.$mount()
			}
		}

		const StayUsers = Vue.prototype.$stayusers = {
			new: function(object){
				// The user should contain a `user_data` object which includes the `type` of user or prospect
				return new Promise((resolve, reject) => {
					const StayNewUserModal = new NewUserModal({
						propsData: {
							user: object
						}
					})
					StayNewUserModal.$on('input', function(msg){
						resolve(msg)
					})
					StayNewUserModal.$on('cancel', function(){
						reject('canceled')
					})
					StayNewUserModal.$mount()
				})
				
			},
			unread: function(){
				Vue.prototype.$stayhttp.put(Vue.stayuser.url, {
					viewed: false
				}).then(response=>{
					console.log(response)
				})
			},
			search: function(options={}){
				return new Promise((resolve, reject)=>{
					var Modal = new UserSearchModal({
						propsData: options
					})
					Modal.$on('input', (data)=>{
						resolve(data)
					})
					Modal.$on('cancel', ()=>{
						reject('canceled')
					})
					Modal.$mount()
				})
			},
			edit: function(user_or_prospect, propsData={}){
				return new Promise((resolve, reject)=>{
					var Modal = new EntityDetailsModal({
						propsData: {
							value: user_or_prospect,
							...propsData
						}
					})
					Modal.$on('input', (data)=>{
						resolve(data)
					})
					Modal.$on('cancel', ()=>{
						reject('canceled')
					})
					Modal.$mount()
				})
			}



		}
		StayUsers.phone_numbers = {
			new: function(options){
				// The user or prospect should be in the options (or we can try and get it off the pageData

			}
		}

		StayUsers.signature = {
			new: function(options){
				options = options || {}
				const StaySignatureModal = new SignatureModal({
					propsData: merge({
						user: Vue.stayuser,
						initials: false
					}, options)
				})
				StaySignatureModal.$mount()
			},
			get: function(){
				return new Promise((resolve, reject) => {
					if(has(Vue.stayuser.signatures, 'signature.image')){
						resolve(Vue.stayuser.signatures.signature.image)
					} else {
						const StaySignatureModal = new SignatureModal({
							propsData: {
								user: Vue.stayuser,
								initials: false,
								promise: true,
								noConsent: true
							}
						})
						StaySignatureModal.$on('input', function(msg){
							resolve(msg.signature)
						})
						StaySignatureModal.$on('cancel', function(){
							reject('canceled')
						})
						StaySignatureModal.$mount()
					}
				})
			}
		}

		StayUsers.initials = {
			new: function(options){
				options = options || {}
				const StaySignatureModal = new SignatureModal({
					propsData: merge({
						user: Vue.stayuser,
						initials: true
					}, options)
				})
				StaySignatureModal.$mount()
			},
			get: function(){
				return new Promise((resolve, reject) => {
					if(has(Vue.stayuser.signatures, 'initials.image')){
						resolve(Vue.stayuser.signatures.initials.image)
					} else {
						const StaySignatureModal = new SignatureModal({
							propsData: {
								user: Vue.stayuser,
								initials: true,
								promise: true,
								noConsent: true
							}
						})
						StaySignatureModal.$on('input', function(msg){
							resolve(msg.initials)
						})
						StaySignatureModal.$on('cancel', function(msg){
							reject('canceled')
						})
						StaySignatureModal.$mount()
					}
				})
			}
		}


		StayUsers.notes = {
			active: false,
			new: function(options){
				if(StayUsers.notes.active){
					return
				}
				StayUsers.notes.active = true
				options = options || {}
				return new Promise((resolve, reject) => {
					const NewNoteModal = new NoteModal({
						propsData: options
					})
					NewNoteModal.$on('input', function(msg){
						StayUsers.notes.active = false
						if(pageData.prospect){
		    				Vue.prototype.$stayfunctions.delayedUpdateById(pageData.prospect.events, msg)
		    			} else {
		    				Vue.prototype.$stayfunctions.delayedUpdateById(pageData.user.events, msg)
		    			}
		    			document.getElementById('app').removeChild(this.$el)
		    			this.$destroy()
						resolve(msg)
					})
					NewNoteModal.$on('cancel', function(msg){
						StayUsers.notes.active = false
						//console.log(this.$el)
						document.getElementById('app').removeChild(this.$el)
						this.$destroy()
						reject("canceled")
					})
					NewNoteModal.$on('hide', ()=>{StayUsers.notes.active = false})
					NewNoteModal.$mount()
					document.getElementById('app').appendChild(NewNoteModal.$el)
				})
			},

			edit: function(event){
				console.log(event)
				return StayUsers.notes.new({event: event})
			},

			destroy: async function(event){
				try{
					let confirm = await Vue.prototype.$staymodals.confirm("Are you sure you want to delete this note?")
					if(confirm){
						Vue.prototype.$stayhttp.delete(`${Vue.prototype.$user.url}/events/${event.id}`).then(response=>{
							if(pageData.prospect){
								Vue.prototype.$stayfunctions.delayedDeleteById(pageData.prospect.events, response.data.data.id)
							} else {
								Vue.prototype.$stayfunctions.delayedDeleteById(pageData.user.events, response.data.data.id)
							}
							return response.data.data
						}, err=>{
							throw(err)
						})
					} else {
						throw('canceled')
					}
				} catch(err){
					throw(err)
				}
			}
		}

		StayUsers.pets = {
			destroy: async function(pet){
				try{
					let confirm = await Vue.prototype.$staymodals.confirm("Are you sure you want to delete this pet?")
					if(confirm){
						let response = await Vue.prototype.$stayhttp.delete(`${Vue.prototype.human.url}/pets/${pet.id}`)
						Vue.prototype.$stayfunctions.delayedDeleteById(pageData.human.pets.data, response.data.pet.id)
						return response
					} else {
						throw("canceled")
					}
				} catch(err){
					throw(err)
				}
			}
		}
	}
}