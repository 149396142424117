<template>
	<div :class="`hero ${_variant} text-${hero.text_variant || 'dark'} bg-${hero.background_variant || 'light'}`" v-loading.center="_loading">

		<!-- PROGRESS BAR IF IMAGE IS EDITED AND BEING PROCESSED -->
		<transition name="fade">
			<b-progress v-if="$jobs.active.length > 0" :value="$jobs.percent_complete" height="5px" />
		</transition>

		<!-- IMAGE VARIANTS IN REVERSE-PRIORITY ORDER -->
		<transition name="fade"><div class="hero-image preload" v-if="loaded.preload" :style="{ backgroundImage: `url(${hero.preload})`}"></div></transition>
		<transition name="fade"><div class="hero-image medium" v-if="loaded.medium" :style="{backgroundImage: `url(${images.medium.src})`}"></div></transition>
		<transition name="fade"><div class="hero-image large" v-if="loaded.large" :style="{backgroundImage: `url(${images.large.src})`}"></div></transition>

		<!-- CANVAS PROVIDED IF THE IMAGE IS EDITED -->
		<transition name="fade"><div class="hero-image canvas" v-show="image && image.canvas" ref="heroCanvas"></div></transition>

		<!-- OVERLAYS AND CONTENT -->
		<div class="hero-overlay" :style="{backgroundColor: overlayBackground}"></div>
		<div class="hero-content">
			<div class="hero-edit" v-if="editable">
				<icon-button class="p-1" icon="camera" @click="handleEdit" tooltip="Edit Hero Image" :variant="hero.text_variant"></icon-button>
			</div>
			<slot>Default Content</slot>
		</div>



	</div>
</template>

<script>
	import ImageDetails from 'common/image_details'
	import {BProgress} from 'bootstrap-vue'
	import IconButton from 'common/icon_button'
	import Http from 'mixins/http'
	import { Pluralize } from 'entity'
	import Jobs from 'mixins/jobs'

	export default {
		props: {
			// Expects a :hero json attribute
			// if you pass entity_type and entity_id
			// the hero will be editable
			value: Object,
			entity_type: String,
			entity_id: String | Number,
			loading: Boolean,

			// Configuration settings:	
			variant: String 		// Force a certain variant regardless of the screen dimentions
		},
		data(){
			return {
				width: null,
				image: {},		// Where an edited Image object from the server is stored
				loaded: {
					preload: false,
					medium: false,
					large: false
				},
				images: {
					preload: null,
					medium: null,
					large: null
				},
				internal_loading: false,
				subscription: null
			}
		},
		methods: {

			updateImages(){
				this.width = this.$el.offsetWidth
				//this.clearImages()
				for(const variant in this.hero){
					if(this.hero[variant]){
						this.getImg(variant)
					} else {
						this.images[variant] = null
						this.loaded[variant] = false
					}
				}
			},

			getImg(variant){
				if(!['preload', this._variant].includes(variant)) return // Only get this image if it is needed for this size or is the preload
				this.images[variant] = new Image()
				this.images[variant].onload = () => this.loaded[variant] = true
				this.images[variant].src = this.hero[variant]
			},


			handleImageUpdate(image){
				console.log("Image updated...")
				console.log(image)

				this.$jobs.subscribe(image, image => {
					this.$set(this, 'image', image)
					this.$emit('input', this.image.type_to_entity.hero)
					if(image.canvas){
						console.log("Canvas included and being mounted...")
						this.$refs.heroCanvas.appendChild(image.canvas)
						//this.$nextTick(()=>this.canvas_loaded = true)
					}
					this.$nextTick(this.updateImages)
				})
				
			},

			handleEdit(){
				this.$modals().show('image-details', {url: this.url, type: 'hero', text: 'Hero Text'}).then(modal => {
					modal.$on('input', this.handleImageUpdate)
				})
			},


			// setSubscription(){
			// 	if(this.subscription) this.clearSubscription()
			// 	this.subscription = this.$root.$on(`images:${this.image.id}:updated`, this.handleImageUpdate)
			// },


			// clearSubscription(){
			// 	this.$root.$off(`images:${this.image.id}:updated`, this.handleImageUpdate)
			// }

		},

		mounted(){
			this.$nextTick(this.updateImages)
		},

		mixins: [Http, Jobs],
		computed: {
			_variant(){
				return this.variant || this.width >= 1000 ? 'large' : 'medium'
			},
			hero(){
				return this.value
			},
			editable(){
				return this.entity_type && this.entity_id ? true : false
			},
			url(){
				return this.editable ? `/${Pluralize(this.entity_type)}/${this.entity_id}` : null
			},

			_loading: {
				get(){
					return this.loading || this.internal_loading || this.$jobs.active.length > 0
				},
				set(val){
					this.internal_loading = val
				}
			},

			jobs(){
				return this.image && this.image.jobs_without_data || []
			},

			active_jobs(){
				return this.jobs && this.jobs.filter(job => ['working','queued'].includes(job.status))
			},

			active_jobs_at(){
				return this.active_jobs.reduce((prev, curr) => prev + (curr.at || 0), 0)
			},

			active_jobs_total(){
				return this.active_jobs.reduce((prev, curr) => prev + (curr.total || 0), 0)
			},

			overlayBackground(){
				if(this.hero.brightness > 0){
					return `rgba(255,255,255,${this.hero.brightness/100})`
				} else if(this.hero.brightness < 0){
					return `rgba(0,0,0,${-this.hero.brightness/100})`
				} else {
					return "Transparent"
				}
			},
		},
		components: {
			IconButton, BProgress
		},
		modals: [ImageDetails]
	}
</script>

<style lang="scss">
	@import 'stylesheets/variables';

	.hero {
		position: relative;
		display: block;
		overflow: hidden;

		&.medium {
			min-height: 200px;
		}

		&.large {
			min-height: 400px;
		}

		.progress {
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			z-index: 6;
		}


		.hero-image {
			@include fill;
			background-size: cover;
			background-position: center;
			z-index: 1;

			canvas {
				width: 100%;
				height: 100%;
			}



			&.medium, &.large {
				z-index: 2;
			}

			&.canvas {
				z-index: 3;
			}

			&.preload {
				@include better-blur(4)
			}
		}


		.hero-overlay{
			@include fill;
			z-index: 4;
		}

		.hero-content{
			@include fill;
			display: flex;
			flex-direction: column;
			justify-content: center;
			z-index: 5;

			.hero-edit{
				position: absolute;
				right: 2rem;
				top: 2rem;
			}

			.jumbotron{
				// Common to run the jumbotron in the slot for this component
				background-color: Transparent !important;
			}
		}

	}

</style>