<template>
	<b-modal id="user-signature" :title="(initials ? 'Initials' : 'Signature')" :visible="visible" @shown="handleLoad" :ok-disabled="okDisabled" :ok-title="`Save ${(initials ? 'Initials' : 'Signature')}`" @ok="handleSave" @cancel="$emit('cancel')">
		<VueSignaturePad
      width="500px"
      height="300px"
      ref="signaturePad"
      :options="{ onBegin, onEnd }"
    />
    <signature-consent v-model="signature_data.consent.status" v-if="!noConsent" />
	</b-modal>
</template>

<script>
	export default {
		data() {
			return {
				visible: true,
				empty: true,
				option: {
					penColor:"rgb(0, 0, 0)",
					backgroundColor:"rgb(255,255,255)"
				},
				signature_data: {
					signature: {
						image: null
					},
					initials: {
						image: null
					},
					consent: {
						status: null
					}
				}
			}
		},
		props: {
			user: Object,
			initials: Boolean,
			'no-consent': Boolean,
			'consent-first': Boolean,
			promise: Boolean
		},
		methods: {
			handleLoad() {
				this.$refs.signaturePad.onResizeHandler()
			},
			onBegin() {
				//console.log("Started draw")
				this.empty = false
			},
			onEnd() {
				//console.log("Ended draw")
				this.$emit('click')
			},
			handleInput() {
				this.$emit('input', this.signature_data)
			},
			handleSave() {
				if(!this.empty){
					var signature = this.$refs.signaturePad.saveSignature()
					if(this.initials){
						this.signature_data.initials.image = signature.data
					} else {
						this.signature_data.signature.image = signature.data
					}
					this.$bvModal.hide('user-signature')
					this.handleInput()
					if(!this.promise){
						let url = this.user.user_data.url + "/signature"
						this.post(url, {
							signature_data: this.signature_data
						}).then(response => {
							console.log(response)
							this.$emit('save', response.data.data)
							//pageData.signature_data = response.data.data
							//this.$stayfunctions.delayedUpdate(pageData.signature_data, response.data.data, 2000)
							//this.$bvModal.hide('user-signature')
							this.$stayfunctions.delayedRefresh()
						}, err => {
							console.log(err)
						})
					}
				}
			},
			saveData(uri, name){
    			var link = document.createElement("a");
  				link.download = name;
  				link.href = uri;
  				document.body.appendChild(link);
  				link.click();
  				document.body.removeChild(link);
  				//delete link;
			}
		},
		computed: {
			okDisabled(){
				return (this.empty || !(this.signature_data.consent.status || this.noConsent))
			}
		}
	}
</script>