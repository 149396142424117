<template>
	<div>
		<transition-group name="fade">
			<div v-if="!updated" key="update">
				<span class="mt-3 mb-5">Please enter or confirm your email address and select the types of emails that you want to receive from us. We will update your preferences immediately.</span>
				<b-form-group label="Email Address" class="mt-4">
					<stay-input v-model="email_opt_out.email_address" email required ref="email_address" invalid-text="Valid email address is required" name="email_address"></stay-input>
				</b-form-group>

				<b-form-group label="Email Types" class="mt-4 mb-5">
					<b-form-checkbox v-model="all" @change="updateAll" switch>All</b-form-checkbox>
				</b-form-group>

				<b-form-group v-for="opt_out in email_opt_out_options" :label="opt_out.name" class="mt-4">
					<div :id="opt_out.name">
						<b-form-checkbox v-model="selected" :value="opt_out.name" switch :name="`${opt_out.name}-opt-out`">{{opt_out.description}}</b-form-checkbox>
						<small class="text-info">{{opt_out.frequency}}</small>
					</div>
				</b-form-group>

				<b-button variant="success" class="mt-5" block @click="submit">Update Preferences</b-button>
			</div>

			<div v-if="updated" key="updated" class="m-5 success d-flex align-items-center justify-content-center">
				<success-icon />
				Your email preferences have been updated!
			</div>
		</transition-group>
	</div>
</template>

<script>
	import difference from 'lodash/difference'
	import merge from 'lodash/merge'
	import email from 'vuelidate/lib/validators/email'
	import Http from 'mixins/http'
	import SuccessIcon from 'common/success_icon'
	import Validation from 'mixins/validation'
	import StayInput from 'common/stay_input'
	import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'
	export default {
		props: {
			email_opt_out_options: Array,
			email_opt_outs: Array, // The actual model from the db
			email_address: String,
			email_id: Number
		},
		mixins: [Validation, Http],
		data(){
			return {
				email_opt_out: {
					email_address: null,
					email_id: null
				},
				all: true,
				selected: [],
				loading: false,
				updated: false
			}
		},
		mounted(){
			if(this.email_address){
				this.email_opt_out.email_address = this.email_address
				this.$nextTick(() => this.$refs.email_address.update())
			}
			if(this.email_id){
				this.email_opt_out.email_id = this.email_id
			}
			this.selected = difference(this.options, (this.email_opt_outs ? this.email_opt_outs : []))
		},
		methods: {
			updateAll(val){
				if(val){
					this.selected = this.options
				} else {
					this.selected = []
				}
			},
			submit(){
				if(this.validate()){
					//console.log("Valid")
					this.loading = true
					this.post("/unsubscribe", {
						email_opt_out: merge(this.email_opt_out, {opt_outs: this.value})
					}).then(res => {
						console.log(res)
						this.updated = true
						this.loading = false
					}, err=>{
						console.log(err)
						this.loading = false
					})
				}
			}
		},
		computed: {
			options(){
				return this.email_opt_out_options.map((o)=>{ return o.name })
			},
			allSelected(){
				return this.selected.length === this.options.length
			},
			value(){
				return difference(this.options, this.selected)
			}
		},
		watch: {
			selected(val, old){
				if(val.length === this.options.length){
					this.all = true
				} else {
					this.all = false
				}
			},
			loading(val){
				this.$emit('loading', val)
			}
		},
		// validations: {
		// 	email_opt_out: {
		// 		email: { email }
		// 	}
		// },
		components: {SuccessIcon, StayInput, BFormGroup, BFormCheckbox}
	}
</script>

<style lang="scss">
	.success {
		height: 15rem;

		.success-checkmark, .error-exclamation {
			position: relative;
			width: 100px;
			height: 100px;
		}
	}
	
</style>
