<template>
	<b-modal id="image-details" :title="type" :visible="visible" :size="type == 'hero' ? 'lg' : 'sm'" @ok.prevent="handleSave">
		<div class="image-details w-100" ref="canvas">
			<b-row v-if="image.type.background_variant || image.type.text_variant || image.type.brightness" class="mb-3">
				<b-col :cols="12" :lg="6" v-if="image.type.background_variant" class="mt-3">
					<label>Background Color</label>
					<color-picker :variant.sync="image.type.background_variant" :brightness="image.type.brightness" />
				</b-col>
				<b-col :cols="12" :lg="6" v-if="image.type.background_variant" class="mt-3">
					<label>Text Color</label>
					<color-picker :variant.sync="image.type.text_variant" />
				</b-col>
				<b-col :cols="12" :lg="6" v-if="image.type.brightness != undefined" class="mt-3">
					<label>Brightness {{image.type.brightness}}</label>
					<b-form-input type="range" min="-100" max="100" v-model="image.type.brightness" />
				</b-col>
			</b-row>
			<upload v-if="!image.url" @input="handleUpload" :url="`${_url}/images/${type}`" border icon="image" accepts="image/png, image/jpeg, image/heic" />
			<image-cropper v-model="image" v-if="image.url && edit" :text="text" />

			<div class="image-preview w-100 d-flex justify-content-center align-items-center flex-column" v-if="variant && variant.url && !edit">
				<div class="image-preview-actions w-100 text-right">
					<icon-button icon="edit" tooltip="Edit image" @click="edit = true" />
					<icon-button icon="trash" variant="danger" tooltip="Delete this image" @click="handleDestroy" />
				</div>
				<div :class="`image-preview-container ${image.image_type}`">
					<div class="image-preview-overlay"></div>
					<img :src="variant.url" v-if="variant && variant.url && !edit" style="max-width: 100%;" />
				</div>
			</div>
		</div>

		<div class="variant-previews w-100" v-if="variants.length > 1">
			<collapse title="Show additional variants" :disabled="!variant_urls || variant_urls.length ==0">
				<div v-for="variant in variants" :key="variant.name">
					<label>{{variant.name}}</label>
					<img :src="variant.url" v-if="variant.url" class="variant-preview" />
				</div>
			</collapse>
		</div>


		<template #modal-footer="{ok, cancel}">
			<div class="d-flex justify-content-between w-100">
				<icon-button icon="more-horizontal">
					<b-dropdown-item button variant="danger" @click="handleDestroy">Reset this {{type}}</b-dropdown-item>
				</icon-button>
				<div>
					<b-button variant="secondary" @click="cancel">Cancel</b-button>
					<b-button variant="primary" @click="ok" :disabled="!$dirty.is_dirty">Update</b-button>
				</div>
			</div>
		</template>
	</b-modal>
</template>

<script>
	import { BModal, BFormInput, BDropdownItem } from 'bootstrap-vue'
	import IconButton from 'common/icon_button'
	import ColorPicker from 'common/color_picker'
	import Upload from 'common/upload'
	import Http from 'mixins/http'
	import ImageCropper from 'common/image_cropper'
	import Collapse from 'common/collapse'
	import Dirty from 'mixins/dirty'

	export default {
		components: { BModal, BFormInput, BDropdownItem, Upload, ImageCropper, ColorPicker, IconButton, Collapse },
		mixins: [Http, Dirty],
		name: 'image-details',
		props: {
			type: String,
			url: String, 		// You can either set a URL or pass the Entity
			value: Object,		// as a value assuming the URL is part of it!
			text: String,
			visible: Boolean
		},
		data(){
			return {
				image: {
					type: {},
					variants: []
				},
				upload: {},
				scale: 1,
				edit: false
			}
		},
		computed: {
			variants(){
				return this.image.variants || []
			},
			variant(){
				return this.variants.reduce((prev, current) => (prev.width > current.width) ? prev : current, {})
			},
			variant_urls(){
				return this.variants.map(variant => variant.url)
			},
			_url(){
				return this.url || this.value.url
			}
		},
		methods: {

			fetchImage(){
				this.get(`${this._url}/images/${this.type}`).then(res => {
					console.log(res)
					this.image = res.data.image
					this.$dirty.commit()
				})
			},

			handleUpload(res){
				if(res.image.edit_on_initialize){
					this.image = res.image
					this.edit = true
				} else {
					this.$emit('input', res.image)
					this.$modals().hide('image-details')
				}
			},

			handleDestroy(){
				this.$modals().confirm(`Are you sure you want to reset this ${this.type} image? This can\'t be undone.`).then(confirm => {
					if(!confirm) return
					this.delete(`${this._url}/images/${this.type}`).then(res => {
						console.log(res)
						this.image = res.data.image
						this.$emit('input', res.data.image)
						this.$dirty.commit()
					})
				})
			},


			handleSave(){
				this.put(`${this._url}/images/${this.type}`, {image: this.image}).then(res => {
					console.log(res)
					this.$emit('input', {...res.data.image, ...{canvas: this.image.canvas}})
					this.$nextTick(() => this.$modals().hide('image-details'))
				})
			}

		},
		mounted(){
			this.fetchImage()
		},
		dirty: ['image']
	}
</script>

<style lang="scss">
	@import 'stylesheets/variables';

	img.variant-preview {
		max-width: 100%
	}

	.image-preview-container {
		position: relative;
		width: 100%;

		&.avatar {
			max-width: 200px;
			max-height: 200px;
		}
	}


</style>

