<template>
	<div class="rsvp-form">
		<transition-group name="fade">
			<div v-if="(invite || event.open_enrollment) && !completed && available" key="invite">
				<p v-if="invite">Make sure the information below is correct and then click the button to confirm your spot.</p>
				<p v-else>Enter your information below to hold your spot. We will send you a confirmation email.</p>

				<b-form-group label="error" v-if="error">
					{{error}}
				</b-form-group>


				<b-form-group label="Name">
					<span v-if="invite && invite.invitee.fname">{{invite.invitee.fname}} {{invite.invitee.lname}}</span>
					<stay-input v-else v-model="internal_invite.name" name="invitee_name" invalid-text="Your name is required" required ref="name" />
				</b-form-group>

				<b-form-group label="Email">
					<span v-if="invite && invite.invitee.email">{{invite.invitee.email}}</span>
					<stay-input v-else v-model="internal_invite.email" name="invitee_email" placeholder="jane.doe@example.com" invalid-text="A valid email address is required" required email ref="email" />
				</b-form-group>

				<div class="w-100 d-flex justify-content-between border-top p-3">
					<h5>Text me updates if the event changes</h5><b-form-checkbox v-model="internal_invite.sms_opt_in_at" :value="new Date().toISOString()" name="sms_opt_in_checkbox"></b-form-checkbox>
				</div>

				<div v-if="sms_opt_in" class="border-top p-3"> 
					<b-form-group label="Phone">
						<span v-if="invite && invite.invitee.phone">{{invite.invitee.phone}}</span>
						<stay-input v-else v-model="internal_invite.phone" invalid-text="A valid phone number is required" required phone :validate-if="sms_opt_in && !invite.invitee.phone" ref="phone" />
						<!--<phone-number-input v-else v-model="internal_invite.phone"></phone-number-input>-->
					</b-form-group>

					<xsmall class="mt-1">We will only be using your permission here to provide updates about this specific event. Carrier message rates will of course apply.</xsmall>
				</div>

				<div class="w-100 d-flex justify-content-between border-top p-3" v-if="invite">
					<a :href="event.external_url">Is this not you?</a>
				</div>

				

				<b-button variant="success" block class="mt-3" @click="handleSave" name="sign_up">Sign Up!</b-button>
			</div>



			<div v-if="!available || (!invite && !event.open_enrollment)" key="no_invite">
				<p>This event isn't currently open for enrollment. For more information on how you can attend this event or events like this, please contact us using the contact information in the menu above.</p>

				<b-button @click="$root.$refs.navigation.$refs.contact_nav.visible = true">Contact us</b-button>
			</div>

			<div v-if="completed" key="completed" class="m-5 success d-flex flex-column align-items-center justify-content-center">
				<success-icon size="xl" />
				<p class="mt-3">You are registered for this event. contact us if you need to cancel.</p>
				<b-button @click="$root.$refs.contact_nav.visible = true">Contact Us</b-button>
			</div>
		</transition-group>

	</div>
</template>

<script>
	//import { required, requiredIf, email } from 'vuelidate/lib/validators'
	import SuccessIcon from 'common/success_icon'
	import StayInput from 'common/stay_input'
	import Http from 'mixins/http'
	import Validate from 'mixins/validation'
	import {BFormGroup, BFormCheckbox} from 'bootstrap-vue'
	import {in_future} from 'functions/date'


	const validPhone = (phone)=>{
		if(phone){
			return phone.valid
		} else {
			return true
		}
	}
	export default {
		name: 'rsvp-form',
		data(){
			return {
				internal_invite: {
					name: null,
					email: null,
					sms_opt_in_at: null,
					phone: null,
					uuid: null
				},
				completed: false,
				error: null
			}
		},
		props: {
			invite: Object
		},
		computed: {
			event(){
				return pageData.event
			},
			url(){
				return this.internal_invite.id ? `/events/${this.event.slug}/invites/${this.internal_invite.uuid}` : `/events/${this.event.slug}/invites`
			},
			sms_opt_in(){
				return (this.internal_invite.sms_opt_in_at ? true : false)
			},
			daysAgo(){
				return days_ago(this.event.start_time)
			},
			is_in_future(){
				return in_future(this.event.start_time)
			},
			available(){
				return !this.event.canceled && this.is_in_future
			}
		},
		methods: {
			handleSave(){

				if(!this.validate()) {
					this.error = "Your entry is invalid"
					return false
				}
				
				this.internal_invite.rsvp_at = new Date().toISOString()

				if(this.invite && this.invite.id){
					// We will be updating an existing invite:
					this.put(this.url, {invite: this.internal_invite}, {loader: true, alert: false})
					.then(res => {
						this.internal_invite = res.data.data
						this.completed = true
					})


				} else {
					// New invite:
					this.post(this.url, {invite: this.internal_invite}, {loader: true, alert: false})
					.then(res => {
						this.internal_invite = res.data.data
						this.completed = true
					})

				}

			}
		},
		mounted(){
			if(this.invite){
				this.internal_invite = this.invite
				if(this.internal_invite.rsvp_at || this.internal_invite.waitlist_at){
					this.completed = true
				}
			}
		},
		components: {SuccessIcon, BFormGroup, BFormCheckbox, StayInput},
		mixins: [Http, Validate],
	}
</script>

<style lang="scss">
	.success {
		height: 15rem;

		.success-checkmark, .error-exclamation {
			position: relative;
			width: 100px;
			height: 100px;
		}
	}
	
</style>