<template>
	<div :class="`empty-state d-flex align-items-center justify-content-center flex-column${card ? ' list-group-item item' : ''}${border ? ' border' : ''}`">
		<icon v-model="icon" size="xxl" />
		<span class="d-block w-100 text-center mt-3">{{text}}</span>
		<b-button v-if="action" @click="$emit('action')" :variant="variant" class="mt-5">{{action}}</b-button>
	</div>
</template>

<script>
	import Icon from 'common/icon'
	export default {
		props: {
			icon: {
				type: String,
				default: 'x'
			},
			text: {
				type: String,
				default: 'Nothing here. Add something!'
			},
			border: Boolean,
			card: Boolean,
			action: String,
			variant: {
				type: String,
				default: "primary"
			}
		},
		components: {Icon}
	}
</script>

<style lang="scss">
	@import 'stylesheets/colors';

	.empty-state {
		padding: 4rem;

		&.border {
			border: dotted 0.5px $secondary-color;
		}

		// svg {
		// 	width: 4rem;
		// 	height: 4rem;
		// 	stroke-width: 0.25px;
		// }

		// .icon {
		// 	font-size: 4rem !important
		// 	height: 4rem !important
		// }
	}
</style>