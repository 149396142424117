<template>
	<div class="user-tags d-flex flex-wrap">
			<div class="results-overlay" @click="active=false" v-if="active"></div>
			<div :class="{tag: true, 'bg-dark': (selected == index), 'text-light': (selected == index), 'text-truncate': true}" v-for="(tag, index) in user.tags" :key="index" @click="handleSelect(index)" :id="`tag${index}`">
				<span v-if="highlight && sanitized_highlights.includes(tag)" v-html="highlight[sanitized_highlights.indexOf(tag)]"></span>
				<span v-else>{{tag}}</span>
				<b-popover placement="bottom" :show="selected == index" :target="`tag${index}`" v-if="!noEdit">
					<b-button variant="danger" size="small" @click="handleDestroy(index)">Remove</b-button>
					<b-button variant="outline-secondary" size="small" @click="handleSelect(index)">Cancel</b-button>
				</b-popover>
			</div>
			<div class="tag-empty" @click="handleAdd" :style="{width: (active ? '200px' : '80px')}" v-if="!noEdit">
				<input name="tag" placeholder="Tag Name" ref="tag" v-focus v-model="tag" @keyup.enter="handleSubmit()" v-if="active" autocomplete="off" />
				<span v-else>ADD TAG</span>
				<div class="results-container" v-if="active">
					<div class="results" v-for="(result, index) in results" :key="index">
						<b-button variant="link" size="small" @click="handleSubmit(result)">{{result}}</b-button>
					</div>
					<div class="results-error text-danger" v-if="error">
						Something went wrong getting your frequently used tags
					</div>
				</div>
			</div>
	</div>
</template>

<script>
	import { BPopover } from 'bootstrap-vue'
	import filter from "lodash/filter"
	import Http from 'mixins/http'
	export default {
		props: {
			user: Object,
			noEdit: Boolean,
			highlight: String | Array
		},
		data() {
			return {
				active: false,
				loading: false,
				error: false,
				selected: null,
				tag: null,
				tags: []
			}
		},
		methods: {
			handleAdd(){
				this.active = true
				if(this.tags.length == 0){
					this.loading = true
					this.get(`/tags`, {alert: false, loader: false}).then(response => {
						console.log(response)
						this.tags = response.data.data
						this.loading = false
					}, err => {
						console.log(err)
						this.loading = false
						this.error = true
					})
				}
			},
			handleSelect(index){
				if(!this.noEdit){
					if(index==this.selected){
						this.selected = null
					} else{
						this.selected = index
					}
				}
				this.$emit('click', this.user.tags[index])
			},
			handleSubmit(tag=null){
				console.log("Submitting tag...")
				tag = tag || this.tag
				console.log(tag)
				this.loading = true
				this.post(`${this.user.url}/tags`, {
					tag: tag
				}, {alert: false}).then(response => {
					console.log(response)
					this.user.tags = response.data.data
					this.tags = []
					this.tag = null
					this.active = false
					this.loading = false
					this.selected = null
				})
			},
			handleDestroy(index){
				this.$stayhttp.delete(`${this.user.url}/tags/${index}`, {}, {alert: false}).then(response => {
					console.log(response)
					this.selected = null
					this.user.tags = response.data.data
				}, err => {
					console.log(err)
					this.selected = null
				})
			}
		},
		computed: {
			results(){
				if(this.tag){
					return this.tags.filter(tag => tag.toLowerCase().includes(this.tag.toLowerCase()))
				} else {
					return this.tags
				}
			},
			sanitized_highlights(){
				if(this.highlight){
					if(typeof(this.highlight)=="string"){
						return [this.highlight.replace(/(<([^>]+)>)/gi, "")]
					} else {
						return this.highlight.map((h)=>{
							return h.replace(/(<([^>]+)>)/gi, "")
						})
					}
				} else {
					return null
				}
			}
		},
		components: { BPopover },
		mixins: [Http]
	}
</script>

<style lang="scss">
	@import '../common/shared.scss';

	.user-tags {
		font-size: 0.5rem;
		color: $secondary-color;
		position: relative;

		.results-overlay{
				position: absolute;
				left: -1000px;
				top: -1000px;
				height: 5000px;
				width: 5000px;
				z-index: 19;
		}

		.tag {
			margin: 0.2rem;
			display: inline-block;
			width: auto;
			border: 0.5px solid $dark-color;
			color: $dark-color;
			border-radius: 2px;
			padding: 0.2rem 0.5rem 0.2rem 0.5rem;
			cursor: pointer;

			em {
				background-color: $info-color;
				color: white;
				font-weight: bold;
				text-decoration: none;
			}
		}

		.tag-empty {
			margin: 0.2rem;
			display: block;
			position: relative;
			transition: width 0.3s ease-in;
			//overflow: hidden;
			border: 0.5px solid $secondary-color;
			border-radius: 2px;
			padding: 0.2rem 0.5rem 0.2rem 0.5rem;

			input {
				border: none;
				margin: none;
				background-color: Transparent;
				width: 100%;
				line-height: 0.8rem;
			}

			input:focus {
				outline: 0;
			}

			.results-container{
				display: block;
				position: absolute;
				left: 0;
				top: 1.4rem;
				width: 100%;
				z-index: 20;

				.results {
					width: 100%;
					background-color: $light-color;
					border-bottom: 0.5px solid $secondary-color;
					border-left: 0.5px solid $secondary-color;
					border-right: 0.5px solid $secondary-color;
					padding: 0.2rem;
					color: $dark-color;
					cursor: pointer;

					button {
						color: $dark-color !important;
					}
				}
			}
		}
	}
</style>