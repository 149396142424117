// The dirty mixin provides capabilities to the component to watch data and determine if it has been updated
// This is most useful when allowing a user to change things on the screen and save bulk changes all at once
// by only passing updated data to the server

// Some configurations that can be passed:


import get from 'lodash/get'
import set from 'lodash/set'
import isEqual from 'lodash/isEqual'
import { getDiff, applyDiff } from 'recursive-diff'


export default {
	data(){
		return {
			dirty_store: {},
			dirty_watchers: [],
			dirtyForceUpdate: 0,
			dirtyHasBeenCommitted: false,
			dirtyWasDirty: false,
			isEqual: isEqual
		}
	},
	created(){
		//this.commit_model = (this.commit_key ? this.$data : get(this.$data, this.commit_key))
		//console.log("Monitoring for dirt...")
		// if(!this.$dirty_model_data){
		// 	console.warn("Dirty.js was mixed but cannot locate any data using the provided keys! Set the appropriate value on the component using 'dirty_key'!")
		// } else {
		// 	this.$dirty = new Dirty(this)
		// }
	},
	methods: {
		$dirty_commit(){
			this.$dirty_keys.forEach(key => {
				let data = get(this, key)
				console.log(data)
				this.$set(this.dirty_store, key, data ? JSON.parse(JSON.stringify(data)) : undefined)
			})
			//this.$emit('dirty:commit', this.dirty_store)
			this.dirtyHasBeenCommitted = true
			this.$dirty_trigger_event('commit')
			this.$forceUpdate()
		},
		$dirty_restore(){
			this.$dirty_keys.forEach(key => {
				let key_array = key.split('.')
				key_array.splice(-1)
				if(key_array.length==0){
					this.$set(this, key, this.dirty_store[key])
				} else {
					this.$set(get(this, key_array), key, this.dirty_store[key])
				}
			})
		},
		$dirty_diff(key=null){
			if(key){
				return getDiff(JSON.parse(JSON.stringify(this.dirty_store[key])), JSON.parse(JSON.stringify(get(this, key))))
			}
			let diff = {}
			this.$dirty_keys.forEach(key => {
				diff[key] = getDiff(JSON.parse(JSON.stringify(this.dirty_store[key])), JSON.parse(JSON.stringify(get(this, key))))
			})
			return diff
		},
		$dirty_path(path){
			return {old: get(this.dirty_store, path), new: get(this, path)}
		},
		$dirty_added(key=null){
			if(key){
				return this.$dirty_diff(key).filter(diff => (diff.op == 'add' || (diff.op == 'update' && !get(this.dirty_store[key], diff.path)))).map(filtered_diff => filtered_diff.path.join('.'))
			} else {
				let added = {}
				this.$dirty_keys.forEach(key => {
					added[key] = this.$dirty_diff(key).filter(diff => diff.op == 'add' || (diff.op == 'update' && !get(this.dirty_store[key], diff.path))).map(filtered_diff => filtered_diff.path.join('.'))
				})
				return added
			}
		},
		$dirty_updated(key=null){
			if(key){
				return this.$dirty_diff(key).filter(diff => (diff.op == 'update' && diff.val)).map(filtered_diff => filtered_diff.path.join('.'))
			} else {
				let added = {}
				this.$dirty_keys.forEach(key => {
					added[key] = this.$dirty_diff(key).filter(diff => diff.op == 'update' && diff.val).map(filtered_diff => filtered_diff.path.join('.'))
				})
				return added
			}
		},
		$dirty_deleted(key=null){
			if(key){
				return this.$dirty_diff(key).filter(diff => (diff.op == 'delete' || (diff.op == 'update' && !diff.val))).map(filtered_diff => filtered_diff.path.join('.'))
			} else {
				let added = {}
				this.$dirty_keys.forEach(key => {
					added[key] = this.$dirty_diff(key).filter(diff => diff.op == 'delete' || (diff.op == 'update' && !diff.val)).map(filtered_diff => filtered_diff.path.join('.'))
				})
				return added
			}
		},
		$dirty_changed(key=null){
			if(key){
				return this.$dirty_diff(key).map(diff => diff.path.join('.'))
			} else {
				let added = {}
				this.$dirty_keys.forEach(key => {
					added[key] = this.$dirty_diff(key).map(diff => diff.path.join('.'))
				})
				return added
			}
		},
		$dirty_is_changed(path){
			let val = this.$dirty_path(path)
			return !isEqual(val.new, val.old)
		},
		$dirty_forceUpdate(){
			this.dirtyForceUpdate++
			this.$forceUpdate()
		},
		$dirty_save(path, key=null, server_key=null, options={}, callback=null){
			let data = {}
			if(key){
				data = {[server_key || key]: get(this, key)}
			} else {
				if(server_key) data[server_key] = {}
				this.$dirty_keys.forEach(key => {
					if(server_key) { data[server_key][key] = get(this, key) } else { data[key] = get(this, key) }
				})
			}
			this.put(path, data, options).then(res => {
				console.log(res)
				if(callback) callback(res)
			})
			return res
		},
		$dirty_on(event, func){
			return this.dirty_watchers.push([event, func])
		},
		$dirty_trigger_event(event){
			this.$emit(`dirty:${event}`, this.dirty_store)
			if(event == 'dirty') this.dirtyWasDirty = true
			if(event == 'commit' || event == 'reset') this.dirtyWasDirty = false
			let funcs = this.dirty_watchers.filter(arr => arr[0] == event).map(arr => arr[1])
			funcs.forEach(func => func(this.$dirty_diff))
		}
	},
	mounted(){
		this.$dirty_commit()
	},
	computed: {
		$dirty(){
			return {
				is_dirty: this.$is_dirty,			// Is anything on the component dirty? A reactive variable, not function
				commit: this.$dirty_commit,			// Update the store to the current value and is_dirty becomes false
				reset: this.$dirty_restore,			// Update the current to the store annd is_dirty becomes false
				diff: this.$dirty_diff,	 			// The raw diff object		
				path: this.$dirty_path, 			// Returns old and new values at a specific path
				changed: this.$dirty_changed,		// Returns an array of all paths that where touched in any way
				is_changed: this.$dirty_is_changed, // Takes a path and lets you know if if changed
				added: this.$dirty_added, 			// Returns an array of paths that have been added (both key added or a value added to existing null key)
				updated: this.$dirty_updated, 		// Returns an array of paths that have been updated (excludes keys that were originally null)
				deleted: this.$dirty_deleted,		// Returns an array of paths that have been deleted
				store: this.dirty_store,
				global: this.$dirty_is_global,
				forceUpdate: this.$dirty_forceUpdate,
				save: this.$dirty_save,
				on: this.$dirty_on  				// Registers a new watcher that can be used by each of the dirty functions to make callbacks, supports: ['dirty', 'commit', 'reset']
			}
		},
		$is_dirty(){
			this.dirtyForceUpdate
			if(!this.dirtyHasBeenCommitted) return false
			let dirty = false
			if(this.$dirty_is_global){
				dirty = this.$dirty_diff().length > 0
			} else {
				dirty = this.$dirty_keys.some(key => {
					return this.$dirty_diff(key).length > 0
				})
			}
			if(dirty && !this.dirtyWasDirty) this.$dirty_trigger_event('dirty')
			return dirty
		},
		$dirty_keys(){
			if(this.$options.dirty && Array.isArray(this.$options.dirty)){
				return this.$options.dirty
			}
			if(this.$options.dirty && typeof(this.$options.dirty) == 'string'){
				return [this.$options.dirty]
			}
			console.warn("Dirty mixin was mounted without any dirty options so dirty will be monitoring the component's $data")
			return ['$data']
		}
	},

}