<template>
	<div id="stay-typeahead" :class="{'form-control': !noFormControl, 'stay-typeahead': true, hidden: (hideInput && !focused), multi: multi}" v-loading="loading" v-on-clickaway="handleBlur" @keydown.delete.prevent="handleDelete">
		<icon-button :icon="icon" show-carat dropright :tooltip="tooltip" hover v-if="icon">
			<template>
				<slot name="dropdown"></slot>
			</template>
		</icon-button>
		<div :class="{'typeahead-input': true, multi: multi}">
			<pill v-for="(item, index) in internal_value" :key="index" v-if="multi" :value="item" @remove="handleRemove(index)" ref="pill" :selected="index == selected_value" :tabindex="index" @keydown.delete.prevent="handleDelete" :underlined="(designateUnconstrained && !item.constrained)" :hideAvatar="hideAvatar" :truncate="truncate" :disabled="disabled" :variant="variant" :no-remove="noEdit">{{item}}</pill>
			<span v-if="!multi && constrained && internal_value" class="single-result">{{internal_value.text}}</span>
			<button v-if="hideInput && !focused && !noEdit" @click="handleFocus" :disabled="disabled"><icon value='edit-2' /></button>
			<slot>
				<input 
					v-model="q" 
					@focus="handleFocus" 
					ref="query_box" 
					:style="{borderBottom : (error ? `dashed 0.75px ${$staycolors.danger}` : 'none')}"
					@keydown.enter.prevent="handleSelect()" 
					@keydown.tab="handleSelect(null, true)"
					@keydown.esc.stop="reset()" 
					@keydown.down.up.prevent="handleArrows($event)"
					@keydown.delete.stop="handleDelete($event)"
					@click="handleFocus"
					:placeholder="has_value ? null : placeholder"
					:class="inputClass"
					v-show="!hideInput || focused"
					:disabled="noEdit"
					@input="handleInput"
					autocomplete="off"
					:id="id"
				/>
			</slot>
			<icon-button icon="x" v-if="showClear" @click="handleClear" />
		</div>
		<div class="results" :style="{opacity: 0, top: top}" v-visible.swing-in-top-fwd.swing-out-top-bck="show_results">
			<empty-state v-if="show_empty_results" text="No Results" icon="search"></empty-state>
			<span v-if="show_initial_results" class="title b-block w-100">{{initialResultsTitle}}</span>
			<div v-if="loading" v-for="index in 3" :key="-(index + 1)" class="result no-click">
				<b-skeleton type="avatar" width="1em" height="1em" class="mr-3 d-inline-block" />
				<b-skeleton width="75%" class="d-inline-block" />
			</div>
			<div v-if="show_initial_results" v-for="(result, index) in formatted_initial_results" :class="{result: true, selected: (selected_index==index)}" @click="handleSelect(result)" :key="index">
				<stay-avatar v-model="result.avatar" :size="30" v-if="result.avatar" />
				<icon v-model="result.icon" v-if="result.icon" />
				{{result.text}}
			</div>

			<div v-if="show_query_results" v-for="(result, index) in formatted_results" :class="{result: true, selected: (selected_index==index)}" @click="handleSelect(result)" :key="index">
				<avatar v-model="result.avatar" :size="30"></avatar>
				<icon v-model="result.icon" v-if="result.icon" />
				{{result.text}}
			</div>
		</div>
		<invalid-text />
	</div>
</template>

<script>
	import isEqual from "lodash/isEqual"
	import at from "lodash/at"
	import { directive as onClickaway } from 'vue-clickaway'
	import Http from "mixins/http"
	import Icon from 'common/icon'
	import IconButton from 'common/icon_button'
	import EmptyState from 'common/empty_state'
	import Pill from 'common/pill'
	import {BSkeleton} from 'bootstrap-vue'
	import Avatar from 'common/avatar'
	import { Validateable, InvalidText } from 'mixins/validation'

	// EVENTS SUPPORTED (DATA PAYLOAD): 
	// initialValueParsed(initial_value OBJECT:raw) - this is run when component is created
	// mounted(vm COMPONENT)
	// focus()
	// blur()
	// input(input_value STRING)
	// inputCleared() - Triggered when someone clicks the show-clear button in the input
	// select(selected_value, OBJECT)
	// remove(removed_value, OBJECT) - this is when a value is removed (not the delete key)
	// noResults() - what to do when the search returns no results?
	// results(results, ARRAY)
	// noInitialResults()
	// initialResults(results, ARRAY)
	// submit(response, OBJECT) - called when the component successfully posts to the server and update

	// CONFIGURATIONS SUPPORTED:
	// showEmptyState: BOOLEAN - the results panel will remain open and show an empty state when there are no results
	

	const Settings = function(props){
		switch(true){

			case (!props.multi && !props.constrained):
				return {
					name: "Single select not constrained",
					configurations: {showEmptyState: false},
					event_callbacks: {
						initialValueParsed: ['setInput'],
						focus: ['showResults'],
						select: ['setInput','hideResults','emitSelect','emitInput','submit'],
						input: ['parseValue','emitInput'],
						inputCleared: ['focusInput'],
						blur: ['hideResults'],
						remove: [],
						noResults: ['hideResults'],
						noInitialResults: [],
						initialResults: [],
						results: ['showResults'],
						submit: []
					}
				}
				break

			case (props.multi):
				return {
					name: "Multi select",
					configurations: {showEmptyState: true},
					event_callbacks: {
						initialValueParsed: [],
						mounted: [],
						focus: ['getResults','showResults'],
						input: [],
						select: ['clearInput','hideResults','emitSelect','emitInput','submit'],
						blur: ['hideResults'],
						remove: [],
						noResults: [],
						noInitialResults: [],
						initialResults: [],
						results: ['showResults'],
						submit: []
					}
				}
				break

			default:
				return {
					name: "Default typeahead configuration",
					configurations: {showEmptyState: false},
					event_callbacks: {
						initialValueParsed: ['helloWorld'],
						initialResultsParsed: ['helloWorld'],
						focus: ['getResults','showResults'],
						input: ['showResults'],
						select: ['clearInput','hideResults','emitSelect','emitInput','submit'],
						blur: ['hideResults'],
						remove: [],
						noResults: ['hideResults'],
						noInitialResults: [],
						initialResults: ['showResults'],
						results: ['showResults'],
						submit: []
					}
				}
				break
		}
	}




	export default {
		name: 'typeahead',
		props: {
			id: String, // Primarily used for identifying the INPUT for capybara tests
			name: String, // Assigned to the input element as well
			value: String | Object | Array, // Initial value which is *NOT* monitorred for changes after mounting
			// Results is either a string url for async callbacks,
			// a function that will take a query, or an array of possible options
			results: {
				type: String | Function | Promise | Array,
				default: "/search"
			},
			// Initial Results contain what should be shown in the dropdown
			// ahead of any search. Can also accept a heading to show
			initialResults: {
				type: String | Function | Promise | Array
			},
			initialResultsTitle: {
				type: String,
				default: "Recommended"
			},
			// The method used for both the initial results and the results requests:
			resultsMethod: {
				type: String,
				default: "post"
			},
			// When typeahead calls the initialResults object when mounted, by default it
			// uses the query payload: search: {q: 'initial value'}
			// You can ignore that and send nothing by setting this to true
			ignoreInitialResultsValue: Boolean,
			// The initial results will load immediately unless lazy is set
			// to true. Then they are loaded when the field has focus
			lazy: Boolean,
			// These props will explicitly set the field that should be used
			// for images, text, iconography and values,
			// these can be passed as dot notation for deeply nested (result._source.avatar)
			// If these aren't set the component looks for some default keys
			avatarField: String,
			textField: String,
			iconField: String,
			valueField: String,
			initialAvatarField: String,
			initialTextField: String,
			initialIconField: String,
			initialValueField: String,

			// This controls the structure of the value output
			// for formatting v-model
			constrainedKey: String,
			unconstrainedKey: String,

			//Some additional configurations:
			multi: Boolean, // Allow multi-select
			constrained: Boolean, // Require the value to be explicitly from the results list
			allowDuplicates: Boolean, // Allow duplicate values in the array if multi
			lenientDuplicates: Boolean, // If true this will look for duplicates using :ID params, otherwise it matches the full value object
			noEdit: Boolean, //Disables editing but doesn't change the style of the component
			showClear: Boolean, // Show a clear input button on the far right side of the input

			// Control for saving changes to the server on update
			url: String, // If provided, Typeahead will send the update to the server on change using the method below
			submitMethod: {
				type: String,
				default: "put"
			},
			rootKey: String, // The key to submit the data under to the server, if empty, post at the root level: e.g. document: {name: value} vs name: value

			// Support for icon-button integration
			// uses the named slot "dropdown" to turn it into a menu
			icon: String,
			tooltip: String,

			// Style configurations
			inputClass: String | Array, // Additional classes to apply to the INPUT element
			noFormControl: Boolean, // Removes the form-control class if being embedded in another form control
			hideInput: Boolean, // Controls whether the component shows as an INPUT element or not
			designateUnconstrained: Boolean, // Provide visual indication that the input is not constrained
			placeholder: String, // Placeholder text that goes in the empty input element
			hideAvatar: Boolean, // Show the avatar on the results in the internal_value
			truncate: Number, // Truncate the textField to N characters for the internal_value
			variant: {
				type: String,
				default: "light"
			},
			disabled: Boolean, // Impacts style and disables making any changes but doesn't impact underlying functionality configurations

		},
		data(){
			return {
				loading: false,
				focused: false, // This controls whether the input should be visible
				show_results: false, // This controls whether the results should be visible
				touched: false,	// Set once the input has been changed with a keystroke
				constrained_input: false, // This indicates whether the input is currently showing a constrained value
				q: null,
				initial_q: true, // If this is true, the value in the query box is the initial value on component mount
				is_mounted: false,
				first_get_after_input: true, // This helps us determine if the current loading sequence is the first get which helps with empty state display
				top: "100%",
				initial_results: [],
				query_results: [],
				response: null,
				selected_index: null,
				selected_value: null,
				internal_value: (this.multi ? [] : null),
				error: false, // This handles the UI and external calls
				fields: {
					initial_results: {
						avatar: ['avatar','image','_source.avatar','_source.image'],
						text: ['text','name','title','_source.text','_source.name','_source.title'],
						icon: ['icon','_source.icon'],
						value: ['value','_source']
					},
					query_results: {
						avatar: ['avatar','image','_source.avatar','_source.image'],
						text: ['text','name','title','_source.text','_source.name','_source.title'],
						icon: ['icon','_source.icon'],
						value: ['value','_source']
					}
				},
				settings: null
			}
		},
		directives: {
    		onClickaway: onClickaway,
    	},
		methods: {
			// Methods that CAUSE events:
			// ############################
			//
			initialValueParse(){
				// Check to see if there is an initial value set with the v-model prop
				// We will use the method above to parse the value into something the component recognizes
				if(this.value){
					console.log(`TYPEAHEAD: ${this.name}: INITIAL VALUE PARSE: ${JSON.stringify(this.value)}`)
					if(Array.isArray(this.value)){
						console.log(`TYPEAHEAD: ${this.name}: INITIAL VALUE PARSE: ${JSON.stringify(this.value)}`)
						this.value.forEach(v=>this.parseValue(v))
					} else {
						this.parseValue(this.value)
					}
				}
				this.event_callback("initialValueParsed", this.internal_value)
			},
			parseValue(value){
				// This is being used when the component is mounted to parse an existing
				// value from the $attrs.value attribute.
				// BIG ASSUMPTION: Currently this method assumes that a plain string = unconstrained
				// and an object = constrained. We may need to change this in the future...
				if(typeof(value)=="string" && value != ""){
					if(this.constrained){
						console.warn("Typeahead is constrained and was passed a plain string value which is assumed unconstrained. Ignoring this value.")
					} else {
						if(this.multi){
							this.internal_value.push({text: value, value: value, constrained: false})
						} else {
							this.internal_value = {text: value, value: value, constrained: false}
							//this.q = value // Since this is a single input we will set the "q" param as well which shows in the input
						}
					}
				} else if(typeof(value)=="object" && !Array.isArray(value) && value != null){
					console.warn("The typeahead component is parsing an initial value which is an object. Currently typeahead assumes that objects are all constrained values. If this is in fact unconstrained, you may want to pass it as a plain string.")
					console.warn(value)
					if(this.multi){
						this.internal_value.push({
							avatar: this.oneOf(value, this.fields.initial_results.avatar),
							text: this.oneOf(value, this.fields.initial_results.text),
							icon: this.oneOf(value, this.fields.initial_results.icon),
							constrained: true,
							value: value
						})
					} else {
						this.internal_value = {
							avatar: this.oneOf(value, this.fields.initial_results.avatar),
							text: this.oneOf(value, this.fields.initial_results.text),
							icon: this.oneOf(value, this.fields.initial_results.icon),
							constrained: true,
							value: value
						}
					}
				} else {
					console.warn("Typeahead was provided a value attribute that it doesn't recognize. Ignoring.")
				}
			},
			handleFocus(){
				this.focused = true
				this.event_callback('focus')
			},
			handleBlur(){
				this.focused = false
				this.event_callback('blur')
			},
			handleInput(){
				if(!this.search_valid){
					this.first_get_after_input = true
					return
				}
				if(!this.loading){
					this.loading = true
					setTimeout(()=>{
						this.getResults()
						this.first_get_after_input = false
					}, 1000)
				}
				this.touched = true
				this.constrained_input = false
				this.initial_q = false
				this.event_callback('input', this.q)
			},

			handleClear(){
				this.clearInput()
				this.event_callback('inputCleared')
			},

			// Methods that are generally CALLED by events:
			// ############################
			//
			setInput(value_object){
				// Sets the value currently in the input element (object only)
				if(!value_object) return
				console.log(`TYPEAHEAD: setInput: ${JSON.stringify(value_object)}`)
				this.constrained_input = value_object.constrained
				this.q = value_object.text
			},

			clearInput(){
				this.q = ""
				this.constrained_input = false
				this.first_get_after_input = true
			},

			focusInput(){
				this.$refs.query_box.focus()
			},

			showResults(){
				// WATCHER: This is being watched to load initial results and reset the selected index
				this.show_results = true
			},

			showResultsUnlessEmpty(){
				if(this.show_query_results || this.show_initial_results) this.show_results()
			},

			hideResults(){
				this.show_results = false
			},

			emitInput(){
				console.log(`TYPEAHEAD EMITTING: ${JSON.stringify(this.formatted_value)}`)
				this.$emit('input', this.formatted_value)
			},

			emitSelect(result){
				if(result && result.constrained){
					this.$emit('select', result.value)
				}
			},

			async submit(){
				if(!this.url) return
				try{
					let response = await this[this.submitMethod](this.url, {
						...(this.rootKey && {[this.rootKey]: {[this.name]: this.formatted_value}}),
						...(!this.rootKey && {[this.name]: this.formatted_value})
					}, {alert: false, loader: false})
					this.event_callback('submit', response)
				} catch(err){
					console.error(err)
				}
			},

			// This method will handle both initial results capture and query results capture
			// and then send them to the right methods for processing.
			async getResults(initial=false){
				if(!this.search_valid && !initial) return
				var response = null
				this.loading = true
				try{
					switch(typeof((initial ? this.initialResults : this.results))){
						case "string":
							var data = await this[this.resultsMethod]((initial ? this.initialResults : this.results), (initial && this.ignoreInitialResultsValue ? null : {search: {q: this.q}}), {alert: false, loader: false, events: false})
							// Making this backwards compatible but will now work with our new Eventable strategy
							// which is expecting the results to be passed
							console.log(`TYPEAHEAD HTTP RESPONSE: ${JSON.stringify(data)}`)
							if(data.data.data){
								console.warn("TYPEAHEAD: Old data response model. You may want to update this controller to the new events-based data response model!")
								response = data.data.data
							} else {
								// Here we don't know the key of the actual search result being returned so we need to take the first one
								// that we can find that has a search result in it
								let data_key = Object.keys(data.data).find(key => typeof(data.data[key]) == 'object' && data.data[key].data)
								if(data_key) response = data.data[data_key].data
							}
							break
						case "promise":
							response = await this.results()
							break
						case "object":
							response = this.initialResults
							break
						default:
							throw(`Unsupported prop type results: #{typeof(this.results)}`)
					}
					this.parseResults(response, initial)
					this.loading = false
				} catch(err){
					console.log(err)
				}
			},
			parseResults(data, initial=false){
				// Data is expected to be passed as either an array of results or an
				// object with a results array within it
				if(Array.isArray(data)){
					if(initial){
						this.initial_results = data
					} else {
						this.query_results = data
					}
				} else if(data.results){
					if(initial){
						this.initial_results = data.results
					} else {
						this.query_results = data.results
					}
				} else {
					console.warn("Typeahead wasn't able to recognize the data response:")
					console.warn(data)
				}
				if(initial){
					this.initial_results.length > 0 ? this.event_callback('initialResults', this.initial_results) : this.event_callback('noInitialResults')
				} else {
					this.query_results.length > 0 ? this.event_callback('results', this.query_results) : this.event_callback('noResults')
				}
			},
			handleArrows(event){
				if(event.keyCode==38){ // Arrow Up
					if(this.selected_index >= 0){
						this.selected_index--
					}
				} else if(event.keyCode==40){ // Arrow down
					if(this.selected_index >= 0){
						this.selected_index++
					} else if(this.visible){
						this.selected_index = 0
					}
				}
			},
			handleSelect(result=null, defocus=false){
				this.initial_q = false
				if(result == null){
				// If no result provided, we should either select what is showing
				// in the visible results, or handle the text input
					if(this.show_results && this.validSelectionIndex()){
						// Results are showing so we need to decide what
						// we are returning
						if(this.show_initial_results && this.initial_results.length > 0){
							result = this.formatted_initial_results[this.selected_index]
						} else if(this.show_query_results && this.query_results.length > 0){
							result = this.formatted_results[this.selected_index]
						}
					} else {
						// If no results are showing we can do something
						// with the current text unless constrained is set
						if(!this.constrained){
							result = (this.q != "" && this.q != null ? {
								text: this.q,
								value: this.q,
								constrained: false
							} : null)
						} else {
							console.warn("Typeahead was set to constrained so plain text isn't allowed")
							this.error = true
							return
						}
					}
				}
				if(!this.allowDuplicates && result){
					if(this.checkDuplicates(result)){
						return
					}
				}
				this.error = false
				if(this.multi && result){
					this.internal_value.push(result)
				} else if(result) {
					this.internal_value = result
				}
				this.event_callback('select', result)
			},
			checkDuplicates(result){
				if(this.multi){
					var index = this.internal_value.findIndex((v)=>{
						if(this.lenientDuplicates && typeof(result.value) == 'object'){
							// TODO: Allow lenient duplicates to look for more than just an ID attribute
							return v.value.id == result.value.id
						} else {
							return isEqual(JSON.parse(JSON.stringify(v)), JSON.parse(JSON.stringify(result)))
						}
					})
					if(index >= 0){
						// Call attention to the one that was already selected:
						this.$refs.pill[index].$el.classList.add("jello-vertical")
						setTimeout(()=>{
							this.$refs.pill[index].$el.classList.remove("jello-vertical")
						}, 500)
						return true
					} else {
						return false
					}
				}
			},
			handleDelete(event){
				// Handler for delete key presses
				if((this.q != null) && (this.q.length > 0)){
					return
				}
				event.preventDefault()
				if(this.selected_value == null){
					if(this.multi && this.internal_value.length > 0){
						this.selected_value = this.internal_value.length - 1
						this.$refs.query_box.blur()
						
					} else if(!this.multi && this.internal_value){
						this.q = this.internal_value.text
						this.internal_value = null
					}
				} else {
					this.handleRemove(this.selected_index)
				}
			},
			handleRemove(index){
				// handler for removing an item
				let removed = this.internal_value.splice(index, 1)
				this.$emit('input', this.formatted_value)
				this.event_callback('remove', removed)
			},
			deleteListener(event){
				// This is only used when the event listener is activated
				// from the watcher on "selected_value"
				// This is canceled if "selected_value" is set to null as well
				console.log(event)
				if(event.keyCode == 8 || event.keyCode == 46){
					this.handleRemove(this.selected_index)
					this.$refs.query_box.focus()
				}
			},
			getHeight(){
				this.top = (this.$el.offsetHeight + 10) + "px"
			},
			full_reset(focus=true){
				if(this.multi){ this.q = null } else { this.q = this.formatted_value.text; focus=false }
				this.query_results = []
				this.reset(focus)
			},
			reset(focus=true){
				this.focused = focus
				this.selected_index = null
				this.selected_value = null
				if(focus){this.$refs.query_box.focus()}
				setTimeout(()=>{
					this.getHeight()
				}, 500)
			},
			oneOf(object, array){
				return at(object, array).find((r)=>{
					return r != null
				})
			},
			validSelectionIndex(){
				if(this.selected_index == null){
					return false
				}
				if(this.show_query_results){
					return ((this.selected_index > -1) && (this.selected_index < this.query_results.length))
				} else if(this.show_initial_results){
					return ((this.selected_index > -1) && (this.selected_index < this.initial_results.length))
				} else {
					return false
				}
			},
			helloWorld(data, event){
				console.log(`Hello World! from event: ${event} with data:`)
				console.log(data)
			},
			event_callback(event, data=null){
				console.log(`TYPEAHEAD EVENT: ${event}`)
				if(!this.settings || !this.settings.event_callbacks || !this.settings.event_callbacks[event]) return
				this.settings.event_callbacks[event].forEach((f)=>{
					if(typeof(f)=="string"){
						try{
							this[f](data, event)
						} catch(e){
							console.error(e)
							console.warn(`Event: ${event}, Function: ${f}`)
						}
					} else if(typeof(f)=='function'){
						[f](data, event)
					} else {
						console.error(`Typeahead event callback for [${event}] not recognized: ${f}`)
					}
				})
			}
		},
		watch: {
			// This controls whether the results should be visible
			show_results(visible){
				if(visible){
					if(this.lazy && this.initial_results.length == 0){this.getResults(true)}
					if(this.constrained){this.selected_index = 0} else {this.selected_index = null}
					this.selected_value = null
					this.$nextTick(() => {
						this.$refs.query_box.focus()
					})
				}
			},
			selected_value(value){
				if(value != null){
					console.log("Setting delete listener")
					window.addEventListener("keydown", this.deleteListener)
				} else {
					window.removeEventListener("keydown", this.deleteListener)
				}
			}
		},
		computed: {
			search_valid(){
				return ((this.q!=null) && (this.q!=""))
			},
			has_value(){
				if(Array.isArray(this.internal_value)){
					return this.internal_value.length > 0
				} else {
					return (this.internal_value != null && this.internal_value != "")
				}
			},
			visible(){
				return (this.constrained ? this.focused : (this.focused && !this.show_empty_results))
			},
			show_initial_results(){
				return this.query_results.length <= 0 && !this.show_empty_results
			},
			show_query_results(){
				return (this.query_results.length > 0)
			},
			show_empty_results(){
				return this.q && this.q != "" && this.query_results.length == 0 && this.settings.configurations.showEmptyState && !this.first_get_after_input
			},
			formatted_initial_results(){
				return this.initial_results.map((r)=>{
					return {
						avatar: this.oneOf(r, this.fields.initial_results.avatar),
						text: this.oneOf(r, this.fields.initial_results.text) || r,
						icon: this.oneOf(r, this.fields.initial_results.icon),
						constrained: true,
						value: this.oneOf(r, this.fields.initial_results.value) || r
					}
				})
			},
			formatted_results(){
				return this.query_results.map((r)=>{
					return {
						avatar: this.oneOf(r, this.fields.query_results.avatar),
						text: this.oneOf(r, this.fields.query_results.text) || r,
						icon: this.oneOf(r, this.fields.query_results.icon),
						constrained: true,
						value: this.oneOf(r, this.fields.query_results.value) || r
					}
				})
			},
			formatted_value(){
				if(this.multi){
					if(this.constrainedKey || this.unconstrainedKey){
						return {
							[this.constrainedKey || 'constrained']: this.internal_value.filter((v)=>{return v.constrained}).map((v)=>{return v.value}),
							[this.unconstrainedKey || 'unconstrained']: this.internal_value.filter((v)=>{return !v.constrained}).map((v)=>{return v.value})
						}
					} else {
						return this.internal_value.map((v)=>{
							return v.value
						})
					}
				} else {
					if(this.constrainedKey && this.internal_value.constrained){
						return this.internal_value.value[this.constrainedKey]
					} else if(this.unconstrainedKey && !this.internal_value.constrained){
						return this.internal_value.value[this.unconstrainedKey]
					} else {
						return this.internal_value.value
					}
				}
			}
		},
		created(){
			// Setup the event and configuration settings for the component that
			// matches these props
			this.settings = Settings(this.$props)
			console.log(`TYPEAHEAD: ${this.name}: created with configuration: ${this.settings.name}`)
			this.initialValueParse()
		},
		mounted(){
			// Get the initial results set if it exists and the lazy prop isn't set
			if(!this.lazy && this.initialResults){
				this.getResults(true) // TRUE = initialResults
				this.event_callback("initialResultsParsed", this.initial_results)
			}
			if(!this.multi && this.allowDuplicates){
				console.warn("Typeahead component cannot support 'allow-duplicates' when not a multi-select.")
			}
			// Load all of the field targets to the arrays:
			if(this.avatarField){this.fields.query_results.avatar.unshift(this.avatarField)}
			if(this.textField){this.fields.query_results.text.unshift(this.textField)}
			if(this.iconField){this.fields.query_results.icon.unshift(this.iconField)}
			if(this.valueField){this.fields.query_results.value.unshift(this.valueField)}
			if(this.initialAvatarField){this.fields.initial_results.avatar.unshift(this.initialAvatarField)}
			if(this.initialTextField){this.fields.initial_results.text.unshift(this.initialTextField)}
			if(this.initialIconField){this.fields.initial_results.icon.unshift(this.initialIconField)}
			if(this.initialValueField){this.fields.initial_results.value.unshift(this.initialValueField)}
			
			this.is_mounted = true
			this.event_callback("mounted", this)
		},
		components: {Icon, IconButton, EmptyState, Pill, BSkeleton, Avatar, InvalidText},
		mixins: [Http, Validateable]
	}
</script>

<style lang="scss">
	@import "stylesheets/variables";
	@import "stylesheets/colors";
	@import "stylesheets/animations";
	.stay-typeahead {
		position: relative;
		display: flex;
  		width: auto;
  		flex: 1 1 auto;
  		//flex-wrap: nowrap;
  		min-height: calc(1.5em + 0.75rem + 2px);
  		height: auto !important;
  		max-width: 100%;

  		&.hidden {
  			border: none !important;
  			box-shadow: none !important;
  		}

  		&.multi {
  			max-height: 10rem;
  		}

  		.icon-button {
  			position: relative !important;
  		}

  		.typeahead-input {
  			display: flex;
  			flex-wrap: nowrap;
  			flex-grow: 1;
  			align-items: center;
  			min-height: calc(1.5em + 0.75rem + 2px);
  			max-height: calc(1.5em + 0.75rem + 2px);
  			max-width: 100%;
  			overflow-x: scroll;
  			overflow-y: hidden;


	  		&.multi {
	  			flex-wrap: wrap !important;
	  			max-height: 10rem !important;
	  			overflow-y: scroll;
	  		}

	  		.single-result{
	  			display: block;
	  			white-space: nowrap;
	  		}

  		}

		input {
			border: none;
			outline: none;
			flex: 1
		}

		button {
			background-color: Transparent;
			border: none;

			svg {
				width: 1rem;
				height: 1rem;
				color: $secondary-color;
			}
		}

		.results {
			display: block;
			visibility: hidden;
			position: absolute;
			left: 0;
			//top: calc(1.5em + 0.75rem + 2px); Now being calculated based on height of el
			width: 100%;
			background-color: white;
			box-shadow: $box-shadow;
			max-height: 20rem;
			overflow-y: scroll;
			z-index: $popover-index + 1;

			.title {
				color: $info-color;
				text-transform: uppercase;
				font-size: 0.5rem;
				font-weight: bold;
				padding: 1rem;
				margin-top: 1rem;
			}

			.result {
				width: 100%;
				height: 4rem;
				border-top: 0.75px solid $border-color;
				padding: 1rem;
				cursor: pointer;

				&:hover {
					background-color: $hover-color;
				}

				&.no-click {
					cursor: default;

					&:hover {
						background-color: Transparent;
					}
				}
			}

			.selected {
				background-color: $border-color;
			}
		}
	}
</style>