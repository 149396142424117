<template>
	<b-modal id="new-user-status" title="New Status" @ok.prevent="handleSubmit" :visible="visible">
		<b-container>
			<b-row class="mt-3">
				<b-col>
					Status labels can be applied to clients or prospects and include a status name, icon and additional custom workflow items.
				</b-col>
			</b-row>
			<b-row class="mt-3" v-if="user_status.name || user_status.icon">
				<b-col class="text-center">
					<user-status v-model="user_status" :variant="current_color"></user-status>
				</b-col>
			</b-row>
			<b-row class="mt-3">
				<b-col>
					<b-form @submit.prevent="handleSubmit">
						<b-form-group
							id="name"
							label="Name"
							invalid-feedback="Status name is required"
							:state="!$v.user_status.name.$error"
							>
							<b-form-input v-model="user_status.name"></b-form-input>
						</b-form-group>
						<b-form-group
							id="icon"
							label="Icon"
							invalid-feedback="Status icon is required"
							:state="!$v.user_status.icon.$error"
						>
							<icon-selector v-model="user_status.icon" height="10rem" expandable></icon-selector>
						</b-form-group>
						<b-form-group
							id="options"
							label="Color Options"
							invalid-feedback="A default color option is required"
							:state="!$v.user_status.options.$error"
						>
							<b-container>
								<b-row v-for="(color, index) in user_status.options.time_based_color" :key="index" class="mb-3">
									<b-col>
										<b-form-select v-model="color.time" :options="{value: 0, text: 'Default'}" v-if="index==0" disabled></b-form-select>
										<b-form-select v-model="color.time" :options="time_options" v-if="index > 0"></b-form-select>
									</b-col>
									<b-col>
										<color-picker v-model="color.color" @input="changeColor"></color-picker>
									</b-col>
								</b-row>
								<b-row v-if="user_status.options.time_based_color.length < 5">
									<b-col class="text-center">
										<b-button variant="outline-secondary" pill size="sm" @click="addTimeBasedColor">+ Color Rule</b-button>
									</b-col>
								</b-row>
							</b-container>
						</b-form-group>
						<b-form-group
							id="available_for"
							class="mt-5"
							invalid-feedback="Status must be available for at least one (prospect or client)"
							:state="($v.$dirty ? user_or_prospect : null)"
							>
							<b-form-checkbox v-model="user_status.available_for_prospects">Status available for prospects</b-form-checkbox>
							<b-form-checkbox v-model="user_status.available_for_users">Status available for clients</b-form-checkbox>
						</b-form-group>
					</b-form>
				</b-col>
			</b-row>
		</b-container>
	</b-modal>
</template>

<script>
	import { required, requiredUnless } from 'vuelidate/lib/validators'
	export default {
		data() {
			return {
				user_status: {
					name: null,
					icon: null,
					available_for_prospects: false,
					available_for_users: false,
					options: {
						time_based_color: [{
							time: 0,
							color: 'primary'
						}]
					}
				},
				time_options: [
					{value: 0, text: "Now"},
					{value: 1, text: "1 day ago"},
					{value: 2, text: "2 days ago"},
					{value: 3, text: "3 days ago"},
					{value: 4, text: "4 days ago"},
					{value: 5, text: "5 days ago"},
					{value: 6, text: "6 days ago"},
					{value: 7, text: "7 days ago"},
					{value: 8, text: "8 days ago"},
					{value: 9, text: "9 days ago"},
					{value: 10, text: "10 days ago"},
					{value: 11, text: "11 days ago"},
					{value: 12, text: "12 days ago"},
					{value: 13, text: "13 days ago"},
					{value: 14, text: "14 days ago"},
				],
				current_color: 'primary'
			}
		},
		props: {
			visible: Boolean
		},
		methods: {
			handleSubmit() {
				this.$v.$touch()
				if(this.$v.$invalid){
					console.log("User_status validation error, not saving")
				} else {
					console.log("Saving user_status")
					this.post("/settings/user_status", {
						user_status: this.user_status
					}).then(response => {
						console.log(response)
						pageData.user_statuses.push(response.data.data)
					}, err => {
						console.log(err)
					})
					this.$bvModal.hide('new-user-status')
				}
			},
			addTimeBasedColor() {
				this.user_status.options.time_based_color.push({time: 10, color: 'primary'})
			},
			changeColor(color) {
				this.current_color = color
			}
		},
		computed: {
			user_or_prospect() {
				return (this.user_status.available_for_users || this.user_status.available_for_prospects)
			}
		},
		validations: {
			user_status: {
				name: {required},
				icon: {required},
				options: {required},
				available_for_users: {
					required: requiredUnless('user_or_prospect')
				},
				available_for_prospects: {
					required: requiredUnless('user_or_prospect')
				}
			}
		}
	}
</script>