import Entity from 'mixins/entity'
import Blink from 'mixins/blink'
import get from 'lodash/get'
import has from 'lodash/has'

const moment = require('moment')

const StandardAvatar = function(object){
	return {
		image: object.avatar,
		text: object.initials
	}
}

const content_map = {

	Admin: {
		title: "fullname",
		subtitle: "email",
		avatar: StandardAvatar
	},

	Prospect: { 
		title: "fullname",
		subtitle: "email",
		avatar: StandardAvatar,
		details: object => {
			return [
				...(object.pets && object.pets.length > 0 ? [{icon: 'gitlab', icon_variant: 'info', text: object.pets.map(pet => pet.name).join(', ')}] : []),
				...(object.email ? [{icon: 'mail', text: object.email}] : []),
				...(object.user_phones && object.user_phones.length > 0 ? [{icon: 'phone', text: object.user_phones.map(p => p.friendly_name || p.phone_number).join(', ')}] : [])
			]
		}
	},

	Client: { 
		title: "fullname",
		subtitle: "city_state",
		avatar: StandardAvatar,
		details: object => {
			return object.pets && object.pets.length > 0 ? [{icon: 'gitlab', icon_variant: 'info', text: object.pets.map(pet => pet.name).join(', ')}] : []
		}
	},

	Trainer: {
		title: "fullname",
		subtitle: "title",
		avatar: StandardAvatar
	},


	Pet: {
		title: "fullname",
		avatar: StandardAvatar,
		subtitle: pet => { 
			if(pet.breeds && pet.breeds.length > 0){
				return pet.breed_input ? `${pet.breeds.map(b => b.name).join(', ')}, ${pet.breed_input}` : pet.breeds.map(b => b.name).join(', ')
			} else {
				return pet.breed_input
			}
		},
		details: pet => {
			if(pet.user){
				return [{icon: 'user', icon_variant: 'info', text: pet.user.fullname}]
			}
			if(pet.prospect){
				return [{icon: 'user', icon_variant: 'info', text: pet.prospect.fullname}]
			}
			return []
		}
	},

	Breed: {
		title: "name",
		subtitle: "keywords",
		description: "description",
		details: breed => {
			return [{icon: 'trending-up', icon_variant: 'info', text: `Life expectancy: ${breed.life_expectancy}`}]
		}
	},


	Document: {
		title: "name",
		subtitle: "filename",
		description: "description",
		viewed: "viewed",
		avatar: doc => { return {
			image: doc.avatar,
			noCircle: true,
			icon: !doc.avatar ? 'file' : null
		}},
		badges: doc => {
			return [
				...(doc.status == 'execution_requested' ? [{variant: 'warn', text: 'Signature Requested'}] : []),
				...(doc.status == 'requested' ? [{variant: 'info', text: 'Document Requested'}] : []),
				...(doc.status == 'request_expired' || doc.status == 'execution_expired' ? [{variant: 'secondary', text: 'Expired Request'}] : [])
			]
		},
		details: doc => {
			return [
				...(doc.jobs.find(d => d.worker == "DocumentClassificationWorker") ? [{loading: true}] : []),
				...(doc.document_type && doc.document_type.name ? [{icon: doc.document_type.icon, text: doc.document_type.name}] : []),
				...(doc.classification_failed ? [{icon: 'question-circle', text: 'Unknown Document', icon_variant: 'danger'}] : []),
				...(doc.signed_at ? [{icon: 'edit-3', text: 'Signed'}] : []),
				...(doc.status == 'needs_execution' ? [{icon: 'signature', icon_variant: 'danger', text: 'Needs Signatures'}] : []),
				...(doc.has_coordinates ? [{icon: 'pen', icon_variant: 'danger', text: 'Fillable Form'}] : [])
			]
		}
	},

	DocumentPage: {
		avatar: doc => { return {
			image: doc.avatar,
			noCircle: true
		}},
		title: page => { return `Page ${page.page_number + 1}`},
		description: "metadata",
		details: page => {
			return [
				...(page.jobs.find(p => p.worker == "OcrParseWorker") ? [{loading: true}] : []),
				...(page.has_elements ? [{icon: 'layers', text: 'Content Analyzed'}] : [])
			]
		}
	},

	Email: { 
		avatar: {icon: "mail"},
		title: "address", 
		badges: email => {
			return [
				...(email.default ? [{variant: 'primary', text: 'default'}] : [])
			]
		},
		details: email => {
			return [
				...(email.opt_outs && email.opt_outs.length > 0 ? [{icon: 'minus-circle', icon_variant: 'danger', text: `Opt-outs: ${email.opt_outs.join(', ')}`}] : [])
			]
		}
	},

	Event: {
		title: "name",
		avatar: {icon: 'zap', variant: 'light', text_variant: 'dark'},
		description: event => {
			return event.notes || event.start_time || ""
		}
	},

	Invite: {
		avatar: {icon: 'calendar'},
		title: "name",
		subtitle: object => {
			if(!object.start_time) return ''
			let start = new Date(object.start_time)
			return start.toString()
		},
		details: object => {
			return [
				...(object.rsvp_at ? [{icon: 'check-circle', text: 'RSVP\'d', icon_variant: 'success'}] : [])
			]
		}
	},

	Message: {
		title: "subject",
		description: "body",
		avatar: {icon: 'mail', variant: 'light', text_variant: 'dark'},
		details: message => {
			return [
				...(message.services.includes('Email') ? [{icon: 'envelope', text: 'Email'}] : []),
				...(message.services.includes('SMS') ? [{icon: 'sms', text: 'SMS'}] : []),
				...(message.attachments.length > 0 ? [{icon: 'paperclip', icon_variant: 'danger', text: 'Attachments'}] : [])
			]
		},
		actions: message => {
			return [
				...(message.status == "queued" ? [{name: 'resend', icon: 'send', text: 'Message didn\'t send'}] : [])
			]
		}
	},

	OrgEventMessage: {
		title: "subject",
		description: "body",
		avatar: {icon: 'mail', variant: 'light', text_variant: 'dark'},
		details: message => {
			return [
				...(message.services.includes('Email') ? [{icon: 'envelope', text: 'Email'}] : []),
				...(message.services.includes('SMS') ? [{icon: 'sms', text: 'SMS'}] : [])
			]
		}
	},

	OrgEvent: {
		title: "name",
		subtitle: "fulladdress",
		avatar: event => {
			let date = new Date(Date.parse(event.start_time))
			return {
				image: event.hero && event.hero.medium || null,
				variant: event.hero && event.hero.background_variant || "dark",
				text_variant: event.hero && event.hero.text_variant || "light",
				text: `${date.getMonth()+1}/${date.getDate()}`,
				brightness: event.hero && event.hero.brightness && parseInt(event.hero.brightness) || undefined
			}
		},
		details: event => {
			return [
				...[{icon: 'clock', text: event.timespan}],
				...[{icon: 'user-friends', text: `${event.total_rsvps} RSVP${event.total_rsvps != 1 ? 's' : ''} of ${event.max_attendees} available`}],
				...(event.price && event.price > 0 ? [{icon: 'money-bill', text: `$${parseFloat(event.price).toFixed(2)}`}] : [{icon: 'money-bill', text: 'FREE'}])
			]
		},
		badges: event => {
			return [
				...(event.open_enrollment ? [{text: 'Open Enrollment', variant: 'success'}] : [{text: 'Closed Enrollment', variant: 'warn'}]),
				...(event.virtual ? [{text: 'Virtual', variant: 'info'}] : []),
				...(event.waitlist ? [{text: 'Waitlist Enabled', variant: 'primary'}] : []),
				...(event.canceled ? [{text: 'Canceled', variant: 'danger'}] : [])
			]
		}
	},

	Phone: {
		avatar: {icon: "phone"},
		title: "friendly_name",
		badges: phone => {
			return [
				...(phone.disabled ? [{variant: 'danger', text: 'OPTED OUT'}] : []),
				...(phone.label ? [{variant: 'primary', text: phone.label.toUpperCase()}] : [])
			]
		},
		details: phone => {
			return [...(phone.default ? [{icon: 'star', icon_variant: 'info', text: 'DEFAULT'}] : [])]
		}
	},

	TempUserSession: {
		title: "id",
		details: session => {
			return [
				...(session.session_id ? [{icon: 'user-check', text: 'Session initiated', icon_variant: 'success'}] : [{icon: 'user-x', text: 'Link not clicked'}]),
				...(session.authenticated ? [{icon: 'lock', text: 'Session authenticated', icon_variant: 'success'}] : [{icon: 'unlock', text: 'Session not authenticated'}])
			]
		},
		badges: session => {
			return session.routes.map(route => {return {text: route.friendly_name || `${route.controller}: ${route.action}`, variant: ColorMap[route.controller], icon: route.authenticated ? 'lock' : null}})
		},
		actions: session => {
			return [
				{component: 'clipboard-button', text: session.url, tooltip: 'Copy link to clipboard'},
				{name: 'click', icon: 'external-link', text: 'Open session'}
			]
		},
		footer: session => {
			return `Expires ${moment(session.expires_at).fromNow()}`
		},
	},

	WorkflowAction: {
		title: "name",
		subtitle: "comments",
		avatar: wfa => { return {
			icon: wfa.avatar
		}},
		details: wfa => {
			return [
				...(wfa.expected_input_entity_classes ? [{icon: 'arrow-up', icon_variant: 'danger', text: wfa.expected_input_entity_classes.join(', ')}] : []),
				...(wfa.expected_output_entity_classes ? [{icon: 'arrow-down', text: wfa.expected_output_entity_classes.join(', ')}] : [])
			]
		}
	}
}

const ColorMap = {
	"clients/documents": "primary",
	"clients/photos": "warning",
	"clients/documents/signatures": "danger"
}

const details_map = {
	"pets.name": (object)=>{ return "Pets: " + object.pets.map(p => p.name).join(", ") },
	"user_phones.friendly_name": (object)=>{ return object.user_phones.map(p => p.friendly_name).join(", ") },
	breed: (object)=>{ return (object.breeds.length > 0 ? object.breeds.map(b=>b.name).join(", ") : object.breed_input)},
	"user.name": (object)=>{ return "Owner: " + object.user.fname + " " + object.user.lname },
	"address.city": (object)=>{ return (object.address ? object.address.city : "") },
	"pets.breed_input": (object)=>{ return "Pets: " + object.pets.map(p => `${p.name} - ${p.breed_input}`) },
	"invitee_type": (object)=> { return object.prospect_id ? "PROSPECT" : object.user_id ? "CLIENT" : "NEW REGISTRANT" },
	"rsvp_at": (object)=>{ return `RSVP: ${object.rsvp_at}`},
	"opted_out": (object)=>{ return object.disabled ? "OPTED OUT" : "" }
}

export default {

	props: {
		// The user-card is entity-agnostic and will accept one of the following v-models:
		// User, Prospect, Pet, Breed
		value: Object,
		// Style configurations:
		showType: Boolean,
		// Show the entity type label that shows on the top of the card
		noAvatar: Boolean,
		// Hide the avatar
		noAvatarBackground: Boolean,
		// Hide the avatar background
		noAvatarCircle: Boolean,
		// Make the avatar square
		allowDestroy: Boolean,
		// Provides a delete button and emits a destroy event
		destroyTooltip: String,
		// Adds a hover over destroy tooltip for the destroy button
		disabled: Boolean,
		// Controls the style only, does not impact clickability or redirects

		card: Boolean,
		// This will render the same border / shadow that is used for b-list-group
		// objects so that the card can stand alone on the page
		cardClass: String | Array,
		// CSS classes to add to the main card div
		//
		// Search results configurations:
		highlight: Object,
		// This accepts an object who's keys equal the fields where results where found
		// and the text renders using <em> tags around the actual search
		size: {
			type: String,
			default: "md"
		},
		// This controls the size props for the included components (e.g. avatar)
		noRedirect: Boolean,
		// Controls redirect to the entity on click
		newWindow: Boolean | String,
		// Opens the redirect in a new window. If boolean, opens using _blank. If string, opens in a new window with name set to that string.
		noClick: Boolean,
		// Makes it so that the element doesn't show a pointer
		hover: Boolean,
		// Animated hover effect
		noHover: Boolean,
		// Disables the background color changing on hover
		selected: Boolean,
		// Controls UI to differentiate the card as being selected
		flush: Boolean,
		// Removes padding from the card to be flush with the parent
		noCreatedAt: Boolean,
		// prevents the default behavior of showing a created_at ago in the bottom right corner of the card
		hideViewedIndicator: Boolean,
		// if the entity is configured to show a new entry, this will force it to be hidden
		loading: Boolean,
		// Uses a the bootstrap skeleton class to show the card in a content loading state
		subscribe: Boolean,
		// Subscribe to events being published via the channel that the page is currently subscribed to.
		// See mixins/events.js for more details on how this works

		// OVERRIDES - allows you to override elements of the UI
		title: String | Function,
		subtitle: String | Function,
		badges: Array | Function

	},

	created(){
		if(this.subscribe){
			// We want to subscribe to events updating this entity published in the page channel
			this.created_subscription = `${this.entity.plural}:${this.entity.id}:updated`
			this.destroyed_subscription = `${this.entity.plural}:${this.entity.id}:destroyed`
			this.$root.$on(this.created_subscription, (entity) => {
				this.$emit('input', {
					...this.entity_value,
					...entity
				})
				this.blink()
			})
			this.$root.$on(this.destroyed_subscription, entity => this.$emit('destroyed', entity))
		}
	},

	data(){
		return {
			badge_variants: {
				Prospect: 'info',
				Client: 'primary',
				Pet: 'warning',
				Breed: 'dark',
				Trainer: 'dark'
			},
			created_subscription: null,
			destroyed_subscription: null
		}
	},

	

	computed: {
		class_list(){
			let classList = `${this.$options._componentTag} ${this.size}${this.noClick ? " no-click" : ""}${this.card ? " list-group-item item" : ""}${this.hover ? " hover" : ""}${this.selected ? " selected" : ""}${this.flush ? ' flush' : ''}${this.disabled ? " disabled" : ''}`
			switch(typeof this.cardClass){
				case 'string':
					return `${classList} ${this.cardClass}`
					break
				case 'array':
					return `${classList} ${this.cardClass.join(' ')}`
					break
				default:
					return classList
					break
			}
		},
		card_data(){
			return {
				avatar: this.getKey('avatar'),
				title: this.getKey('title'),
				subtitle: this.getKey('subtitle'),
				actions: this.getKey('actions'),
				description: this.getKey('description'),
				details: this.getKey('details'),
				badges: this.getKey('badges'),
				footer: this.getKey('footer'),
				viewed: this.getKey('viewed'),
				author_name: this.getKey('author_name')
			}
		}
	},

	methods: {
		handleClick(){
			if(!this.noRedirect && this.entity.url){
				//location.href = this.entity.url
				window.open(this.entity.url, (this.newWindow ? (typeof(this.newWindow) == "string" ? this.newWindow : '_blank') : '_self'))
			} else {
				this.$emit('click', this.entity)
			}
		},

		// Handler for card_data.actions that don't provide an 'action' attribute
		// in the object

		handleAction(action_object){
			this.$emit(action_object.name || 'unnamed-action', this.entity)
		},

		getKey(key){
			if(!this.entity_type || !content_map[this.entity_type]){
				return null
			}
			if(this.$props[key]){
				// Handle the override here:
				return typeof(this.$props[key]) == "function" ? this.$props[key](this.entity) : (this.entity[this.$props[key]] || this.$props[key])
			}
			switch(typeof(content_map[this.entity_type][key])){
				case 'function':
					return content_map[this.entity_type][key](this.entity)
				case 'string':
					if(content_map[this.entity_type][key] == 'created_at' || content_map[this.entity_type][key] == 'updated_at'){
						return this.$stayfunctions.ago(this.entity[content_map[this.entity_type][key]])
					}
					return has(this.entity, content_map[this.entity_type][key]) ? get(this.entity, content_map[this.entity_type][key]) : content_map[this.entity_type][key]
				case 'object':
					if(Array.isArray() && (key == 'details' || key == 'badges')){
						return content_map[this.entity_type][key]
					} else if(key == 'avatar'){
						return content_map[this.entity_type][key]
					} else {
						throw(`Entity card is returning an object for key [${key}]`)
					}
				default:
					if(key == 'avatar'){
						return this.entity.avatar
					}
					if(key == 'footer' && !this.noCreatedAt && this.entity.created_at){
						return `Created ${this.$stayfunctions.ago(this.entity.created_at)}`
					}
					if(key == 'author_name' && this.entity.author_name){
						return this.entity.author_name
					}
					return undefined
			}
		}
	},
	mixins: [Entity, Blink]


}