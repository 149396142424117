<template>
	<b-container>
		<b-row>
			<b-col cols=12 lg=6 order=2 order-md=1>
				<stay-avatar :value="user" class="d-block float-left mr-3" />
				<span class="d-block w-100">{{user.fname}} {{user.lname}}</span>
				<small>{{(user.address ? user.address.city || "": "")}}</small>
			</b-col>
			<b-col cols=12 lg=6 order=1 order-md=2 class="text-right mb-3">
				<span class="d-block w-100"><b-badge variant="dark"><small>{{(user.type || user.user_data.type) | uppercase}}</small></b-badge></span>
				<xsmall class="d-block w-100">Added {{$stayfunctions.shortdate(user.created_at)}}</xsmall>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				<!-- Placeholder -->
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
	export default {
		props: {
			user: Object
		}
	}
</script>