<template>
	<b-container>
		<b-row>
			<b-col cols="auto" class="p-1" v-for="color in colors">
				<div class="color-swatch" :style="{backgroundColor: color.hex}" @click="handleSelect(color)" tabindex="0" @keydown.enter="handleSelect(color)">
					<div class="color-swatch-overlay" :style="{backgroundColor: overlayBackground}"></div>
					<div class="color-swatch-selected" v-if="_value && _value.hex == color.hex">
						<div class="color-swatch-icon">
							<icon value='check' size="xs" />
						</div>
					</div>
				</div>
				
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
	import Icon from 'common/icon'
	import Colors from 'mixins/colors'
	export default {
		components: {Icon},
		mixins: [Colors],
		data() {
			return {
				color: []
			}
		},
		props: {
			multi: Boolean,
			value: Object,
			name: String,
			hex: String,
			variant: String,
			brightness: String | Number,
			options: Array
		},
		methods: {
			handleSelect(color) {
				this.$emit('input', color)
				this.$emit('update:hex', color.hex)
				this.$emit('update:name', color.name)
				this.$emit('update:variant', color.variant)
			}
		},
		computed: {
			colors(){
				return this.options || Object.keys(this.$colors.all).map(key => { return {name: null, variant: key, hex: this.$colors[key]}})
			},
			_value(){
				this.value; this.hex; this.name; this.variant;
				if(this.value) return this.value
				let key = ['hex','variant','name'].find(key => this[key])
				if(!key) return null
				return this.colors.find(color => {
					return color[key] == this[key]
				})
			},
			_brightness(){
				return parseInt(this.brightness || 0)
			},
			overlayBackground(){
				if(this._brightness > 0){
					return `rgba(255,255,255,${this._brightness/100}`
				} else if(this._brightness < 0){
					return `rgba(0,0,0,${-this._brightness/100}`
				} else {
					return "Transparent"
				}
			}
		}
	}
</script>

<style lang="scss">

	.color-swatch {
		width: 2rem;
		height: 1.5rem;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.color-swatch-overlay {
		position: absolute;
		left:  0;
		top:  0;
		width: 100%;
		height:  100%;
		z-index: 2;
	}
	.color-swatch-selected {
		display: flex;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
		z-index: 3;

		.color-swatch-icon {
			display: flex;
			align-items: center;
			justify-content: center;
			background: white;
			width: 1em;
			height: 1em;
			border-radius: 100px;
		}
	}
</style>