<template>
	<b-container flush>
		<b-row>
			<b-col>
				<b-form-group>
					<b-form-checkbox v-model="checked" @input="updateInput">
						<small>
							By checking this box, you are signing this Agreement electronically. You agree your electronic signature is the legal equivalent of your manual signature on this Agreement. By selecting "I Accept" you consent to be legally bound by this Agreement's terms and conditions. You further agree that your use of a key pad, mouse or other device to select an item, button, icon or similar act/action, or to otherwise provide {{org}}. or in accessing or making any transaction regarding any agreement, acknowledgement, consent terms, disclosures or conditions constitutes your signature (hereafter referred to as "E-Signature"), acceptance and agreement as if actually signed by you in writing. You also agree that no certification authority or other third party verification is necessary to validate your E-Signature and that the lack of such certification or third party verification will not in any way affect the enforceability of your E-Signature or any resulting contract between you and {{org}}. You also represent that you are authorized to enter into this Agreement for all persons who own or are authorized to access any of your accounts and that such persons will be bound by the terms of this Agreement. You further agree that each use of your E-Signature in obtaining a {{org}} service constitutes your agreement to be bound by the terms and conditions of the {{org}}.
						</small>
					</b-form-checkbox>
				</b-form-group>
			</b-col>
		</b-row>
	</b-container>
</template>

<script>
	export default {
		data() {
			return {
				checked: false
			}
		},
		computed: {
			org() {
				return pageData.organization.name
			}
		},
		methods: {
			updateInput(evt){
				this.$emit('input', evt)
			}
		}
	}
</script>