// Root mixin for all Organizations-specific controller
// views and their corresponding pack files.

import Common from './index'
import Navigation from 'components/temp_users/nav'

export default {

	components: { Navigation },
	mixins: [Common],

	mounted(){
		console.log("P U B L I C")
	}


}