<template>
	<div class="image-cropper">
		<div class="cropper-canvas w-100" v-if="not_destroyed">
			<cropper-canvas :style="canvasStyle" ref="canvas">
				<b-skeleton width="100%" height="100%" v-if="loading" />
				<cropper-image :src="image.url" :alt="image.type" ref="image" @transform="handleImageTransform" @mouseup="handleImageTransformStop"></cropper-image>
				<cropper-shade v-if="ready" :x="selection_x" :y="selection_y" :width="selection_width" :height="selection_height" :style="selection_style"></cropper-shade>
				<cropper-selection v-if="ready" :x="selection_x" :y="selection_y" :width="selection_width" :height="selection_height" outlined id="selection" ref="selection">
					<div :class="`text-overlay display-3${image.type.text_variant ? ` text-${image.type.text_variant}` : ''}`" v-if="show_overlay" :style="overlay_style">{{text}}</div>
					<cropper-crosshair centered></cropper-crosshair>
				</cropper-selection>
				<cropper-handle action="move" plain ref="handle"></cropper-handle>
			</cropper-canvas>
		</div>

		<div v-if="$admin">
			<collapse title="Show raw position data">
				<pre>
					{
						selection_scale: {{selection_scale}},
						image_scale: {{image_scale}},
						scale: {{scale}},
						original_image_offset_x: {{original_image_offset_x}},
						original_image_offset_y: {{original_image_offset_y}},
						distance_from_center_x: {{distance_from_center_x}},
						distance_from_center_y: {{distance_from_center_y}},
						absolute_distance_from_center_x: {{absolute_distance_from_center_x}},
						absolute_distance_from_center_y: {{absolute_distance_from_center_y}},
						center_x: {{center_x}},
						center_y: {{center_y}},
						x: {{x}},
						y: {{y}}
					}
				</pre>
			</collapse>
		</div>

	</div>
</template>

<script>

	/* NOTES:
	/	To calculate the dimensions of an image crop accurately you need to take into consideration
	/	the following elements and their relative dimensions / scales:
	/
	/	1. CANVAS - The size of the usable space on the device which may be in fact smaller than the
	/	size of the crop you are trying to make.
	/
	/	2. IMAGE - The image can be scaled up and down to fill the dimensions of the crop as necessary.
	/
	/	3. SELECTION - The selection will also potentially be scaled to fit the canvas that we are working
	/	with.
	/
	/	TRANSFORMATION MATRIX: The values represent the following functions: matrix( scaleX(), skewY(), skewX(), scaleY(), translateX(), translateY() )
	/	https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/matrix
	/
	*/

	import "cropperjs"
	import { BSkeleton } from 'bootstrap-vue'
	import Collapse from 'common/collapse'
	import Pluralize from 'mixins/pluralize'

	export default {
		components: { BSkeleton, Collapse},
		mixins: [Pluralize],
		name: 'image-cropper',
		props: {
			value: Object,
			text: String
		},
		data(){
			return {
				is_mounted: false,
				loading: true,
				not_destroyed: true,
				zoom: 1,
				image_offset_x: 0,
				image_offset_y: 0,
				original_image_offset_x: null,
				original_image_offset_y: null,
				init_transform: [],
				current_transform: []
			}
		},
		computed: {

			ready(){
				return this.canvas_width && this.image.width ? true : false
			},

			// Image values that will likely be altered
			// when editing the image inn the component...

			image(){
				return this.value
			},

			starting_image_scale(){
				//if(!this.ready) return 1
				return this.canvas_width / this.image.width
			},

			image_center(){
				return [this.image.width/2, this.image.height/2]
			},

			image_scale(){
				return this.current_transform[0]
			},

			scale(){
				return this.image_scale / this.selection_scale
			},

			distance_from_center_x(){
				return -(this.current_transform[4] - this.init_transform[4])
			},

			distance_from_center_y(){
				return -(this.current_transform[5] - this.init_transform[5])
			},

			absolute_distance_from_center_x(){
				return this.distance_from_center_x / this.image_scale
			},

			absolute_distance_from_center_y(){
				return this.distance_from_center_y / this.image_scale
			},

			center_x(){
				return this.image_center[0] + this.absolute_distance_from_center_x
			},

			center_y(){
				return this.image_center[1] + this.absolute_distance_from_center_y
			},

			x(){
				return this.center_x - (this.selection_width / 2)
			},

			y(){
				return this.center_y - (this.selection_height / 2)
			},





			// Selection values
			// These should be provdied by the Image::Type configurations

			variants(){
				return this.image.variants.map(variant => {
					return {
						...variant,
						...{
							_zoom: variant.width / this.variant.width,
							_style: `width: ${variant.width}px; height: ${variant.height}px;`
						}
					}
				})
			},


			// We want to use the variant that has the maximum width as our
			// template for the crop...

			variant(){
				return this.image.variants.reduce((prev, current) => (prev.width > current.width) ? prev : current)
			},

			selected_variant_index(){
				return this.image.variants.findIndex(variant => variant.width == this.variant.width)
			},

			
			// SELECTION POSITIONING (note: the selection is always static right now)
			// Handles positioning of the crop selection box relative to the
			// canvas...

			selection_center_x(){
				return this.canvas_width / 2
			},

			selection_center_y(){
				return this.canvas_height / 2
			},

			selection_scale(){
				if(!this.ready) return 1
				return this.variant.width > this.canvas_width ? (this.canvas_width - 20) / this.variant.width : 1
			},

			selection_style(){
				return `${this.variant && this.variant.round ? 'border-radius: 50%' : ''}`
			},


			selection_width(){
				return this.variant.width * this.selection_scale
			},

			selection_height(){
				return this.variant.height * this.selection_scale
			},

			// absolute_selection_width(){
			// 	return this.variant.width
			// },

			// absolute_selection_height(){
			// 	return this.variant.height
			// },

			selection_x(){
				if(!this.ready) return 0
				return (this.canvas_width - this.selection_width) / 2
			},

			selection_y(){
				if(!this.ready) return 0
				return (this.canvas_height - this.selection_height) / 2
			},

			// x(){
			// 	return this.absolute_selection_x - this.absolute_image_x
			// },

			// y(){
			// 	return this.absolute_selection_y - this.absolute_image_y
			// },

			// center_x(){
			// 	return this.x + (this.absolute_selection_width / 2)
			// },

			// center_y(){
			// 	return this.y + (this.absolute_selection_height / 2)
			// },



			// Canvas dimensions and other viewer configurations
			// that alter the UI of the cropper...

			canvasStyle(){
				return {
					minHeight: `${this.canvas_height}px`
				}
			},

			canvas_width(){
				if(!this.is_mounted) return null
				return this.$refs.canvas && this.$refs.canvas.clientWidth
			},

			canvas_height(){
				if(!this.is_mounted) return null
				return this.image.height * this.starting_image_scale
			},


			// OVERLAY handlers. Sometimes we want to show an overlay with
			// some text in it to replicate what a HERO will look like with content:

			show_overlay(){
				return this.text || this.image.type.brightness != undefined
			},

			overlay_style(){
				return this.image.type.brightness > 0 ? `background-color: rgba(255,255,255,${this.image.type.brightness/100});` : (this.image.type.brightness < 0 ? `background-color: rgba(0,0,0,${-this.image.type.brightness/100});` : '')
			},



		},

		methods: {

			handleImageTransform(evt){
				// Expects a CSS transform matrix:
				// matrix( scaleX(), skewY(), skewX(), scaleY(), translateX(), translateY() )
				// When we move and scale the image, we are moving the top left point of the
				// selection around on the image. Here we need to calculate that so we can figure out
				// what x,y to send
				//
				// Cropper seems to use some strange transformX and transformY matrix values onn initialization
				// so we store those first and then start monitoring for changes so that we can calc the
				// appropriate x, y values for the server...
				this.current_transform = evt.detail.matrix

				if(this.original_image_offset_x == null || this.original_image_offset_y == null){
					console.log("Setting initial Transformation...")
					console.log(evt.detail.matrix)
					this.init_transform = evt.detail.matrix
					this.original_image_offset_x = evt.detail.matrix[4]
					this.original_image_offset_y = evt.detail.matrix[5]
				} else {
					this.image_offset_x = evt.detail.matrix[4] - this.original_image_offset_x
					this.image_offset_y = evt.detail.matrix[5] - this.original_image_offset_y
					this.zoom = evt.detail.matrix[0]
					//console.log(this.x, this.y, this.zoom)
				}
			},



			async handleImageTransformStop(evt){
				try {
					console.log(evt)

					// When a transform stops, we want to emit the values of the transform here.
					// The relevant attributes for a transform would be:
					//
					let keys = ['center_x', 'center_y', 'x', 'y', 'scale']
					//
					// Here we want to transverse the image object and the selected variant that we are editing...

					let val = JSON.parse(JSON.stringify(this.image))
					keys.forEach(key => {
						if(Object.keys(val.type).includes(key)) val.type[key] = this[key]
						if(Object.keys(val.variants[this.selected_variant_index]).includes(key)) val.variants[this.selected_variant_index][key] = this[key]
					})
					val['canvas'] = await this.$refs.selection.$toCanvas()
					this.$emit('input', val)
					return
				} catch(err){
					throw(err)
				}
			},



			handleImageLoaded(evt){
				console.log("Image loaded")
				//this.transformImageWhenReady()
				this.$refs.canvas.addEventListener('actionend', this.handleImageTransformStop)
				this.loading = false
			},


			transformImageWhenReady(){
				if(this.image.type.scale){
					this.$refs.image.$zoom(this.image.type.scale)
				}

				if(this.image.type.center_x && this.image.type.center_y){
					this.$refs.image.$move(
						-(this.image.type.center_x - this.image_center[0]),
						-(this.image.type.center_y - this.image_center[1])
					)
				}

			}


		},

		mounted(){
			this.$nextTick(()=>{
				this.$refs.image.$ready(this.handleImageLoaded)
				this.is_mounted = true
			})
		},

		beforeDestroy(){
			this.$refs.canvas.removeEventListener('actionend', this.handleImageTransformStop)
			this.not_destroyed = false
		}
	}
</script>

<style lang="scss">

	.image-cropper {

		position: relative;

		.text-overlay {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			display: flex;
			align-items: center;
		}

		.b-skeleton {
			position: absolute;
			z-index: 100;
		}

	}




</style>
