<template>
	<div class="floating-button">
		<button @click="$emit('click')" :style="{ backgroundColor: $staycolors[variant] }" v-b-tooltip.hover :title="tooltip" :class="{'color-pulse-success': pulse}">
			<icon :value="icon" variant="white" />
		</button>
		<div class="blurred-content" :style="{ backgroundColor: $staycolors[variant] }" v-visible.swing-in-right-fwd.swing-out-right-bck="show_blurred">{{blurred}}</div>
	</div>
</template>

<script>
	import Icon from 'common/icon'
	import { VBTooltip } from 'bootstrap-vue'
	export default {
		props: {
			variant: {
				type: String,
				default: 'primary'
			},
			icon: {
				type: String,
				default: 'plus'
			},
			tooltip: String,
			pulse: Boolean,

			// This is content that shows when the button is not active or focused
			// This can also be set using a slot. The timings control when the content
			// shows and disappears
			blurred_content: String | Function | Promise,
			blurred_delay: {
				type: Number,
				default: 3000
			},
			blurred_timeout: Number,

			// This is content that shows when the button us active and focused
			// Can also be set using a slot
			focused_content: String | Function | Promise,
		},
		data() {
			return {
				blurred: null,
				show_blurred: false
			}
		},
		async mounted(){
			if(this.blurred_content){
				try {
					// First get the content
					if(typeof(this.blurred_content)=='promise'){
						this.blurred = await this.blurred_content
					} else {
						this.blurred = (typeof(this.blurred_content)=='function' ? this.blurred_content() : this.blurred_content)
					}
					// Now we can set the visibility timings
					if(this.blurred_delay == 0){
						this.show_blurred = true
					} else {
						setTimeout(()=>{
							this.$set(this.$data, 'show_blurred', true)
							if(this.blurred_timeout){
								setTimeout(()=>{
									this.show_blurred = false
								}, this.blurred_timeout)
							}
						}, this.blurred_delay)
					}
				} catch(e) {
					console.warn(e)
				}
			}
		},
		components: {Icon},
	}
</script>

<style lang="scss">
	@import 'stylesheets/variables';
	@import 'stylesheets/colors';

	.floating-button {
		position: fixed;
		display: block;
		z-index: 2;
		bottom: 3em;
		right: 3em;
		height: 4.5em;
		min-width: 4.5em;

		button {
			@include hover;
			height: 4.5em;
			width: 4.5em;
			color: white;
			box-shadow: $box-shadow;
			border-radius: 200px;
			//line-height: 4.5em;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			border: 1px solid $light-color;
		}


		.blurred-content {
			position: absolute;
		    display: flex;
		    top: 0;
		    left: -15.5rem;
		    width: 20rem;
		    height: 100%;
		    padding: 0.5rem 5rem 0.5rem 0.5rem;
		    border-radius: 2rem 7.25rem 7.25rem 2rem;
		    z-index: -1;
		    font-size: 0.75rem;
		    align-items: center;
		    color: white;
		}
	}
</style>