<template>
	<div>
		<nav class="stay-nav d-flex d-flex-row flex-nowrap align-items-center" :style="{
			backgroundColor: backgroundColor,
			color: foregroundColor
		}">
			<div class="order-1 d-flex d-flex-row flex-nowrap align-items-center">
				<a :href="$root.pageData.organization.website || '#'">
					<org-logo height="50" class="pl-3" fade></org-logo>
				</a>
			</div>

			<div class="order-2 d-flex d-flex-row flex-nowrap align-items-center ml-auto">
				<ul class="d-none d-lg-block" :style="{color: foregroundColor}">
					<li v-if="false"><a href="/account">My Account</a></li>
					<li><b-button variant="link" @click="$refs.contact_nav.visible = !$refs.contact_nav.visible">Contact Us</b-button></li>
				</ul>
			</div>
		</nav>

		<contact-nav ref="contact_nav" />


	</div>
</template>


<script>
	import Colors from 'mixins/colors'
	import OrgLogo from 'common/org_logo'
	import ContactNav from './contact_nav'

	export default {
		components: { OrgLogo, ContactNav },
		mixins: [Colors],
		data: function() {
			return {
				current_user: pageData.current_user,
				organization: pageData.organization
			}
		},
		computed: {
			backgroundColor(){
				return this.$colors.light
			},
			foregroundColor(){
				return this.$colors.dark
			}
		}
	}
</script>

<style lang="scss">
	@import "../common/shared.scss";
	.stay-nav{
		height: 5rem;
		padding-left: 1rem;
		padding-right: 1rem;
		//box-shadow: 0 0 3px 0 #3B3B3C;
		margin-bottom: 10px;
		//-webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
		//-moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
		//box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);

		button {
			border: none;
			outline: none;
			background-color: Transparent;
			cursor: pointer;
		}

		ul {
			list-style-type: none;
			margin-bottom: 0;

			li {
				display: inline;
				font-family: Raleway;
				font-weight: 200;
				font-size: 14px;
				color: inherit;
				text-align: left;
				text-transform: uppercase;
				padding: 0.25rem;
			}
		}

		a {
			color: inherit;

			&:hover {
				text-decoration: none;
			}
		}
	}
</style>