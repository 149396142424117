<template>
	<div>
		<stay-phone-call :user_phone="user_phone"></stay-phone-call>
		<b-table :items="items" :fields="fields" responsive hover class="prospect-table" selectable selected-variant="secondary" @row-selected="handleRowSelect">
			<template v-slot:cell(created)="data">
				<dot v-if="!data.item.viewed" variant="info"></dot>
				<small>{{$stayfunctions.ago_or_date_after(5, data.item.created_at)}}</small>
			</template>
			<template v-slot:cell(fullname)="data">
	        	<prospect-info :prospect="data.item"></prospect-info>
	      	</template>
	      	<template v-slot:cell(user_phones)="data">
	      		<span v-if="data.item.user_phones.length > 0 && data.item.user_phones[0].verified == false" class="phone-alert" v-html="$stayicon.svg('alert-circle')" v-b-tooltip title="We were not able to verify this phone number"></span>
	      		{{data.item.user_phones.length > 0 ? data.item.user_phones[0].friendly_name : ''}}
	      		<span v-if="data.item.user_phones.length > 0" v-html="$stayicon.svg('phone-outgoing')" @click="call(data.item.user_phones[0])"></span>
	      	</template>
	      	<template v-slot:cell(email)="data">
	      		<a :href="'mailto:' + data.item.email">{{data.item.email}}</a>
	      	</template>
	      	<template v-slot:cell(animals)="data">
	      		<prospect-pets :value="data.item.pets"></prospect-pets>
	      	</template>
	      	<template v-slot:cell(status)="data">
	      		<user-status editable :user="data.item"></user-status>
	      	</template>
		</b-table>
	</div>
</template>

<script>
	import StayPhoneCall from '../organizations/stay_phone_call'
	import ProspectInfo from './table/prospect_info'
	import ProspectPets from './table/prospect_pets'

	export default {
		data() {
			return {
				user_phone: {},
				fields: [
					'created',
					{
						key: 'fullname',
						label: 'Owner'
					},
					{
						key: 'animals',
						label: 'Pet'
					},
					{
						key: 'address.city',
						label: 'City'
					},
					{
						key: 'status',
						label: 'Status'
					}
				]

			}
		},
		props: {
			items: Object | Array
		},
		methods: {
			call(user_phone) {
				console.log(user_phone)
				this.user_phone = user_phone
				this.$bvModal.show('stay-phone-call')
			},
			handleRowSelect(evt) {
				this.$stayloader.enable()
				//console.log(evt)
				location.href = '/users/' + evt[0].id
			}
		},
		components: {
			'stay-phone-call': StayPhoneCall,
			'prospect-info': ProspectInfo,
			'prospect-pets': ProspectPets
		}
	}
</script>

<style lang="scss">
	@import '../common/shared';

	.prospect-table .phone-alert svg {
		color: $danger-color !important;
	}
</style>
