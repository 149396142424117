<template>
	<div class="html-input">
		<div id="editor">
			
		</div>
	</div>
</template>

<script>
	import Quill from 'vendor/quill/quill.js'
	import {Validateable} from 'mixins/validation'

	const toolbarOptions = [
		[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
		['bold', 'italic', 'underline', 'strike'],
		[{ 'list': 'ordered'}, { 'list': 'bullet' }],
		['link','clean'] 
	]

	export default {
		name: 'html-input',
		props: {
			value: String
		},
		data(){
			return {
				editor: null
			}
		},
		methods: {
			mount_editor(){
				this.editor = new Quill('#editor', {
					modules: { toolbar: toolbarOptions },
					theme: 'snow',
					placeholder: 'Your message goes here...',
					debug: true
				})
				if(this.value) this.editor.root.innerHTML = this.value
				this.editor.on('text-change', (delta, oldDelta, source) => this.$emit('input', this.editor.root.innerHTML))
				this.editor.root.addEventListener('blur', ()=>this.$emit('blur'))
			},
			focus(){
				this.editor.focus()
			}
		},
		mounted(){
			this.mount_editor()
		},
		watch: {
			value(val){
				if(this.editor && !this.editor.hasFocus()){
					this.editor.root.innerHTML = val
				} 
			}
		},
		mixins: [Validateable]
	}
</script>

<style lang="scss">
	@import '../../../vendor/quill/quill.snow.css';

</style>