<template>
	<b-modal id="user-search" title="User Search" :visible="visible" size="lg" @ok.prevent="handleSave" @ok-text="buttonText">
		<search-filters ref="search_filters" @response="results = $event" @loading="loading = $event" v-bind="$props"></search-filters>
		<search-table v-model="results" ref="search_table" :force-loading="loading" @selected="handleSelect" v-bind="$props"></search-table>
	</b-modal>
</template>

<script>
	import SearchFilters from './search_filters'
	import SearchTable from './search_table'
	import pickBy from 'lodash/pickBy'
	export default {
		name: 'user-search',
		props: {
			// Only allow for certain indices: User, Prospect, Pet, Breed
			only: String | Array,

			// Configurations for response payloads
			// By default the component returns search results if nothing selected
			//
			multiSelect: Boolean,
			redirectOnClick: Boolean,

			// These control what is returned in the 'input' emit:
			// By default the component returns an object with each of these
			// elements as a key.
			// If a single key is requested, it is returned without a key
			return: {
				type: Array | String,
				default: function(){return ["query","indices","results","selected"]}
			},

			// The results themselves can be returned wrapped in the eleastic result
			// wrapper or hidden (just the result) using this config:
			hideSearchWrapper: Boolean
		},
		data(){
			return {
				visible: true,
				search: {
					q: null,
					query: null,
					filter: null,
					layout: false
				},
				results: null,
				html: false,
				loading: false,
				selected: []
			}
		},
		methods: {
			handleSelect(items){
				if(this.multiSelect){
					this.selected = items
				} else {
					this.selected = items
					this.$emit('input', this.parsed_response)
					this.visible = false
				}
			},
			handleSave(){
				this.$emit('input', this.parsed_response)
				this.visible = false
			}
		},
		computed: {
			buttonText(){
				if(this.$refs.search_table){
					return this.$refs.search_table.buttonText || "Add"
				} else {
					return "Ok"
				}
			},
			raw_response(){
				// The value to return if all keys are selected for response
				return {
					query: this.$refs.search_filters.query,
					indices: this.$refs.search_filters.selected.indices,
					results: this.results,
					selected: (this.hideSearchWrapper ? (Array.isArray(this.selected) ? this.selected.map((r)=>{ return r._source }) : this.selected._source) : this.selected)
				}
			},
			parsed_response(){
				if(typeof(this.return)=='string' || this.return.length == 1){
					return (Array.isArray(this.return) ? this.raw_response[this.return[0]] : this.raw_response[this.return])
				} else {
					return pickBy(this.raw_response, (v,k)=>{
						return this.return.includes(k)
					})
				}
			}
		},
		components: {SearchFilters, SearchTable}
	}
</script>