<template>
	<div class="org-logo">
		<transition name='fade'>
			<span v-if='!logo_path'>{{name}}</span>
			<img v-if='logo_path' :src='logo_path' v-show='showImg' v-on:load='onLoadedImg' height='50px'>
		</transition>
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				logo_path: pageData.organization.configurations.logo_path || null,
				name: pageData.organization.name,
				showImg: !this.fade
			}
		},
		props: {
			height: String, 
			width: String,
			fade: Boolean
		},
		template: "",
		methods: {
			getSrcPath() {
				if(this.logo_path) {
					var transformations = ((this.height) ? 'h_' + this.height : "")
					if (transformations && this.width) {
						transformations += ',' 
					}
					transformations += ((this.width) ? 'w_' + this.width + '/' : "")
					return "https://res.cloudinary.com/stay-app/" + ((transformations) ? transformations + '/' : '') + this.logo_path
				} else {
					return "https://via.placeholder.com/50"
				}
			},
			onLoadedImg() {
				this.showImg = true
			},
			show() {
				return !this.fade
			}
		}
	}
</script>

<style lang="scss">
	.org-logo {
		img {
			height: 50px;
		}
	}
</style>