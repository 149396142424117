<template>
	<b-container>

		<b-row v-if="loading">
			<b-col>
				<stayloader-avatar-block :rows="2" />
			</b-col>
		</b-row>

		<b-row v-if="!loading && $stayfunctions.isEmpty(signature_data.signature) && $stayfunctions.isEmpty(signature_data.initials)">
			<b-col>
				<empty-state icon="edit-3" text="Add your signature to enable electronic execution of documents" />
			</b-col>
		</b-row>

		<b-row v-if="!loading && !$stayfunctions.isEmpty(signature_data)">
			<transition name="slide-fade">
				<b-row v-if="!$stayfunctions.isEmpty(signature_data.signature)">
					<b-col cols="4">
						<img :src="signature_data.signature.url" width="100%" />
					</b-col>
					<b-col>
						<span class="w-100 d-block">Signature</span>
						<small>{{$stayfunctions.ago(signature_data.signature.created_at)}}</small>
					</b-col>
					<b-col class="text-right">
						<b-button variant="outline-danger" size="sm" @click="destroy(signature_data.signature)">Delete</b-button>
					</b-col>
				</b-row>
			</transition>

			<transition name="slide-fade">
				<b-row v-if="!$stayfunctions.isEmpty(signature_data.initials)">
					<b-col cols="4">
						<img :src="signature_data.initials.url" width="100%" />
					</b-col>
					<b-col>
						<span class="w-100 d-block">Initials</span>
						<small>{{$stayfunctions.ago(signature_data.initials.created_at)}}</small>
					</b-col>
					<b-col class="text-right">
						<b-button variant="outline-danger" size="sm" @click="destroy(signature_data.initials)">Delete</b-button>
					</b-col>
				</b-row>
			</transition>
		</b-row>


	</b-container>
</template>

<script>
	export default {
		data() {
			return {
				loading: true,
				signature_data: {}
			}
		},
		props: {
			user: Object
		},
		methods: {
			reload(){
				this.loading = true
				this.get(this.user.user_data.url + '/signature', {alert: false, loader: false}).then(response=>{
					this.signature_data = response.data.data
					this.loading = false
				}, err=>{
					console.log(err)
					this.loading = false
				})
			},
			destroy(signature){
				this.$bvModal.msgBoxConfirm(`Are you sure you want to delete your ${signature.type}?`).then(response => {
					if(response){
						this.$stayhttp.delete(this.user.user_data.url + "/signature", signature).then(response => {
							console.log(response)
							setTimeout(() => {this.$set(this.signature_data, signature.type, {})}, 2000)
						})
					}
				})
			}
		},
		mounted() {
			this.reload()
		}
	}
</script>