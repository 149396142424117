<template>
	<avatar v-bind="card_data.avatar" :size="size" v-on="$listeners" />
</template>

<script>
	import EntityUI from 'mixins/entity_ui'
	import Avatar from 'common/avatar'
	export default {
		name: 'entity-avatar',
		props: {
			size: {
				type: String,
				default: 'md'
			}
		},
		mixins: [EntityUI],
		components: {Avatar}
	}
</script>