<template>
	<div class="stay-collapse">
		<icon-button :title="title" :icon="_visible ? 'arrow-down' : 'arrow-right'" @click="_visible = !_visible" v-if="!hideButton" />
		<b-collapse v-model="_visible">
			<slot></slot>
		</b-collapse>
	</div>
</template>

<script>
	import IconButton from 'common/icon_button'
	import {BCollapse} from 'bootstrap-vue'

	export default {
		components: {IconButton, BCollapse},
		name: 'collapse',
		props: {
			title: String,
			value: Boolean,
			hideButton: Boolean
		},
		data(){
			return {
				internal_value: false
			}
		},
		methods: {

			collapse(){
				this.$emit('input', false)
				this.internal_value = false
			},

			expand(){
				this.$emit('input', true)
				this.internal_value = true
			}

		},
		computed: {
			_visible: {
				get(){
					return this.value == true || this.internal_value == true
				},
				set(val){
					this.internal_value = val
					this.$emit('input', val)
				}
			}
		}
	}

</script>