<template>
	<div :class="{
		upload: true,
		slot: is_slot,
		dragover: dragover,
		border: border,
		block: block,
		disabled: disabled
	}">
		<slot name="loading" v-if="loading" :progress="percentage_complete"></slot>
		<slot v-show="!loading"></slot>

		<!-- EMPTY STATE CONTENT - Shown when nothing is happening or is replaced by the default slot -->
		<div class="content" v-if="!is_slot">
			<icon :value="icon" variant="danger" size="xl" />
			{{is_mobile ? 'Take a picture or upload a file below' : 'Drag your file here or browse below'}}
			<label class="btn btn-primary">
				{{is_mobile ? 'Upload' : 'Browse Computer'}}
				<input type="file" name="file" @change="handleSelect" :accept="_accepts.join(',')" ref="input" hidden />
			</label>
		</div>
		<input v-if="is_slot" type="file" name="file" @change="handleSelect" :accept="_accepts.join(',')" ref="input" />


		<!-- DRAG AND DRAGOVER CONTENT - changes state based on dragging outside the component on the page or over the component -->
		<div :class="{drag: true, dragover: dragover}" v-if="drag && !errors" @dragover.prevent="handleDragover">
			<div class="drag-content">
				<icon value="file-plus" :variant="dragover ? 'light' : 'dark'" size="xl" :class="`dropzone-${_uid}`" />
				{{dragover ? 'Yes, right here!' : 'Drop your file here!'}}
			</div>
		</div>


		<!-- ERROR CONTENT - shows if there is an error state with the dropped file or selected file -->
		<transition name="fade">
			<div class="upload-error" @dragover.prevent="handleDragover" v-if="errors">
				<icon-button icon="x" class="dismiss" @click="reset" variant="light" />
				<error-icon size="xl" />
				{{errors}}
			</div>
		</transition>


		<!-- LOADING CONTENT - Shown when actively uploading and no loading slot is provided -->
		<div class="upload-loading" v-if="loading && !is_loading_slot">
			<div class="upload-loader" v-loading.center.no-overlay="true"></div>
			Uploading {{percentage_complete}}%
		</div>

		<!-- SUCCESS CONTENT - Shown when an upload is successful unless there is a default slot in which case we assume the success is represented there -->
		<div class="upload-success" v-if="response && !errors && !is_slot">
			<success-icon size="xl" />
			Upload Successful
		</div>


		<!-- CONTENT OVERLAY - Ensures the static content isn't visible beneath loading, error and drop screens (not used with a default slot) -->
		<div class="content-overlay" v-if="(drag || errors || loading || response) && !is_slot"></div>

		<!-- DROPZONE - A hidden div to make sure we can keep the focus as the mouse is moved around mid-drag -->
		<div :class="`dropzone ${_uid}`" v-if="dragover" @dragover.prevent @dragleave.prevent="handleDragleave" @drop.prevent="handleDrop"></div>

		<!-- PAGE LEAVE WATCHER - another hidden div that covers the page so that we know if the file is dragged off of the page -->
		<div class="document-overlay" v-if="drag" @dragleave.prevent="event => $root.$emit('document:dragleave', event.target)"></div>



	</div>
</template>

<script>
	import axios from 'axios'
	import Icon from 'common/icon'
	import IconButton from 'common/icon_button'
	import SuccessIcon from 'common/success_icon'
	import ErrorIcon from 'common/error_icon'
	export default {

		name: 'upload',

		props: {
			accepts: {
				type: Array | String,
				default: "image/png, image/jpeg"
			},
			url: {
				type: String,
				required: true
			},
			icon: {
				type: String,
				default: 'upload'
			},
			options: Object,			// Additional optional parameters to be passed in the POST
			sizeLimit: {
				type: Number,
				default: 20000000 		// 20MB default size limit
			},
			autoReset: Boolean | Number,// If number, reset number miliseconds after successful upload, otherwise use the default 3 seconds
			border: Boolean,
			block: Boolean, 			// Make sure there is a minimum height so it is taller
			noEvent: Boolean, 			// Don't publish an entity:created event on the response
			disabled: Boolean
		},

		data(){
			return {

				drag: false, 			// Whether something is currently being dragged on the page
				dragover: false,		// Whether something is being dragged over this component on the page
				loading: false,			// Loading
				dataTransfer: null,		// Data available in the dataTransfer element as soon as a drag is started
				file: null,				// The file that is selected or dropped
				percentage_complete: 0,	// Updated as the file is sent
				config: {
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					onUploadProgress: this.handleUploadProgress
				},
				uncaught_error: null, 	// Renders errors that are returned from the server on upload that we didn't catch in the client
				response: null,
				is_mounted: false
			}
		},

		methods: {

			// Way to trigger the file browser (or camera roll on mobile) to
			// open up programmatically

			browse(){
				this.$refs.input.click();
			},

			reset(){
				this.drag = this.dragover = false
				this.dataTransfer = this.file = this.uncaught_error = null
			},

			// Handler for the file being dragged over top of the
			// upload component

			handleDragover(event){
				if(this.dragover || this.disabled) return
				// First confirm that there is a file attached to this drag. If no file is found,
				// we will ignore this dragover.
				let dt = event.dataTransfer
				if(dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.contains('Files'))){
					this.dragover = true
					this.file
				}
			},

			// Handler for the file being dragged off of the upload component

			handleDragleave(event){
				this.dragover = false
			},

			// Initial handler for the drag event when something is first dragged on
			// to the browser doeument.

			handleDocumentDragover(event){
				if(this.drag || this.disabled) return
				this.dataTransfer = event.dataTransfer
				if(this.dataTransfer.types && (this.dataTransfer.types.indexOf ? this.dataTransfer.types.indexOf('Files') != -1 : this.dataTransfer.types.contains('Files'))){
					this.drag = true
					this.dataTransfer = event.dataTransfer
					// If we could actually get the file info on drag we should do something with it
					// here. According to this we can't: https://stackoverflow.com/questions/34771177/detect-file-type-on-dragenter-cross-browser-solution
				}
			},

			// Handler for the file being dragged off of the browser.
			// We use a full document overlay to watch for this event
			// and then $emit it on root for all upload components on the page
			// to pick up and handle.

			handleDocumentDragleave(event){
				this.reset()
			},

			// Handles something being dropped on the dropzone for this upload
			// component. We $emit this on $root to inform the other upload components
			// on the page so they can reset.

			handleDrop(event){
				if(this.disabled) return
				event.preventDefault()
				this.$root.$emit('upload:drop', event)
				let files = event.dataTransfer.files
	        	this.createFile(files[0])

			},

			handleSelect(event){
				this.$emit('upload:selected', event)
				let files = event.target.files
				this.createFile(files[0])
			},

			createFile(file){
				this.file = file
				if(!this.valid) return
				let formData = new FormData()
  				for(const key in this.upload){
  					formData.append(key, this.upload[key])
  				}
  				if(this.options){
	  				for(const key in this.options){
	  					formData.append(key, this.options[key])
	  				}
	  			}
  				formData.append('authenticity_token', this.$csrf)
  				this.$emit('promise', this.sendFile(formData))
			},

			// NOTE: We are using Axios directly here rather than going through http.js as we want to take
			// advantage of the onUploadProgress feature. Unfortunately this means we don't get some of the
			// benefits of using http.js including alerts, loaders and events. We need to handle those
			// ourselves here...

			async sendFile(formData){
				console.log(formData)
				try {
					this.loading = true
					let response = await axios.post(this.url, formData, this.config)
					this.loading = false
					this.handleResponse(response)
				} catch(err){
					this.loading = false
					this.uncaught_error = err
					throw(err)
				}
			},

			handleUploadProgress(progressEvent){
				console.log(progressEvent)
				this.percentage_complete = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
			},

			handleResponse(axios_response){
				console.log(axios_response)
				this.$emit('input', axios_response.data)
				if(this.$root.$handleHttpResponse && !this.noEvent){
					this.$root.$handleHttpResponse(axios_response)
				}
				this.response = axios_response
				if(this.autoReset) setTimeout(this.reset, typeof(this.autoReset) == 'number' ? this.autoReset : 3000)
			}
		},

		computed: {
			is_slot(){
				return !!this.$slots.default
			},

			is_loading_slot(){
				return !!this.$slots.loading
			},

			_accepts(){
				return Array.isArray(this.accepts) ? this.accepts : this.accepts.split(",").map(acc => acc.trim())
			},

			valid_size(){
				return this.file ? this.file.size <= this.sizeLimit : null
			},

			valid_type(){
				return this.file ? this.accepts.includes(this.file.type) : null
			},

			valid(){
				return this.valid_size && this.valid_type
			},

			errors(){
				return this.uncaught_error ? this.uncaught_error.message :
					 (this.file ? 
						(this.valid_type == false ? 'This filetype is not supported' : 
							(this.valid_size == false ?  `Files over ${this.sizeLimit / 1000000} MB are not supported.` : null)
							) : null)
			},

			upload(){
				return this.file ? {
					file: this.file,
					filename: this.file.name,
					filetype: this.file.type,
					filesize: this.file.size,
					source: window.location.href
				} : null
			}
		},

		created(){
			document.addEventListener('dragover', this.handleDocumentDragover)
			document.addEventListener('dragend', this.handleDocumentDragend)
			this.$root.$on('upload:drop', component => this.drag = this.dragover = false)
			this.$root.$on('document:dragleave', this.handleDocumentDragleave)
		},

		mounted(){
			this.$nextTick(() => this.is_mounted = true)
		},

		components: {Icon, IconButton, SuccessIcon, ErrorIcon}

	}
</script>

<style lang="scss">
	@import 'stylesheets/colors';
	@import 'stylesheets/variables';

	.upload {

		display: flex;
		width: 100%;
		min-height: 6em;
		position: relative;

		&.border {
			border: 1px dotted $dark-color;
		}

		&.block {
			min-height: 50vh;
		}

		input[type="file"] {
			position: absolute;
			opacity: 0;
			z-index: -1;
		}

		.content {
			display: flex;
			flex-direction: column;
			width: 100%;
			min-height: 6em;
			padding: 2em;
			justify-content: center;
			align-items: center;
		}

		.drag, .upload-error, .upload-loading, .upload-success {
			@include fill;
			opacity: 0.9;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			z-index: 11;
		}

		.drag {
			color: $dark-color;
			background-color: white;
			border: 2px dashed $info-color;

			&.dragover {
				color: white;
				background-color: $info-color;
			}

			.drag-content {
				display: flex;
				flex-direction: column;
			}
		}

		.upload-error {
			background-color: $danger-color;
			color: white;

			.dismiss {
				position: absolute;
				top: 0;
				right: 0;
			}
		}

		.upload-loading {
			background-color: white;
			color: $dark-color;

			.upload-loader {
				position: relative;
				width: 3em;
				height: 3em;
			}
		}

		.content-overlay {
			@include fill;
			background-color: white;
			z-index: 10;
		}

		.dropzone {
			@include fill;
			background-color: Transparent;
			cursor: copy;
			z-index: 12;
		}

		.document-overlay {
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: $secondary-color;
			opacity: 0.2;
			z-index: 9;
		}

	}
</style>