<template>
	<div class="prospect-info">
		<span class="prospect-name">{{prospect.fname}} {{prospect.lname}}</span>
		<span class="prospect-email">{{prospect.email}}</span>
	</div>
</template>

<script>
	export default {
		props: {
			prospect: Object
		}
	}
</script>

<style lang="scss">
	@import '../../common/shared.scss';

	.prospect-info {
		display: block;
		width: 10rem;
		font-size: 1rem;
		white-space: no-wrap;

		.prospect-name {
			dispaly: block;
			width: 100%;
			font-weight: bold;
			white-space: nowrap;
			overflow: hidden;
		}

		.prospect-email {
			display: block;
			width: 100%;
			font-weight: light;
			font-size: 0.75rem;
			overflow: hidden;
			color: $secondary-color;
		}
	}

</style>
