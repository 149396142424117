<template>
	<b-badge :class="{'stay-pill': true, selected: selected}" :variant="variant" :active="selected" :disabled="disabled">
		<stay-avatar v-model="value.avatar" :size="30" v-if="value.avatar && !hideAvatar"></stay-avatar>
		<span v-if="truncate" :class="{'pill-text': true, underlined: underlined}" :title="value.text">{{value.text | truncate(truncate)}}</span>
		<span v-else :class="{'pill-text': true, underlined: underlined}">{{value.text}}</span>
		<button v-if="!noRemove" aria-label="Remove" class="close-button" @click="$emit('remove')" :disabled="disabled">x</button>
	</b-badge>
</template>

<script>
	import { BBadge } from 'bootstrap-vue'
	import StayAvatar from 'common/stay_avatar'

	export default {
		props: {
			value: Object,
			selected: Boolean,
			noRemove: Boolean,
			underlined: Boolean, // Adds a dotted underline, useful for indicating something can be acted upon or is different
			hideAvatar: Boolean,
			disabled: Boolean,
			truncate: Number,
			variant: {
				type: String,
				default: "light"
			}
		},
		components: { BBadge, StayAvatar }
	}
</script>

<style lang="scss">
	@import "./shared.scss";
	.stay-pill {
	    margin: 0.25rem;
	    font-size: 0.75rem;
	    padding-right: 0.75rem;

	    &.selected {
	    	border: solid 1px $dark-color;
	    }

	    &.disabled {
	    	opacity: 0.6;
	    }

	    .pill-text {

	    	&.underlined {
	    		border-bottom: 0.5px dashed $secondary-color;
	    	}
	    }

	    button {
	    	border: none;
	    	background: none;
	    	outline: none;
	    }
	}
</style>