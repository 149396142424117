<template>
	<div class="prospect-pets d-flex flex-row">
		<div v-for="pet in (pets ? pets.slice(0,1) : value.slice(0,1))" class="d-flex flex-row">
			<div class="pet-avatar d-flex align-items-stretch">
				<entity-avatar :value="pet" size="sm" />
			</div>
			<div class="d-flex flex-column mr-2">
				<div v-if="highlight && highlight['pets.name'] && (stripHTML(highlight['pets.name'][0]) == pet.name)" class="pet-name"><span v-html="highlight['pets.name'][0]"></span></div>
				<div v-else class="pet-name">{{pet.name}}</div>
				<div v-if="pet.breeds.length > 0" class="pet-breeds">
					<span v-for="breed in pet.breeds" class="mr-1">{{breed.name}}</span>
				</div>
				<div v-else class="pet-breeds-unconfirmed">
					{{pet.breed_input}}
				</div>
			</div>
			<div class="more-pets" v-if="(pets ? pets.length > 1 : value.length > 1)">
				<small><b-badge pill variant="info">+{{(pets ? pets.length-1 : value.length-1)}}</b-badge></small>
			</div>
		</div>
	</div>
</template>

<script>
	import {BBadge} from 'bootstrap-vue'
	import EntityAvatar from 'common/entity_avatar'
	export default {
		components: {
			EntityAvatar,
			BBadge
		},
		props: {
			pets: Array,
			value: Array,
			highlight: Array | String
		},
		methods:{
			stripHTML(string){
				return string.replace(/(<([^>]+)>)/gi, "")
			}
		}
	}
</script>

<style lang="scss">
	@import '../../common/shared.scss';

	.prospect-pets {
		display: block;
		width: 15rem;

		.pet-avatar {
			margin-right: 0.25rem;
		}

		.pet-name {
			font-weight: bold;
		}

		.pet-breeds, .pet-breeds-unconfirmed {
			font-size: 0.75rem;
			color: $secondary-color;
			white-space: nowrap;
			overflow: none;
		}

	}
</style>