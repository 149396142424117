<template>
	<div>
		<!-- USERS TABLE -->
			<b-table 
				:items="users_prospects" 
				:fields="users_prospects_fields" 
				class="search-table" 
				:style="{opacity: (loading ? '0.5' : '1')}" 
				hover 
				responsive 
				selectable 
				:select-mode="(multiSelect ? 'multi' : 'single')" 
				@row-hovered="handleHover($event)" 
				@row-selected="handleSelect"
			>

				<!-- Name Column -->
				<template v-slot:cell(_source.fname)="data">
					<b-row class="first">
						<span v-if="data.item.highlight && data.item.highlight.fname" v-html="data.item.highlight.fname[0]">&nbsp;</span>
						<span v-else>{{data.item._source.fname || data.item._source.name}}&nbsp;</span>

						<span v-if="data.item.hightlight && data.item.highlight.lname" v-html="data.item.highlight.lname"></span>
						<span v-else>{{data.item._source.lname}}&nbsp;</span>
					</b-row>
					<b-row class="first">
						<tags :user="data.item._source" :highlight="(data.item.highlight ? data.item.highlight.tags : null)" no-edit @click="$emit('filter', {tags: $event})"></tags>
					</b-row>
				</template>

				<!-- Record Type -->
				<template v-slot:cell(_index)="data">
					<b-row>
						<small v-if="data.item._index=='users'"><b-badge variant="info" pill @click="$emit('filter', {indices: 'User'})">CLIENT</b-badge></small>
						<small v-if="data.item._index=='prospects'"><b-badge variant="secondary" pill @click="$emit('filter', {indices: 'Prospect'})">PROSPECT</b-badge></small>
					</b-row>
					<b-row>
						<xsmall>{{short_date(data.item._source.created_at)}}</xsmall>
					</b-row>
				</template>



				<!-- Pets -->
				<template v-slot:cell(_source.pets)="data">
					<prospect-pets :value="data.item._source.pets" :highlight="data.item.highlight"></prospect-pets>
				</template>


				<!-- Contact info -->
				<template v-slot:cell(_source.email)="data">
					<b-row>
						<small class="text-muted">{{data.item._source.email}}</small>
					</b-row>
					<b-row>
						<small>{{data.item._source.address | address}}</small>
					</b-row>
				</template>

			</b-table>

		<!-- PETS TABLE -->
		<h5 v-if="pets.length > 0">pets</h5>

		<b-table v-if="pets.length > 0" :items="pets" :fields="pets_fields" class="search-table" :style="{opacity: (loading ? '0.5' : '1')}">


		</b-table>

		<empty-state icon="search" text="Find some folks using the filters above" class="mt-5 mb-5" v-if="this.users_prospects.length == 0 && this.pets.length==0" v-loading.center="loading"></empty-state>
		<b-pagination></b-pagination>


	</div>
</template>

<script>
	import ProspectPets from './table/prospect_pets'
	import { BTable, BPagination, BBadge} from 'bootstrap-vue'
	import EmptyState from 'common/empty_state'
	import {short_date} from 'functions/date'
	export default {
		props: {
			value: {
				type: Array,
				default: []
			},
			//noSelect: Boolean,
			//selectOnClick: Boolean,
			forceLoading: Boolean,

			// Configuration for what to do on a click
			// Multiselect passes both @selected and @input
			// No multiselect passes only @input
			redirectOnClick: Boolean,
			multiSelect: Boolean,

			// Pass back the ES search object or the activerecord model
			returnSearchObject: Boolean

		},
		data(){
			return {
				users_prospects_fields: [
					{key: "_source.fname", label: "Name"},
					{key: "_index", label: "Customer Type"},
					{key: "_source.pets", label: "Pets"},
					{key: "_source.email", label: "Contact Info"}
				],
				pets_fields: [
					{key: "id", label: ""},
					{key: "_source.name", label: "Name"}
				],
				loading: false,
				all: false,
				selected: []
			}
		},
		methods: {
			short_date: short_date,
			handleSelect(items){
				this.selected = items
				if(this.redirectOnClick){
					window.location.href = this.urlForEntity(this.selected[0])
				}
				if(this.multiSelect){
					this.$emit('selected', this.selected)
				} else {
					this.$emit('selected', this.selected[0])
				}
			},
			handleHover(event){
				window.status = event._source.user_data.url
			},
			urlForEntity(es_object){
				// Quick hack to get this working. This all needs to be refactored:
				switch(es_object._index){
					case "users":
						return `/clients/${es_object._source.id}`
						break
					default:
						return `/${es_object._index}/${es_object._source.id}`
				}
			}
		},
		computed: {
			users_prospects(){
				if(this.value){
					return this.value.filter((v)=>{return ((v._index == "prospects") || (v._index == "users"))})
				} else {
					return []
				}
			},
			pets(){
				if(this.value){
					return this.value.filter((v)=>{return (v._index == "pets")})
				} else {
					return []
				}
			},
			buttonText(){
				if(this.selected.length > 0){
					return `Add ${this.selected.length == 1 ? 'this' : 'these'} ${this.selected.length}`
				} else if(this.users_prospects.length > 0){
					return `Add all ${this.users_prospects.length} ${this.$stayfunctions.pluralize('record', this.users_prospects.length)}`
				} else {
					return 'Add'
				}
			}
		},
		watch: {
			forceLoading(val){
				this.loading = val
			}
		},
		components: {
			'prospect-pets': ProspectPets,
			BTable, EmptyState, BPagination, BBadge
		}
	}
</script>

<style lang="scss">
	@import "../common/shared.scss";
	.search-table{
		transition: opacity .25s ease-in-out;
  		-moz-transition: opacity .25s ease-in-out;
  		-webkit-transition: opacity .25s ease-in-out;

  		.badge {
  			cursor: pointer;
  		}

  		.first {
  			margin-left: 0 !important;
  		}

	}
</style>