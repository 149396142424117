import eq from 'lodash/eq'


// Each validator is passed the "value" of the component which should be
// surfaced as a computed parameter, prop or data element with the key "value".
// The validator is also passed a second, optional parameter which is the value
// of the prop ...
// E.g. <my-component :value="10" :min-length="4" ... />
// ... would call handler for 'minLength' with parameters (10, 4)


const Validators = [

	{
		name: 'required',
		type: Boolean,
		handler: value => value ? true : false,
		invalidText: 'Required'
	},

	{
		name: 'requiredIf',
		type: Boolean,
		handler: (value, bool) => bool ? (value ? true : false) : true
	},

	{
		name: 'minLength',
		type: Number,
		handler: (value, length) => !value || value.length >= length ? true : false,
		invalidText: (value, length) => `Must be minimum ${length} characters`
	},

	{
		name: 'email',
		type: Boolean,
		handler: value => !value || value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) ? true : false,
		invalidText: 'Not a valid email address'
	},

	{
		name: 'password',
		type: Boolean,
		handler: value => !value || /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(value), // Min 8 character with capital and lowercase
		invalidText: 'Passwords must contain both lower and uppercase characters and at least 8 characters long'
	},

	{
		name: 'equals',
		type: String,
		handler: (value, comparator) => !value || eq(value, comparator),
		invalidText: "Values don't match"
	},

	// This one should be used if you just want to pass the actual
	// validation status directly...

	{
		name: 'invalid',
		type: Boolean,
		handler: (value, invalid) => !invalid,
		invalidText: 'Invalid value'
	}


]


const ValidatorProps = Validators.reduce((collector, value) => ({...collector, [value.name]: {type: value.type}}), {})


const Validateable = {

	props: {
		...ValidatorProps,
		invalidText: String
	},

	data(){
		return {
			validated: false,
			validateable: true
		}
	},

	methods: {
		validate: function(){ this.validated = true; return this.valid },

		invalidate: function(){ this.validated = false; return true }
	},

	computed: {

		active_validators(){
			this.value
			return Validators.filter(validator => this.$props[validator.name]).map(validator => {
				return {
					...validator,
					valid: validator.handler(this.value, this.$props[validator.name]),
					invalidText: typeof(validator.invalidText) == 'function' ? validator.invalidText(this.value, this.$props[validator.name]) : validator.invalidText
				}
			})
		},

		// validator_statuses: function(){ this.value; return this.active_validators.reduce((collector, validator) => ({
		// 	...collector, [validator.name]: {
		// 		status: validator.handler(this.value, this.$props[validator.name]),
		// 		invalidText: validator.handler(this.value, this.$props[validator.name]) ? null : (typeof(validator.invalidText) == 'function' ? validator.invalidText(this.value, this.$props[validator.name]) : validator.invalidText)
		// 	}}), {}) },

		valid: function(){ return !this.active_validators.some(validator => !validator.valid) },

		_invalid: function(){ return this.validated && this.valid == false },

		is_invalid: function(){ return this._invalid },

		_invalidText: function(){ return this.invalidText || this.active_validators.filter(validator => !validator.valid).map(validator => validator.invalidText).join(', ') }

	}

}

// This is a component that can be added and provided a prop of :validation
// which should be a boolean or a function that is executed. The component should
// be added to the inheriting component template wherever you want the invalidText
// to show on a failure...


const CustomValidation = {

	name: 'custom-validation',
	mixins: [Validateable],
	template: '<span v-show="is_invalid"><slot><span class="text-danger uppercase text-sm">{{_invalidText}}</span></slot></span>',
	props: {
		value: String | Object | Array // Very likely not used as we are just checking the actual :invalid prop here
	}


}


const InvalidText = {

	name: 'invalid-text',
	template: '<span v-show="invalid" class="invalid-text">{{invalidText}}</span>',
	computed: {
		invalid(){ return this.$parent._invalid },
		invalidText() { return this.$parent._invalidText }
	}
}

// This method can be used on any component to trigger validation of all
// components that have a ref and support it...

const validate = function(only_refs=null){

	let refs = Object.keys(this.$refs).filter(ref => this.$refs[ref] && this.$refs[ref].validateable && (!only_refs || only_refs.includes(ref)))
	if(refs.length == 0) console.warn("No validateable refs found on the component. Ensure you have refs defined for all components expected to be validated.")
	let results = refs.map(ref => this.$refs[ref].validate())
	return results.some(result => result == false) ? false : true

}

const invalidate = function(only_refs=null){

	let refs = Object.keys(this.$refs).filter(ref => this.$refs[ref] && this.$refs[ref].validateable && (!only_refs || only_refs.includes(ref)))
	if(refs.length == 0) console.warn("No validateable refs found on the component. Ensure you have refs defined for all components expected to be validated.")
	let results = refs.map(ref => this.$refs[ref].invalidate())
	return true

}

const Validation = {

	methods: {

		validate: validate,
		invalidate: invalidate

	}

}

export {Validateable, validate, invalidate, Validation, CustomValidation, InvalidText}
export default Validation