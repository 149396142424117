<template>
	<b-dropdown toggle-class="form-control custom-select d-flex justify-content-between align-items-end" menu-class="w-100" no-caret block @show="getOptions()" v-loading="loading">
		<template v-slot:button-content>
			<div class="text-muted mr-1">{{text}}</div>
			<xsmall class="text-truncate" v-if="selected.length != 0">
				<span v-for="select in selected">{{select}}, </span>
			</xsmall>
			<small v-else>ALL</small>
		</template>
		<b-dropdown-item button v-for="option in opts" @click="select(option)" button-class="d-flex justify-content-between">
			<span class="d-block">{{option}}</span>
			<span class="d-block" v-if="selected.includes(option)" v-html="$stayicon.svg('check')"></span>
		</b-dropdown-item>
	</b-dropdown>
</template>

<script>
	import {BDropdown, BDropdownItem} from 'bootstrap-vue'
	export default {
		components: {BDropdown, BDropdownItem},
		props: {
			text: {
				type: String,
				default: "Select"
			},
			value: Array,
			options: Array,
			source: Function,
			lazy: Boolean
		},
		data(){
			return {
				opts: [],
				selected: [],
				loading: false
			}
		},
		methods: {
			async getOptions(){
				if((this.opts.length) == 0 && this.source){
					this.loading = true
					try {
						this.opts = await this.source()
						this.loading = false
					} catch(err) {
						this.loading = false
						console.log(err)
					}
				}
			},
			select(option){
				if(this.selected.includes(option)){
					this.selected.splice(this.selected.indexOf(option), 1)
				} else {
					this.selected.push(option)
				}
				this.$emit('input', this.selected)
			}
		},
		mounted(){
			this.selected = this.value
			if(this.options){
				this.opts = this.options
			} else {
				if(this.source && !this.lazy){
					this.getOptions()
				}
			}
		}
	}
</script>