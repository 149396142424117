// Main entry point for the organizations application
import Vue from 'vue/dist/vue.esm'
import VueI18n from 'vue-i18n'
import Public from 'mixins/root/public'

import Users from '../../src/components/users'

// Local components (TODO: move into views??)
import VerifyForm from '../../src/components/temp_users/verify_form'
import Unsubscribe from '../../src/components/temp_users/unsubscribe'
import RsvpForm from 'components/org_events/rsvp_form'
import Hero from 'common/hero'
import NewSession from 'session/new_session'
import Authenticators from 'session/authenticators'
import { BJumbotron, BListGroup, BListGroupItem, BBadge } from 'bootstrap-vue'
import { event_time } from 'functions/date'

// Plugins
Vue.use(Users)
//Vue.use(OrgEvents)

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    data: {
      user: pageData.user,
      doc: pageData.document,
      total_pages: null,
      signature_data: {
        consent: {
          status: false,
          created_at: null
        },
        initials: {
          image: null,
          created_at: null
        },
        signature: {
          image: null,
          created_at: null
        }
      },
      complete: false,
      errors: [],
      loading: false
    },
    components: {
      'verify-form': VerifyForm,
      'unsubscribe': Unsubscribe,
      RsvpForm, Hero, NewSession, Authenticators,
      BJumbotron, BListGroup, BListGroupItem, BBadge
    },
    mixins: [Public],
    filters: {event_time: event_time},
    mounted() {
      console.log(" INDEX . J S")
      this.$on('connected', function(evt) {
        console.log(evt)
      })
    }
  }).$mount('#app')
});