<!-- Stay Avatar Guidelines
	 - The avatar accepts 3 different data types for rendering:
	 1. :user = user object which can be passed or will default to the pageData current_user
	 2. :user_upload which needs to be passed with the url_for included
	 3. :input which accepts an image url as a string or ione of the above objects

	 Other props:
	 1. size as a number
	 2. clickable which changes the cursor behavior

-->
<template>
	<div class="stay-avatar justify-content-center align-items-center" :style="style" v-loading.center="loading" v-if="entity">
		<transition name="fade">
			<img v-if="entity.avatar && !is_icon" :src="entity.avatar" 
				:style="(noCircle ? { position: 'relative' } : { clipPath: imageCircle, '-webkit-clip-path': imageCircle })" 
				:alt="'avatar'" 
				@load="handleImageLoad" 
				v-show="!loading"
			>
		</transition>
		<span 
			:style="{fontSize: fontSize}" 
			v-if="!entity.avatar && entity.initials"
		>
			{{entity.initials}}
		</span>
		<span 
			:style="{fontSize: fontSize}" 
			v-if="!entity.avatar && entity.emptyStateIcon"
			v-html="$stayicon.svg(entity.emptyStateIcon)"
			class="empty-state-icon"
		>
		</span>
		<svg width="100" height="100" v-if="!noCircle">
  			<circle cx="50%" cy="50%" :r="(size/2)-1" :stroke="$staycolors.primary" stroke-width="0.75" :fill="$staycolors.light" />
		</svg>
	</div>
</template>


<script>
	import Entity from 'mixins/entity'
	export default {
		data() {
			return {
				loading: true,
				image_url: null,
				content_type: null,
				current_user: null,
				source: null
			}
		},
		props: {
			clickable: Boolean,
			size: {
				type: Number,
				default: 50
			},
			value: Object | String,
			noCircle: Boolean,
			boxShadow: Boolean,
			editable: Boolean,			// Will handle upload direct from a click
		},
		computed: {
			fontSize() {
				return (this.size/2.5) + 'px'
			},
			imageCircle() {
				return 'circle(' + Math.round(this.size/2-2) + 'px at center)'
			},
			backgroundColor(){
				return {backgroundColor: (this.entity_type == 'UserDocument' && !this.entity.avatar ? this.$staycolors.light : 'Transparent')}
			},
			dimensions(){
				if(this.entity){
					switch(this.entity.type){
						case "UserDocument":
							if(this.entity.avatar){
								return {minWidth: `${this.size}px`, maxWidth: `${this.size}px`, maxHeight: `${this.size}px`}
							} else {
								return {minWidth: `${this.size/1.29}px`, width: `${this.size/1.29}px`, height: `${this.size}px`, margin: `0 ${(this.size-(this.size/1.29))/2}px 0 ${(this.size-(this.size/1.29))/2}px`}
							}
							break
						default:
							return {minWidth: `${this.size}px`, width: `${this.size}px`, height: `${this.size}px`}
					}
				} else {
					return {}
				}

			},
			cursor(){
				return {cursor: (this.clickable || this.editable ? 'pointer' : 'default')}
			},
			style(){
				return {...this.dimensions, ...this.backgroundColor, ...this.cursor}
			},
			is_icon(){
				return this.entity.avatar && !this.entity.match(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)
			}
		},
		methods: {
			handleImageLoad() {
				this.loading = false
			}
		},
		mounted(){
			this.$nextTick(()=>{
				if(this.entity && !this.entity.avatar){
					this.loading = false
				}
			})
		},
		mixins: [Entity]
	}
</script>

<style lang="scss">
	@import 'css';
	.stay-avatar {
		position: relative;
		display: flex;

		.empty-state-icon svg {
			width: 40%;
			height: 40%;
		}

	}
	.stay-avatar svg {
		position: relative;
		width: 100%;
		height: 100%;
	}
	.stay-avatar span {
		position: absolute;
		z-index: 2;
		line-height: 50px;
		width: 100%;
		text-align: center;
	}
	.stay-avatar img {
		max-width: 100%;
		max-height: 100%;
		position: absolute;
		z-index: 2;
	}
</style>
