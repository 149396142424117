<template>
	<div :class="{
			'string-edit': true,
			placeholder: !internal_value,
			underline: underline,
			'edit-on-click': editOnClick
		}" @click="handleClick" v-loading="loading">
		<slot v-if="!(edit || editing)">{{internal_value || placeholder}}</slot>
		<stay-input 
			v-if="(edit || editing) && !html" 
			v-model="internal_value" 
			v-bind="$props"
			ref="input"  
			@destroy="$emit('destroy')" 
			@keyup.enter="handleBlur"
			@blur="handleBlur"
		/>
		<html-input
			v-if="(edit || editing) && html"
			v-model="internal_value"
			v-bind="$props"
			ref="input"
			@blur="handleBlur"
		/>
	</div>
</template>

<script>
	import StayInput from 'common/stay_input'
	import HtmlInput from 'common/html_input'
	import Http from 'mixins/http'
	import Dirty from 'mixins/dirty'
	export default {
		name: 'string-edit',
		props: {
			value: String | Object,
			placeholder: String,
			editing: Boolean,
			editOnClick: Boolean,
			email: Boolean, // Force email as it is a standard string and not an object
			address: Boolean, // Force address as this could be a standard string
			required: Boolean,
			validateOnBlur: Boolean,

			// Name and ID for the input field
			// This is effectively the "key" in the key/value pair but we can't use the word key as an attribute
			name: {
				type: String,
				default: "stringEdit"
			},

			// Used to pass strong parameters. E.g. if a document is updated it should be passed as document: {key: value}
			rootKey: String, 

			// If provided this field will update the server on change
			url: String, 
			method: {
				type: String,
				default: "put"
			},
			showDestroy: Boolean,
			underline: Boolean,
			html: Boolean

		},
		data(){
			return {
				loading: false,
				edit: false,
				internal_value: this.value,
				touched: false,
				mounted: false
			}
		},
		methods: {
			handleClick(){
				console.log("click")
				if(this.editOnClick){
					this.edit = true
					this.$nextTick(() => this.$refs.input.focus())
				}
			},
			handleBlur(){
				if(this.$dirty.is_dirty && this.$refs.input.validate()){
					this.submit().then(this.reset)
				} else {
					this.reset()
				}
			},
			async submit(){
				if(!this.url) return
				try {
					return await this[this.method](this.url, {
						...(this.rootKey && {[this.rootKey]: {[this.name]: this.internal_value}}),
						...(!this.rootKey && {[this.name]: this.value})
					}, {alert: false, loader: false})
				} catch(err){
					throw(err)
				}
			},
			reset(){
				this.edit = false
			}
		},
		mounted(){
			//this.internal_value = this.value
			this.$nextTick(()=>{this.mounted=true})
			this.$root.$on('$dirty:restore', ()=>{
				console.log("string_edit: $dirty:restore")
				this.internal_value = this.value
			})
		},
		watch: {
			internal_value(val, oldVal){
				if(this.mounted) this.$emit('input', val)
			}
		},
		components: { StayInput, HtmlInput },
		mixins: [Http, Dirty],
		dirty: ['value']
	}
</script>

<style lang="scss">
	@import "stylesheets/colors";

	.string-edit {
		display: inline-block;
		position: relative;

		&.placeholder {
			opacity: 0.6;
			font-style: italic;
		}

		&.underline {
			border-bottom: 1px dotted $secondary-color;
		}

		&.edit-on-click {
			cursor: pointer;
			border-bottom: 1px solid Transparent;

			&:hover {
				border-bottom: 1px dotted $info-color;
			}

		}

	}
</style>