<template>
	<div :class="{'input-overlay': true, edge: destroy || overlays.destroy}" v-loading="loading || overlays.loading">
			<div class="input-overlay-container" v-if="success || overlays.success || valid == true || $data._valid == true">
				<div class="success-checkmark">
					<svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
				</div>
			</div>

			<div class="input-overlay-error" v-visible.scale-in-center.scale-out-center="error || overlays.error || valid == false || $data._valid == false">
				!
			</div>

			<div class="input-overlay-destroy" v-if="destroy || overlays.destroy" tabindex="0" role="button" @click="$emit('destroy')">
				-
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			// You can either specify the individual booleans to show using props
			// below or you can alter the data object directly (used in the v-overlays directive)
			success: Boolean,
			error: Boolean,
			loading: Boolean,
			destroy: Boolean,

			// Another method of using this is via validation. In this situation
			// the component will show: Success = true, Error = false, empty = null
			valid: null
		},
		data(){
			return {
				// This is provided primarily for the v-overlays directive
				// which may pass multiples of these in after being mounted
				// and attached to the input element
				overlays: {
					success: false,
					error: false,
					loading: false,
					destroy: false
				},

				// Another method of using this is via validation. In this situation
				// the component will show: Success = true, Error = false, empty = null
				_valid: null
			}
		}
	}
</script>

<style lang="scss">
	@import "css";
	.input-overlay {
		position: absolute;
		display: flex;
		width: 100%;
		height: 100%;
		background-color: Transparent;
		align-items: center;
		justify-content: flex-end;
		pointer-events: none;
		padding-right: 1rem;
		z-index: 2;

		&.edge {
			padding-right: 0.5rem !important;
		}

		.input-overlay-container {
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 1.5rem;
			height: 1.5rem;
		}

		.input-overlay-error {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $danger-color;
			border-radius: 5px;
			color: white;
			font-size: 0.75rem;
			height: 1.25rem;
			width: 1.25rem;
			font-weight: 700;
		}

		.input-overlay-destroy {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $secondary-color;
			border-radius: 100px;
			width: 1rem;
			height: 1rem;
			color: white;
			cursor: pointer;
			pointer-events: auto;
			margin-left: 0.5rem;
		}
	}
</style>