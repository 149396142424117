import Pluralize from 'pluralize'

export default {

	methods: {
		pluralize: Pluralize
	},

	filters: {
		pluralize: function(val, string, include_val_in_response=true){
			return Pluralize(string, val, include_val_in_response)
		}
	}

}