<template>
	<b-list-group class="p-3">
		<b-table-lite :items="phone_numbers" :fields="['friendly_name','label','default','verified','sms_opt_in']" hover thead-class="d-none">
			<template slot="friendly_name" slot-scope="data">
				<span class="w-100 d-block">{{data.item.friendly_name}}</span>
				<b-badge class="d-md-none">{{data.item.label}}</b-badge>
				<b-badge v-if="data.item.default" variant="info" class="d-md-none">default</b-badge>
			</template>
			<template slot="label" slot-scope="data">
				<b-badge class="d-none d-md-block">{{data.item.label}}</b-badge>
			</template>
			<template slot="default" slot-scope="data">
				<b-badge v-if="data.item.default" variant="info" class="d-none d-md-block">default</b-badge>
			</template>
			<template slot="verified" slot-scope="data">
				<small><span class="w-100 w-xl-auto d-block d-xl-inline">Verified </span><span v-html="$stayicon.boolean(data.item.verified)"></span></small>
			</template>
			<template slot="sms_opt_in" slot-scope="data">
				<small><span class="w-100 w-xl-auto d-block d-xl-inline">SMS Opt In </span><span v-html="$stayicon.boolean(data.item.sms_opt_in)"></span></small>
			</template>
		</b-table-lite>
		<b-button>New</b-button>
	</b-list-group>
</template>

<script>
	export default {
		props: {
			phone_numbers: Array
		}
	}
</script>