<template>
	<b-modal :title="`New ${prospect.user_data.type}`" id="new-user-or-prospect" @ok.prevent="handleSubmit" :visible="true" @cancel="$emit('cancel')">
		<b-form>
			<b-form-group label="First Name" :state="show_validations ? true : null">
				<b-form-input v-model="prospect.fname" :state="show_validations ? true : null"></b-form-input>
			</b-form-group>


			<b-form-group label="Last Name" invalid-feedback="Last name is required">
				<b-form-input v-model.trim.lazy="$v.prospect.lname.$model" :state="show_validations ? !$v.prospect.lname.$error : null" required></b-form-input>
			</b-form-group>


			<b-form-group label="Email Address" invalid-feedback="Please enter a valid email address">
				<b-form-input v-model.trim.lazy="$v.prospect.email.$model" :state="show_validations ? !$v.prospect.email.$error : null" required></b-form-input>
			</b-form-group>


			<b-form-group label="Phone Number" invalid-feedback="Please enter a valid phone number" :state="show_validations ? prospect.phone.valid : null">
				<phone-number-input v-model="prospect.phone" ref="phone" :state="show_validations ? prospect.phone.valid : null"></phone-number-input>
			</b-form-group>


			<b-form-group label="Source">
				<b-form-input v-model="prospect.source" :state="show_validations ? true : null"></b-form-input>
			</b-form-group>
		</b-form>
	</b-modal>
</template>

<script>
	import { required, email } from 'vuelidate/lib/validators'
	export default {
		props: {
			user: Object
		},
		data() {
			return {
				prospect: {
					fname: null,
					lname: null,
					email: null,
					phone: {
						phone_number: null,
						friendly_name: null,
						label: null,
						valid: false,
						capabilities: {},
						iso: null
					},
					user_data: {
						type: "prospect",
						url: null
					},
					source: null
				},
				show_validations: false
			}
		},
		validations: {
			prospect: {
				lname: {
					required
				},
				email: {
					required,
					email
				},
			}
		},
		methods: {
			handleSubmit() {
				this.show_validations = true
				this.$v.$touch()
				if(this.$v.$invalid) {
					console.log("Error found in the form entry")
					//this.$v.$reset()
				} else {
					//console.log('Submit')
					let data = (this.prospect.user_data.type=='prospect' ? {prospect: this.prospect} : {user: this.user})
					//if (this.prospect.phone) { this.prospect.phone = this.$refs.phone.phoneObject }
					this.post(`/${this.prospect.user_data.type}s.json`, data)
					.then(response => {
						//console.log(response)
						this.$emit('input', response.data.data)
						this.$bvModal.hide('new-user-or-prospect')
						//pageData.prospects.push(response.data.data)
					},
					err => {
						console.log(err)
					})
				}
			}
		},
		mounted(){
			if(this.user){
				//console.log(this.user)
				this.prospect = this.user
			}
		}
	}
</script>