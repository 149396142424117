<template>
	<div class="stay-note">
		<b-modal id="note-modal" title="New Note" :visible="modal_visible" @ok.prevent="handleSave" size="lg" @hide="handleHide">
			<template v-slot:modal-footer="{ ok, cancel }">
				<div class="d-flex justify-content-between w-100">
					<small class="text-muted">{{(os == "Mac" ? '&#8984;' : 'CTRL')}} + enter to save</small>
					<span class="d-block">
						<b-button variant="secondary" @click="cancel">Cancel</b-button>
						<b-button variant="primary" @click="ok" :disabled="!edited">Save</b-button>
					</span>
				</div>
			</template>
			<b-form-textarea class="note-textarea" @keydown.ctrl.enter="handleSave" @keydown.meta.enter="handleSave" no-resize :max-rows="10" :rows="3" v-model="event_data.notes" autofocus></b-form-textarea>
		</b-modal>
		<floating-button ref="floatingButton" v-visible.scale-in-center.scale-out-center="!modal_visible && edited" pulse icon="message-circle" :tooltip="event_data.notes || 'New Note' | truncate" @click="modal_visible=true"></floating-button>
	</div>
</template>

<script>
	import {BModal, BFormTextarea} from 'bootstrap-vue'
	import FloatingButton from 'common/floating_button'
	import Http from 'mixins/http'
	import Dirty from 'mixins/dirty'
	export default {
		props: {
			event: Object
		},
		data(){
			return {
				event_data: {
					name: 'Note',
					event_type: 'note',
					notes: ""
				},
				modal_visible: true,

				// This allows us to create a diff so we can determine
				// if the content has been changed and show the appropriate
				// warning to the user on page exit, etc.
				old_note: ""
			}
		},
		methods: {
			async handleSave(){
				if(!this.valid) return
				try {
					let response = this.event_data.id ? await this.update() : await this.create()
					this.old_note = response.data.event.notes
					this.close()
				} catch(err){
					throw(err)
				}	
			},
			create(){
				return this.post(this.url, this.event_data)
			},
			update(){
				return this.put(this.url, this.event_data)
			},
			handleUnload(e){
				e = e || window.event
				if(this.edited){
					if(e){
						e.preventDefault()
						e.returnValue = 'You have made changes to this page that will be lost. Are you sure you want to leave?'
					}
					return 'You have made changes to this page that will be lost. Are you sure you want to leave?'
				}

			},
			handleHide(event){
				if(event.trigger != "cancel" && this.edited){
					this.modal_visible = false
				} else {
					event.preventDefault()
					this.close()
				}
			},
			async close(){
				// Confirms if the data hasn't been saved and then removes any
				// unload listeners, etc.
				try {
					var confirmation = true
					if(this.edited && this.valid) confirmation = await this.$modals().confirm("Are you sure you want to close? You will lose any unsaved notes.")
					if(confirmation){
						this.modal_visible = false
						this.$nextTick(()=>{
							this.$emit('cancel')
						})
					}
				} catch(err){
					throw(err)
				}
			}
		},
		mounted(){
			if(this.event){
				this.event_data = JSON.parse(JSON.stringify(this.event))
				this.old_note = this.event_data.notes
			}
			window.addEventListener("beforeunload", this.handleUnload)
		},
		beforeDestroy(){
			if(this.$refs.floatingButton){
				this.$refs.floatingButton.$destroy()
			}
			console.log("Note modal being destroyed")
			window.removeEventListener("beforeunload", this.handleUnload)
		},
		computed: {
			url(){
				return `${this.human.url}/events${this.event_data.id ? `/${this.event_data.id}` : ''}`
			},
			edited(){
				return this.event_data.notes != this.old_note
			},
			valid(){
				return this.event_data.notes != "" && this.edited
			}
		},
		componnents: {BModal, BFormTextarea, FloatingButton},
		mixins: [Http]
	}
</script>

<style lang="scss">
	@import '../common/shared.scss';

	.note-textarea{
		border: none;
		font-family: $primary-font;
	}
</style>
