<template>
	<b-navbar toggleable="md">
		<b-navbar-toggle target="search-filter-collapse">
			<template v-slot:default="{ expanded }" class="bg-info">
				<small>Filters <icon value='filter' /></small>
			</template>
		</b-navbar-toggle>
		<b-collapse id="search-filter-collapse" is-nav>
			<b-container>
				<b-row>
					<b-col cols=12 md=6 lg=4 xl=3>
						<b-form-input placeholder="Search" v-model="q" @input="getResults()"></b-form-input>
					</b-col>
					<b-col cols=12 md=6 lg=4 xl=3>
						<array-dropdown v-model="selected.tags" :source="getTags" text="Tags" lazy @input="getResults()"></array-dropdown>
					</b-col>
					<b-col cols=12 md=6 lg=4 xl=3>
						<array-dropdown v-model="selected.indices" :options="options.indices" text="Type" @input="getResults()"></array-dropdown>
					</b-col>
				</b-row>
				<b-row class="mt-3">
					<b-col>
						<small class="font-weight-bold">{{ results.length | pluralize('result') }} found</small>
					</b-col>
				</b-row>
			</b-container>
		</b-collapse>
	</b-navbar>
</template>

<script>
	import { BCollapse, BNavbar, BNavbarToggle, BFormInput } from 'bootstrap-vue'
	import Http from 'mixins/http'
	import Pluralize from 'mixins/pluralize'
	import ArrayDropdown from 'common/array_dropdown'
	import Icon from 'common/icon'
	export default {
		props: {
			only: {
				type: String | Array,
				default: ()=>{ return ["User","Prospect","Pet","Breed"]}
			}
		},
		data(){
			return {
				q: null,
				options: {
					tags: [],
					indices: null // This is overriden by the prop on mount
				},
				selected: {
					tags: [],
					indices: []
				},
				loading: false,
				results: [],
				total: 0
			}
		},
		methods: {
			getTags(){
				return new Promise((resolve, reject)=>{
					//console.log("Getting tags")
					this.get(`/tags`, {}, {alert: false, loader: false}).then(response=>{
						resolve(response.data.data)
					}, err=>{
						reject(err)
					})
				})
			},
			getResults(){
				if(window.location.pathname == "/search"){
					window.history.replaceState('', '', `${window.location.origin}/search?q=${encodeURI(this.q)}`)
				}
				if(!this.loading){
					this.$emit('loading')
					this.loading = true
					setTimeout(()=>{
						this.post("/search", {
							query: this.query,
							indices: (this.selected.indices.length > 0 ? this.selected.indices : this.options.indices)
						}, {loader: false, alert: false}).then(response=>{
							this.results = response.data.data.results
							this.total = response.data.data.total
							this.$emit('response', response.data.data.results)
							if(pageData.results){
								pageData.results = response.data.data.results
							}
							this.loading = false
						}, err=> {
							console.log(err)
							this.$emit('response', [])
							this.loading = false
						})
					}, 1000)
				}
			},
			addFilter(filters){
				//Expects filters to be passed as a key/value pairs (e.g. {tags: 'LRA', user_status: 'test'})
				Object.keys(filters).forEach((f)=>{
					if(this.selected[f]){
						if(!this.selected[f].includes(filters[f])){
							this.selected[f].push(filters[f])
							this.getResults()
						}
					} else {
						this.selected[f] = [filters[f]]
						this.getResults()
					}
				})
				return this.selected
			}
		},
		created(){
			this.q = pageData.q || null
			this.results = pageData.results || []
			this.options.indices = (typeof(this.only)=='string' ? [this.only] : this.only)
		},
		computed:{
			query(){
				var res = {
					query: {
						bool: {
							must: {},
							filter: []
						}
					},
					highlight: {
						fields: [
							{fname:{}},
							{lname:{}},
							{tags:{}},
							{email:{}},
							{name:{}},
							{'pets.name':{}}
						]
					}
				}
				this.selected.tags.forEach((t)=>{
					res.query.bool.filter.push({match: {tags: t}})
				})
				if(this.q && (this.q != "")){
					res.query.bool.must = {query_string: {query: this.q}}
				} else {
					res.query.bool.must = {match_all: {}}
				}
				return res
			}
		},
		watch: {
			loading(val){
				this.$emit('loading', val)
			}
		},
		mixins: [Http, Pluralize],
		components: { BCollapse, BNavbar, BNavbarToggle, ArrayDropdown, Icon, BFormInput }
	}
</script>