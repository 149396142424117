<template>
	<div class="user-status-colors d-flex flex-row">
		<div 
			v-for="(color, index) in value.options.time_based_color" 
			:style="{backgroundColor: $staycolors[color.color]}" 
			:key="index" 
			class="swatch m-1"
			v-b-tooltip.hover
			:title="(color.time == 0 ? 'Default color' : 'Color shown after ' + pluralize('day', color.time, true))"
		></div>
	</div>
</template>

<script>
	import Pluralize from 'pluralize'
	export default {
		props: {
			value: Object
		},
		methods: {
			pluralize: Pluralize
		}
	}
</script>

<style lang="scss">
	.user-status-colors {
		min-width: 5rem;
		height: 1.5rem;

		.swatch {
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 500px;
		}
	}
</style>