<template>
	<div class="user-status" :style="{color: $staycolors[variant]}">

		<div v-if="!editable && value">
			<icon v-if="value.icon" :value="value.icon" :variant="variant" />
			{{value.name}}
		</div>

		<b-dropdown v-if="editable && status" toggle-class="user-status-edit" :no-caret="noCaret">
			<template v-slot:button-content>
				<icon v-if="status.icon" :value="status.icon" :hex="color" />
				<span :style="{color: color}">{{status.name}}</span>
			</template>
			<b-dropdown-item v-for="user_status in user_statuses" :key="user_status.id" @click="handleUpdate(user_status)"><icon :value="user_status.icon" />{{user_status.name}}</b-dropdown-item></b-dropdown>
		</b-dropdown>

		<b-dropdown v-if="editable && !status" toggle-class="user-status-edit">
			<template v-slot:button-content>
				<span :style="{color: $staycolors[variant]}">No Status</span>
			</template>
			<b-dropdown-item v-for="user_status in user_statuses" :key="user_status.id" @click="handleUpdate(user_status)"><icon :value="user_status.icon" />{{user_status.name}}</b-dropdown-item></b-dropdown>
		</b-dropdown>

	</div>
</template>

<script>
	import {BDropdown, BDropdownItem} from 'bootstrap-vue'
	import Icon from 'common/icon'
	import Http from 'mixins/http'
	import {days_ago} from 'functions/date'
	import { Entity } from 'entity'
	export default {
		// UPDATE JULY 2020:
		// This component relies on pageData.organization.user_statuses
		// to be present on the page to operate. We will no longer be
		// calculating the color ahead of time and sending to ES.
		// Instead we will rely on realtime JS calculation on page load
		//
		// UPDATE JAN 2022:
		// Now using the this.human dataset which should be standard across all components
		// where a human is present on the page
		// 
		// UPDATE FEB 2022:
		// Can now call to get the full list of user_statuses available from the 
		// /settings/user_statuses endpoint if they don't exist on the page:
		// NOTE: Want to be careful with this as it could cause multiple calls to the server
		// so we're putting it behind a :fetch prop which is false by default
		data() {
			return {
				loading: false,
				editing: false,
			}
		},
		props: {
			value: Object, 			// Expects a human. If undefined, will fallback on this.human which means "input" event would need to be handled explicitly
			user_status_id: Number, // Used for specifying a specific v-model to update e.g. v-model:user_status_id
			variant: {
				type: String,
				default: 'secondary'
			},
			editable: Boolean,
			noCaret: Boolean,
			fetch: Boolean, 		// Will fetch user_statuses for the org if not on the page
			noUpdate: Boolean,		// Prevents changing the selector from trying to update the :human
		},
		methods: {
			requestEdit() {
				this.editing = true
				this.loading = true
			},
			handleUpdate(user_status) {
				// 2 use cases to handle here:
				// 1. They select a new status
				// 2. They select a status that already exists
				// 3. The names don't match, update
				if(this.noUpdate) return this.$emit('input', {user_status_id: user_status.id, status_updated_at: Date.now()})
				if(!this._human){ this.$alert.danger('No prospect or user selected'); return ;}
				if(this.status && this.status.name == user_status.name){
					return this.$modals().confirm('This status is already selected. Would you like to reset the color option?').then(confirm => { return confirm ? this.update(user_status) : false})
				}
				this.update(user_status)
			},
			update(user_status) {

				this.put(this._human.url, {
					human: {
						user_status_id: user_status.id,
						force_status_update: true
					}
				}).then(this.handleUpdateResponse)

			},
			handleUpdateResponse(response){
				this.$emit('input', {
					...this._human,
					...{
						user_status_id: (response.data.prospect || response.data.user)['user_status_id'],
						status_updated_at: (response.data.prospect || response.data.user)['status_updated_at']
					}
				})
			},
			fetchStatuses(){
				this.get('/settings/user_statuses', {}, {loader: false, alert: false}).then(res => {
					this.$root.$set(this.$root.pageData, 'user_statuses', res.data.user_statuses.data)
				}, err => {
					console.error(err)
				})
			}
		},
		computed: {
			_human(){
				return Entity(this.value || this.human)
			},
			all_statuses(){
				return this.$root.pageData.user_statuses || []
			},
			// The full catalog of user_statuses stored against the organization
			// filtered for the entity type (user or prospect)
			user_statuses(){
				return this.all_statuses.filter((s)=>{
					if(this._human.type == 'prospect'){
						return s.available_for_prospects
					} else {
						return s.available_for_users
					}
				})
			},
			// The specific user_status from the full catalog provided above
			status(){
				if(this._human.user_status_id){
					return this.user_statuses.find((s)=>{return s.id == this._human.user_status_id})
				} else {
					return null
				}
			},
			color(){
				var color = this.$staycolors.secondary
				if(this._human.status_updated_at){
					if(this.status.options.time_based_color && this.status.options.time_based_color.length > 0){
						this.status.options.time_based_color.forEach((c)=>{
							if(days_ago(this._human.status_updated_at) >= c.time){
								color = this.$staycolors[c.color]
							}
						})
					}
				}
				return color
			},
			_editable(){
				return this.editable && ((this._human._entity_type == 'Prospect' && this.current_user.permissions.update_prospects) || (this._human._entity_type == 'Client' && this.current_user.permissions.update_clients))
			}
		},
		mounted(){
			if(this.fetch) this.fetchStatuses()
		},
		mixins: [Http],
		components: {BDropdown, BDropdownItem, Icon}
	}
</script>

<style lang="scss">
	@import '../../components/common/shared.scss';
	.user-status {
		position: relative;
		display: block;
	}
	.user-status-edit {
		background-color: transparent;
		border: none;
		color: $dark-color;
	}
	.user-status-edit:hover {
		background-color: $light-color;
	}

</style>