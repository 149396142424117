<template>
  <div class="w-100">
    <b-form @submit.prevent="onSubmit" v-if="show" action="/users/sign_in" method="post">
      <transition name="fade" mode="out-in">
        <b-form-group
          v-if="!verify_data.code_sent"
          id="phone-number"
          label-for="phone-number"
          label="We need to confirm your identity before continuing. We will send you a 4-digit code."
          :invalid-feedback="error"
          :state="valid"
        >
          <b-form-radio-group
            id="code_destination"
            :options="options"
            v-model="verify_data.code_method"
            class="mt-5"
            :disabled="loading"
          ></b-form-radio-group>
        </b-form-group>

        <code-entry :device="device" v-model="verify_data.code" v-if="verify_data.code_sent" @back="verify_data.code_sent = false" @input="onSubmit" />
      </transition>
      <span v-if="valid==false && verify_data.code_sent" class="d-block w-100 text-danger text-center">{{error}}</span>

    <b-button type="submit" variant="dark" class="w-100 mt-3">{{(loading ? '' : 'Continue')}}<b-spinner v-if="loading" variant="light" small /></b-button>
    </b-form>
  </div>
</template>

<script>
  import CodeEntry from './4_digit_code'
  export default {
    data() {
      return {
        show: true,
        loading: false,
        error: "Please choose where we should send the code",
        valid: null,
        url: window.location.href,
        verify_data: {
          code: null,
          code_sent: false,
          code_method: null,
          id: this.temp_user.id
        }
      }
    },
    props: {
      temp_user: Object,
      redirect: {
        type: String,
        default: "/"
      }
    },
    methods: {
      onSubmit(){
        if(this.verify_data.code_method==null){
          this.valid = false
          return false
        }
        this.valid = true
        this.loading = true
        this.$stayhttp.put(this.url, {
          verify_data: this.verify_data
        }, {loader: false, alert: false}).then(response => {
          console.log(response)
          if(response.data.data.success){
            // This means the user was authenticated so we can store the cookie
            // and move on the the redirect page (if it exists)
            this.setCookie(response.data.data.id)
            window.location.href = this.redirect
          } else if(response.data.data.success == false) {
            this.error = response.data.message
            this.valid = false
            this.loading = false
          } else {
            this.verify_data = response.data.data
            this.loading = false
          }
        }, err => {
          console.log(err)
          this.error = "Something went wrong"
          this.valid = false

        })
      },
      setCookie(uuid){
        let d = new Date();                
        d.setTime(d.getTime() + (1*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = "temp_user=" + uuid + ";" + expires + ";path=/";
      }
    },
    computed: {
      options() {
        var data = []
        for(var i=0; i<this.temp_user.masked_data.phone_numbers.length; i++){
          data.push({text: `Text me at ${this.temp_user.masked_data.phone_numbers[i].friendly_name}`, value: this.temp_user.masked_data.phone_numbers[i].id})
        }
        if(this.temp_user.masked_data.email){
          data.push({text: `Email me at ${this.temp_user.masked_data.email}`, value: "email"})
        }
        return data
      },
      device() {
        return (this.code_method == "email" ? "email" : "phone")
      }
    },
    components: {
      'code-entry': CodeEntry
    }
  }
</script>