<template>
	<b-input-group :class="{'stay-input': true, error: _invalid}">
		<input-overlay v-if="$listeners.destroy" destroy @destroy="$emit('destroy')"></input-overlay>
		<icon-button class="prepend" :icon="getIcon" @click.stop v-if="getIcon" :disabled="disabled">
			<template>
				<slot></slot>
			</template>
		</icon-button>
		<div class="append" v-if="append">{{append}}</div>
		<phone-number-input v-if="isPhone" v-model="internal_value" :placeholder="placeholderText" ref="input" @blur="handleBlur" @valid="touched = $event ? true : $event" :name="name" @keyup="handleKeyup" :disabled="disabled" />
		<address-input v-if="isAddress" v-model="internal_value" ref="input" @blur="handleBlur" no-form-control :name="name" @keyup="handleKeyup" :disabled="disabled" />
		<b-form-input v-if="!isPhone && !isAddress && !textarea" :placeholder="placeholderText" :class="{'ml-5': getIcon}" v-model="internal_value" @blur="handleBlur" :name="name" ref="input" @keyup="handleKeyup" :disabled="disabled" />
		<b-form-textarea v-if="textarea" :placeholder="placeholderText" :class="{'ml-5': getIcon}" v-model="internal_value" @blur="handleBlur" :name="name" ref="input" @keyup="handleKeyup" :disabled="disabled" />
		<invalid-text />
	</b-input-group>
</template>

<script>
	import has from 'lodash/has'
	import {Validateable, InvalidText} from 'mixins/validation'
	import AddressInput from 'common/address_input'
	import InputOverlay from 'common/input_overlay'
	import IconButton from 'common/icon_button'
	import PhoneNumberInput from 'common/phone_number_input'
	import { BFormInput, BInputGroup, BFormTextarea } from 'bootstrap-vue'
	export default {
		components: {
			AddressInput, InputOverlay, IconButton, PhoneNumberInput, InvalidText,
			BFormInput, BInputGroup, BFormTextarea
		},
		mixins: [Validateable],
		name: "StayInput",
		props: {
			value: String | Object,
			name: String, 		// This is the value to pass as a form element and used for debugging purposes
			//phone: Boolean,	// This is actually handled by Validateable mixin now
			//email: Boolean,	// This is actually handled by Validateable mixin now
			address: Boolean,
			icon: String,
			textarea: Boolean,	// Can be shown as a text area instead of an input
			placeholder: String,
			append: String,		// Adds characters to the end of the box e.g. @, .00, etc

			// Validation states. Some of these are automated but can be
			// overridden with the following props (in priority order):
			error: Boolean, 	// Overrides everything
			success: Boolean,
			loading: Boolean,
			disabled: Boolean,

			// Used for arrays where you want to add a remove option
			//showDestroy: Boolean,

		},
		data(){
			return {
				internal_value: this.value,
				// We don't want to show errors until the input has been
				// focused and typed in
				touched: false,
				mounted: false
			}
		},
		computed: {
			isPhone(){
				return (typeof(this.value) == 'object' && has(this.value, 'phone_number')) || this.phone
			},
			isAddress(){
				return (typeof(this.value) == 'object' && (has(this.value, 'place_id') || has(this.value, 'city')) ) || this.address
			},
			isEmail(){
				return this.email
			},
			showSuccess(){
				if(this.success){
					return true
				// } else if(this.valid && !this.hideSuccess){
				// 	return Object.keys(this.$props).some(r=> this.showSuccessOn.includes(r)) && this.touched
				} else {
					return false
				}
			},
			showError(){
				return this.error || (!this.valid && !this.hideError && this.touched)
			},
			placeholderText(){
				return this.placeholder || (this.isPhone ? "e.g. 4155551212" : (this.isEmail ? "hello@mystayapp.com" : ""))
			},
			getIcon(){
				// phone-number-input handles its own icon as it is a dropdown
				// list to select from
				if(!this.hideIcon && !this.isPhone){
					return this.icon || (this.isEmail ? "mail" : null)
				} else {
					return null
				}
			},
			overlays(){
				return {
					success: this.showSuccess,
					error: this.showError,
					destroy: this.showDestroy
				}
			}
		},
		watch: {
			internal_value:{
				handler(val, oldVal){
					console.log(`${this.name}: stay_input: OLDVALUE: ${JSON.stringify(oldVal)}\nNEWVALUE: ${JSON.stringify(val)}`)
					if(this.mounted){
						//console.log("stay_input: EMITTING")
						this.$emit('input', val)
						if(this.isPhone){
							this.$emit('update:phone_number', val.phone_number)
							this.$emit('update:friendly_name', val.friendly_name)
						}
					}
				},
				deep: true
			},
			value(val, oldVal){
				if(this.mounted){
					this.internal_value = val
				}
			}
		},
		created(){
			//this.internal_value = this.value
			this.$nextTick(()=>{this.mounted = true})
			this.$root.$on('$dirty:restore', ()=>{
				//console.log("stay_input: $dirty:restore")
				this.internal_value = this.value
			})
		},
		methods: {
			// TODO: Refactor this unit as it isn't updated dynamically when the
			// value prop is changed. We need to rewrite this so that the data isn't
			// stored internally but is emitted on input:
			update(){
				this.internal_value = this.value
			},
			handleBlur(event){
				this.touched = true
				this.$emit("blur")
			},
			handleKeyup(event){
				this.$emit("keyup", event)
			},
			focus(){
				this.$refs.input.focus()
			}
		}
	}
</script>

<style lang="scss">
	@import "stylesheets/colors";

	.stay-input {
		position: relative;
		border: 1px solid #ced4da;

		input {
			border: none;
			width: 100%;
		}

		.icon-button.prepend {
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
			z-index: 2;
		}

		.append {
			position: absolute;
			right: 0.75rem;
			top: 0;
			height: 100%;
			display: flex;
			align-items: center;
			font-weight: 100;
			font-color: $secondary-color;
			z-index: 2;
		}

		.phone-number-input {
			border: none !important;
		}
	}
	.stay-input.error {
		border: 1px solid $danger-color !important;
		margin-bottom: 0.75rem;
	}
</style>