<template>
	<b-modal :visible="visible">
		<div v-if="entity" class="entity-header w-100 b-block">
			<stay-hero v-if="entity.has_hero" v-model="entity.hero"></stay-hero>
			<stay-avatar v-if="entity.has_avatar" v-model="entity" :size="100"></stay-avatar>

			<b-list-group flush>
				<b-list-group-item v-for="(value, key) in entity" v-if="!reject_always.includes(key)">
					<h5 class="text-muted">{{key}}</h5>
					<component :is="`${typeof value}-edit`" v-if="typeof value == 'string'" v-model="internal_data[key]" :name="key" :editing="true" :url="entity.url" @input="handleInput"></component>
					<span v-else>{{value}}</span>
					<xsmall>{{typeof(value)}}</xsmall>
				</b-list-group-item>
			</b-list-group>
		</div>
	</b-modal>
</template>

<script>

	import Entity from 'mixins/entity'
	import StringEdit from 'common/string_edit'
	export default {
		props: {
			value: Object,

			// Configurations for editing data:
			// The edit mode determines if the fields are all shown as editable together and submitted as one PUT
			// or the fields are editable on click and updated with the server individually
			editMode: {
				type: String,
				validator: function(value){
					return ['all','individual','none'].indexOf(value) != -1
				},
				default: 'individual'
			},
			// If true, the modal will load already in editing mode as defined above
			// If editMode = none, this setting is irrelevant
			editing: Boolean
		},
		data(){
			return {
				visible: true,
				internal_data: null,

				// These are keys that should never be shown in the modal or editable
				reject_always: ['domain','created_at','updated_at','id','has_avatar','has_hero']
			}
		},
		created(){
			this.internal_data = this.value
		},
		mixins: [Entity],
		components: {
			'string-edit': StringEdit
		},
		methods: {
			handleInput(event){
				// If the compinent is set to edit inline via "individual" edit-mode, then we want to emit
				// the internal model every time something is changed:
				if(this.editMode == "individual"){
					this.$emit('input', this.internal_data)
				}
			}
		}

	}
</script>

<style lang="scss">
	@import "css";
	.entity-header {

	}
</style>