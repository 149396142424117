<template>
	<typeahead 
		:value="formatted_value" 
		results="/addresses/search" 
		placeholder="Enter an address" 
		:no-form-control="noFormControl" 
		icon="map"
		:initial-results="initial_results"
		@input="handleInput"
	>		
	</typeahead>
</template>

<script>
	// The expected format of an address is as follows:
	// text: String and the expected text to use in the UI
	// html: String - this is only used as part of a search result (adds emphasis to the words that have been searched)
	// address: ?
	// 

	import Typeahead from 'common/typeahead'

	const AddressToString = function(address){
		return [address.address, address.city, address.state, address.zip].filter(Boolean).join(", ")
	}

	export default {
		props: {
			value: Object | String,
			noFormControl: Boolean
		},
		data(){
			return {
				internal_value: this.value,
				initial_results: [
					{ text: "San Francisco, CA, USA", place_id: "ChIJIQBpAG2ahYAR_6128GcTUEo", html: "<strong>San</strong> <strong>Francisco</strong>, CA, USA" },
					{ text: "Oakland, CA, USA", place_id: "ChIJA-2qKIt9hYARZ5N1NdUVtHE", html: "<strong>Oakland</strong>, CA, USA" }
				],
				mounted: false
			}
		},
		components: {
			'typeahead': Typeahead
		},
		computed: {
			formatted_value(){
				if(!this.internal_value || typeof(this.internal_value)=="string" || this.internal_value.place_id){
					return this.internal_value
				} else {
					return AddressToString(this.internal_value)
				}
			}
		},
		methods: {
			handleInput(event){
				//console.log(event)
				this.internal_value = event
				this.$emit('input', this.internal_value)
			},
		},
		mounted(){
			this.$nextTick(()=>{
				this.mounted = true
			})
		}
	}
</script>

<style lang="scss">
	@import "css";

	.address-input{
		position: relative;



	}
</style>