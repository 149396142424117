import get from 'lodash/get'
import unionBy from 'lodash/unionBy'
import capitalize from 'lodash/capitalize'
import {Pluralize} from 'entity'

// ENTITY.JS MIXIN
// ----------------------------------------
// This plugin handles computed values and standard methods
// to process a components props and determine if a user, prospect, etc
// is attached to the object
// 
// Guide:
// By default the mixin assumes that the "entity" is identified using the `value` prop or data attribute
// You can override this by setting a variable `entity_key` to "$props.user" or "$data.prospect" etc.
//
// Each model should be added here with an appropriate boolean check function that identifies the model:
const entities = [
	{name: "Admin", check: (object)=>{ return (object.role || false) == "admin" }},
	{name: "Client", check: (object)=>{ return (object.role || false) == "client" }},
	{name: "Trainer", check: (object)=>{ return (object.role || false) == "user" }},
	{name: "Prospect", check: (object)=>{ return Object.keys(object).includes("fname") && !Object.keys(object).includes("prospect_id") && !Object.keys(object).includes("rsvp_at") }},
	{name: "Pet", check: (object)=>{ return Object.keys(object).includes("breed_input") }},
	{name: "Breed", check: (object)=>{ return Object.keys(object).includes("species_id") }},
	{name: "Document", check: (object)=>{ return Object.keys(object).includes("document_type") }},
	{name: "DocumentPage", check: (object)=>{ return Object.keys(object).includes("page_number") }},
	{name: "Upload", check: (object)=>{ return Object.keys(object).includes("filetype") }},
	{name: "Invite", check: (object)=>{ return Object.keys(object).includes("rsvp_at") }},
	{name: "Email", check: (object)=>{ return Object.keys(object).includes("local") }},
	{name: "Phone", check: (object)=>{ return Object.keys(object).includes("friendly_name") }},
	{name: "Message", check: (object)=>{ return Object.keys(object).includes("template_type") }},
	{name: "OrgEventMessage", check: (object)=>{ return Object.keys(object).includes("rsvps_only") }},
	{name: "OrgEvent", check: (object)=>{ return Object.keys(object).includes("waitlist") }},
	{name: "Event", check: (object)=>{ return Object.keys(object).includes("event_type")}},
	{name: "TempUserSession", check: (object)=>{ return Object.keys(object).includes("routes")}},
	{name: "WorkflowAction", check: (object)=>{ return Object.keys(object).includes("action_class") }}
]

// Temporarily being used to surface the correct avatars for Event instances.
const event_icons = {
	email: "mail",
	note: "file-text",
	document_signed: "edit-3"
}

const mappings = {
	Activity: {
		singular: "activity",
		plural: "activities"
	},
	Admin: {
		singular: 'user',
		plural: 'users',
		fullname: (object)=>{ return `${object.fname} ${object.lname}`},
		initials: (object)=>{ return `${object.fname.substring(0,1)}${object.lname.replace('<em>','').substring(0,1)}`},
		url: (object)=>{ return `/clients/${object.id}`}
	},
	Client: {
		singular: 'user',
		plural: 'users',
		fullname: (object)=>{ return `${object.fname} ${object.lname}`},
		initials: (object)=>{ return `${object.fname.substring(0,1)}${object.lname.replace('<em>','').substring(0,1)}`},
		url: (object)=>{ return `/clients/${object.id}`},
		city_state: (object)=>{
			if(object.address && object.address.text){
				return object.address.text
			}
			if(object.address && object.address.city){
				return object.address.state ? `${object.address.city}, ${object.address.state}` : object.address.city
			}
			if(object.address && object.address.state){
				return object.address.state
			}
			return null
		}
	},
	Prospect: {
		singular: 'prospect',
		plural: 'prospects',
		icon: 'user',
		fullname: (object)=>{ return `${object.fname} ${object.lname}`},
		initials: (object)=>{ return `${object.fname.replace('<em>','').substring(0,1)}${object.lname.replace('<em>','').substring(0,1)}`},
		url: (object)=>{ return `/prospects/${object.id}`}
	},
	Pet: {
		singular: 'pet',
		plural: 'pets',
		fullname: "name",
		initials: (object)=>{ return capitalize(object.name.replace(/(<([^>]+)>)/gi, "")).substr(0,1)},
		url: (object)=>{ return `/pets/${object.id}`}
	},
	Breed: {
		singular: 'pet',
		plural: 'pets',
		fullname: "name",
		initials: (object)=>{ return capitalize(object.name.replace(/(<([^>]+)>)/gi, "")).substr(0,2) },
		url: (object)=>{ return `/breeds/${object.id}`}
	},
	Trainer: {
		singular: 'user',
		plural: 'users',
		fullname: (object)=>{ return `${object.fname} ${object.lname}`},
		url: (object)=>{ return `/users/${object.id}`}
	},
	Document: {
		singular: 'document',
		plural: 'documents',
		url: "url",
		emptyStateIcon: "file-text",
		fullname: "name"
	},
	OrganizationDocument: {
		singular: 'organization_document',
		plural: 'organization_documents'
	},
	DocumentPage: {
		singular: 'document_page',
		plural: 'document_pages'
	},
	DocumentType: {
		singular: "document_type",
		plural: "document_types"
	},
	Upload: {
		singular: 'user_upload',
		plural: 'user_uploads',
		url: "url"
	},
	Invite: {
		singular: 'invite',
		plural: 'invites',
		fullname: (object)=> { return  object.attendee && object.attendee.name},
		//initials: (object)=>{ return `${object.fname.substring(0,1)}${object.lname.substring(0,1)}`},
		url: (object)=>{ return `/calendar/stay/invites/${object.id}`}
	},
	IntegrationIdentity: {
		singular: 'integration_identity',
		plural: 'integration_identities'
	},
	Email: {
		singular: 'email',
		plural: 'emails',
		avatar: "mail",
		fullname: (object)=>{ return `${object.local}@${object.domain}` },
		url: (object)=>{ return `mailto:${object.local}@${object.domain}`}
	},
	Phone: {
		singular: 'user_phone',
		plural: 'user_phones',
		fullname: "friendly_name"
	},
	Message: {
		singular: 'message',
		plural: 'messages',
		icon: 'message-circle'
	},
	OrgEventMessage: {
		singular: 'org_event_message',
		plural: 'org_event_messages'
	},
	OrgEvent: {
		singular: 'org_event',
		plural: 'org_events',
		fulladdress: event => { return event.address && event.address.query || null },
		timespan: event => {
			let start = new Date(Date.parse(event.start_time))
			let end = new Date(Date.parse(event.end_time))
			if(start.getDate() == end.getDate()){
				return `${start.toLocaleDateString()} from ${start.getHours()}:${start.getMinutes()} to ${end.getHours()}:${end.getMinutes()}`
			} else {
				return `From ${start.toLocaleDateString()} at ${start.getHours()}:${start.getMinutes()} to ${end.toLocaleDateString()} at ${end.getHours()}:${end.getMinutes()}`
			}
		}
	},
	OrgEventSearch: {
		singular: 'org_event_search',
		plural: 'org_event_searches'
	},
	Event: {
		singular: 'event',
		plural: 'events',
	},
	TempUserSession: {
		singular: 'temp_user_session',
		plural: 'temp_user_sessions'
	},
	WorkflowAction: {
		singular: 'workflow_action',
		plural: 'workflow_actions'
	}
}

// These functions should accept the highlight object, array or string specific to the key they represent
// and the entity_value (the raw entity object). The function should return a copy of the entity_value with the appropriate
// values updated with the highlight.

const highlight_mappings = {
	User: {
		tags: (highlight, object)=>{ return object },
		'user_phones.friendly_name': (highlight, object)=>{
			let response = object.user_phones
			highlight.forEach((h)=>{
				response[object.user_phones.findIndex((p)=>{ return p.friendly_name == h.replace(/(<([^>]+)>)/gi, "")})].friendly_name = h
			})
			return response
		},
		'pets.breed_input': (highlight, object)=>{
			let response = object.pets
			highlight.forEach((h)=>{
				response[object.pets.findIndex((p)=>{ return p.breed_input == h.replace(/(<([^>]+)>)/gi, "")})].breed_input = h
			})
			return response
		}
	},
	Prospect: {
		tags: (highlight, object)=>{ return unionBy(highlight, object.tags, (v)=>{ return v.replace(/(<([^>]+)>)/gi, "")}) },
		'user_phones.friendly_name': (highlight, object)=>{
			let response = object.user_phones
			highlight.forEach((h)=>{
				response[object.user_phones.findIndex((p)=>{ return p.friendly_name == h.replace(/(<([^>]+)>)/gi, "")})].friendly_name = h
			})
			return response
		},
		'pets.name': (highlight, object) => {
			if(!object.pets){
				return
			}
			let response = object.pets
			highlight.forEach((h)=>{
				response[object.pets.findIndex((p)=>{ return p.name == h.replace(/(<([^>]+)>)/gi, "")})].name = h
			})
			return response
		},
		'pets.breed_input': (highlight, object)=>{
			let response = object.pets
			highlight.forEach((h)=>{
				response[object.pets.findIndex((p)=>{ return p.breed_input == h.replace(/(<([^>]+)>)/gi, "")})].breed_input = h
			})
			return response
		}
	},
	Pet: {
		'user.fullname': (highlight, object)=>{
			let response = object.user
			response.fullname = highlight[0]
			return response
		},
		'prospect.fullname': (highlight, object)=>{
			let response = object.prospect
			response.fullname = highlight[0]
			return response
		}
	}
}



///////////////////
// FUNCTIONS FOR EXPORTING
// start here:
//////


export const toEntity = function(object){

	let entity_object = entities.find(entity => entity.check(object))
	if(!entity_object) return object

	let entity_type = entity_object.name

	let entity = {
		...object,
		...{entity_type: entity_type}
	}

	let mapping = mappings[entity_type]

	for (const key in mapping) {
		entity[key] = typeof(mapping[key]) == "string" ? (object[mapping[key]] || mapping[key]) : mapping[key](object)
	}

	return entity

}

export const singularize = function(entity_name){

	let entity = Object.keys(mappings).find(entity => entity == entity_name || mappings[entity].singular == entity_name || mappings[entity].plural == entity_name)
	return entity ? mappings[entity].singular : undefined

}

export const pluralize = function(entity_name){

	let new_pluralize = Pluralize(entity_name)
	if(new_pluralize) return new_pluralize
	let entity = Object.keys(mappings).find(entity => entity == entity_name || mappings[entity].singular == entity_name || mappings[entity].plural == entity_name)
	return entity ? mappings[entity].plural : undefined

}

export const entity_icon = function(entity_name){

	let entity = Object.keys(mappings).find(entity => entity == entity_name || mappings[entity].singular == entity_name || mappings[entity].plural == entity_name)
	return entity ? mappings[entity].icon : 'circle'

}

const mixin = {

	computed: {
		entity_ready(){
			return (this.entity_type && mappings[this.entity_type] ? true : false)
		},
		entity_type(){
			return (this.entity_value && this.entity_object ? this.entity_object.name : null)
		},
		entity_object(){
			if(this.entity_value){
				return entities.find((e)=>{ return e.check(this.entity_value) })
			} else {
				return null
			}
		},
		entity_value(){
			if(this.entity_key){
				return get(this, entity_key)
			} else {
				return this.value
			}
		},
		entity_mappings(){
			if(this.entity_ready){
				let response = {}
				for (const [key, value] of Object.entries(mappings[this.entity_type])) {
					if(this.highlight){
						response[key] = (typeof(value)=='function' ? value(this.entity_highlighted) : (this.entity_highlighted[value] || value))
					} else {
  						response[key] = (typeof(value)=='function' ? value(this.entity_value) : (this.entity_value[value] || value))
  					}
				}
				return response
			} else {
				return {}
			}
		},
		highlight_object(){
			return highlight_mappings[this.entity_type] || {}
		},
		highlight_mappings(){
			if(this.highlight && this.entity_ready){
				let response = {}
				for (const [key, value] of Object.entries(this.highlight)) {
					response[key] = (this.highlight_object[key] ? this.highlight_object[key](value, this.entity_value) : value[0])
				}
				return response
			} else {
				return {}
			}
		},
		entity_highlighted(){
			if(this.entity_ready && this.highlight){
				return {
					...this.entity_value,
					...this.highlight_mappings
				}
			} else {
				return {}
			}
		},
		entity(){
			if(this.entity_ready){
				if(this.highlight){
					return {
						//...this.entity_value, - Replacing this with the highlighted version - may want to confirm it works with no highlight
						...this.entity_highlighted,
						...this.entity_mappings,
						...{type: this.entity_type}
					}
				} else {
					return {
						...this.entity_value,
						...this.entity_mappings,
						...{type: this.entity_type}
					}
				}
			}
		}

	}
}

export default mixin
