<template>
	<div :class="`close-button${absolute ? ' absolute' : ''}`"><icon-button icon="x" :disabled="disabled" @click="$emit('click')" :tooltip="tooltip" /></div>
</template>

<script>
	import IconButton from 'common/icon_button'
	export default {
		name: 'close',
		props: {
			disabled: Boolean,
			tooltip: String,
			absolute: Boolean 		// Controls whether the button places itself absolutely in the top right corner
		},
		components: {IconButton}
	}
</script>

<style lang="scss">

	.close-button.absolute {
		position: absolute;
		top: 0;
		right: 0;
	}

</style>